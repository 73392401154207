import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ children, requiredRole }) => {
  const { auth, loading } = useContext(AuthContext);

  if (loading) {
    // Enquanto estiver carregando, exibe um spinner
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!auth.isAuthenticated) {
    // Se o usuário não estiver autenticado, redireciona para a página de login
    return <Navigate to="/login" />;
  }

  if (requiredRole && auth.user.funcao !== requiredRole) {
    // Se uma função específica for requerida e o usuário não a possui, redireciona para /home
    return <Navigate to="/inicio" replace />;
    // Alternativamente, renderize um componente de "Acesso Negado":
    // return <AccessDenied />;
  }

  // Se todas as verificações passarem, renderiza o componente filho
  return children;
};

export default PrivateRoute;
