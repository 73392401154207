import React, { useState, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Link as MuiLink,
  CircularProgress,
  Alert,
  Divider,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Email,
  Lock,
  Login as LoginIcon,
  PersonAdd,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const { login } = useContext(AuthContext); // Obtenção da função login do contexto
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendMessage, setResendMessage] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const navigate = useNavigate();

  const validarEmail = (email) => {
    const regexEmail = /\S+@\S+\.\S+/;
    return regexEmail.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setResendMessage('');

    if (!email || !senha) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (!validarEmail(email)) {
      setError('O formato do email é inválido.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://medchoices.com.br:3001/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, senha }),
      });

      const data = await response.json();

      if (data.success) {
        login(data.token); // Salva o token no contexto
        localStorage.setItem('userId', data.userId); // Salva o ID do usuário no localStorage
        navigate('/inicio');
      } else if (data.message?.toLowerCase().includes('verifique seu e-mail')) {
        setError('Por favor, verifique seu e-mail.');
      } else {
        setError(data.message || 'Credenciais inválidas.');
      }
    } catch (err) {
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setResendMessage('');
    setError('');
    setResendLoading(true);

    try {
      const response = await fetch('https://medchoices.com.br:3001/reenviar-verificacao', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setResendMessage(data.message);
      } else {
        setError(data.message || 'Erro ao reenviar o e-mail de verificação.');
      }
    } catch (err) {
      setError('Erro ao conectar ao servidor.');
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={6}
          sx={{
            p: { xs: 3, sm: 6 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 56,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <LoginIcon sx={{ color: '#fff', fontSize: 30 }} />
            </Box>
            <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>
              Entrar
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <MuiLink component={Link} to="/" underline="hover" color="text.secondary">
               Digite seus dados para acessar o medchoices
              </MuiLink>
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {resendMessage && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {resendMessage}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error && !validarEmail(email) && email !== ''}
              helperText={
                !validarEmail(email) && email !== '' ? 'Formato de email inválido' : ''
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="action" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="senha"
              label="Senha"
              name="senha"
              type={mostrarSenha ? 'text' : 'password'}
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Mostrar/Esconder senha"
                      onClick={() => setMostrarSenha(!mostrarSenha)}
                      edge="end"
                    >
                      {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <LoginIcon />}
              sx={{
                py: 1.5,
                mb: 3,
                mt: 2,
              }}
            >
              {loading ? 'Entrando...' : 'Entrar'}
            </Button>

            {(error === 'Por favor, verifique seu e-mail.' || resendLoading) && (
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleResendVerification}
                disabled={resendLoading}
                sx={{
                  py: 1.5,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {resendLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    Reenviando...
                  </Box>
                ) : (
                  'Reenviar Verificação'
                )}
              </Button>
            )}


            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <Typography
                component={Link}
                to="/recuperar-senha"
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  color: 'primary.main',
                  cursor: 'pointer',
                }}
              >
                Esqueceu sua senha?
              </Typography>
            </Box>

            <Box sx={{ position: 'relative', my: 3 }}>
              <Divider>
                <Typography variant="body2" color="text.secondary" sx={{ px: 1 }}>
                  Não tem uma conta?
                </Typography>
              </Divider>
            </Box>

            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<PersonAdd />}
              sx={{ py: 1.5 }}
              component={Link}
              to="/register"
            >
              Registrar-se
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
