import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  useTheme,
  AppBar,
  Toolbar,
  Stack,
  Chip,
  Divider,
  TextField,
  IconButton,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  School as SchoolIcon,
  Timeline as TimelineIcon,
  Group as GroupIcon,
  Security as SecurityIcon,
  CheckCircle as CheckCircleIcon,
  Login as LoginIcon,
  AppRegistration as AppRegistrationIcon,
  Star as StarIcon,
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  MenuBook as MenuBookIcon,
  Psychology as PsychologyIcon,
  Speed as SpeedIcon,
  EmojiEvents as EmojiEventsIcon,
} from '@mui/icons-material';
import { Stethoscope } from 'lucide-react';
import { useForm } from 'react-hook-form';
import CountUp from 'react-countup';
import LogoMedChoices from '../logomedchoices.svg'; // Certifique-se de ajustar o caminho


const features = [
  {
    icon: SchoolIcon,
    title: 'Banco de Preguntas Extenso',
    description: 'Más de 10,000 preguntas actualizadas y validadas por expertos médicos.',
  },
  {
    icon: TimelineIcon,
    title: 'Seguimiento de Progreso',
    description: 'Análisis detallado de tu rendimiento y áreas de mejora.',
  },
  {
    icon: GroupIcon,
    title: 'Comunidad Médica',
    description: 'Conecta con otros estudiantes y profesionales de la medicina.',
  },
  {
    icon: SecurityIcon,
    title: 'Simulaciones Realistas',
    description: 'Exámenes que replican el formato oficial de las pruebas médicas.',
  },
];

const plans = [
  {
    title: 'Plan Básico',
    price: '2999',
    period: '/mes',
    features: [
      'Acceso a 1000 preguntas',
      '2 simulaciones mensuales',
      'Estadísticas básicas',
      'Soporte por email',
    ],
    buttonText: 'Comenzar Gratis',
    popular: false,
  },
  {
    title: 'Plan Premium',
    price: '4999',
    period: '/mes',
    features: [
      'Acceso ilimitado a preguntas',
      'Simulaciones ilimitadas',
      'Estadísticas avanzadas',
      'Soporte prioritario 24/7',
      'Grupos de estudio',
      'Recursos descargables',
    ],
    buttonText: 'Comenzar Ahora',
    popular: true,
  },
  {
    title: 'Plan Institucional',
    price: '9999',
    period: '/mes',
    features: [
      'Todo lo del Plan Premium',
      'Portal administrativo',
      'Reportes personalizados',
      'API de integración',
      'Soporte dedicado',
      'Capacitación personalizada',
    ],
    buttonText: 'Contactar Ventas',
    popular: false,
  },
];

const testimonials = [
  {
    name: 'Dr. Carlos Rodríguez',
    role: 'Médico Residente',
    image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?w=400&auto=format&fit=crop&q=60',
    content: 'MedChoices me ayudó a aprobar mi examen de residencia con una puntuación sobresaliente. La calidad de las preguntas y las explicaciones detalladas fueron fundamentales.',
  },
  {
    name: 'Dra. Ana Martínez',
    role: 'Especialista en Pediatría',
    image: 'https://images.unsplash.com/photo-1594824476967-48c8b964273f?w=400&auto=format&fit=crop&q=60',
    content: 'La plataforma es intuitiva y el contenido está muy bien organizado. Los simulacros son muy realistas y me ayudaron a prepararme adecuadamente.',
  },
  {
    name: 'Dr. Juan Pérez',
    role: 'Estudiante de Medicina',
    image: 'https://images.unsplash.com/photo-1622253692010-333f2da6031d?w=400&auto=format&fit=crop&q=60',
    content: 'Excelente herramienta de estudio. El seguimiento del progreso y las estadísticas me ayudaron a identificar mis áreas débiles y mejorarlas.',
  },
];

const stats = [
  { label: 'Estudiantes Activos', value: 15000 },
  { label: 'Preguntas Disponibles', value: 10000 },
  { label: 'Tasa de Aprobación', value: 95 },
  { label: 'Años de Experiencia', value: 10 },
];

function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    setContactDialogOpen(false);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      {/* Navbar */}
      <AppBar position="fixed" sx={{ bgcolor: theme.palette.primary.main }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            component="img"
            src={LogoMedChoices}
            alt="Logo"
            sx={{ height: 50, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
          <Stack direction="row" spacing={2}>
            <Button
            variant="outlined"
              color="inherit"
              startIcon={<LoginIcon />}
              onClick={() => navigate('/login')}
              sx={{
                textTransform: 'none',
                color: theme.palette.primary.contrastText,
              }}
            >
              Ingresar
            </Button>
            <Button
              variant="contained"
              startIcon={<AppRegistrationIcon />}
              onClick={() => navigate('/register')}
              sx={{
                textTransform: 'none',
                bgcolor: theme.palette.secondary.main,
                ':hover': { bgcolor: theme.palette.secondary.dark },
              }}
            >
              Registrarse
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box
        sx={{
          pt: { xs: 12, md: 20 },
          pb: { xs: 8, md: 12 },
          textAlign: 'center',
          background: `linear-gradient(135deg, ${theme.palette.primary.main}15 0%, ${theme.palette.secondary.main}15 100%)`,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              <Typography 
                variant="h3" 
                gutterBottom
                sx={{
                  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  mb: 3,
                }}
              >
                Tu éxito en el examen médico comienza aquí
              </Typography>
              <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
                La plataforma líder en Argentina para la preparación de exámenes médicos. 
                Más de 15,000 estudiantes confían en nosotros.
              </Typography>
              <Stack 
                direction={{ xs: 'column', sm: 'row' }} 
                spacing={2} 
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/register')}
                  startIcon={<ArrowForwardIcon />}
                >
                  Comenzar Ahora
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/demo')}
                >
                  Ver Demo
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?w=800&auto=format&fit=crop&q=60"
                alt="Medical students studying"
                sx={{
                  width: '100%',
                  maxWidth: 600,
                  height: 'auto',
                  borderRadius: 4,
                  boxShadow: theme.shadows[4],
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Paper elevation={0} sx={{ p: 3, textAlign: 'center', bgcolor: 'background.default' }}>
                <Typography variant="h3" color="primary" sx={{ mb: 1, fontWeight: 'bold' }}>
                  <CountUp end={stat.value} suffix={stat.label.includes('Tasa') ? '%' : '+'} duration={2.5} />
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {stat.label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Features Section */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h4" align="center" sx={{ mb: 6 }}>
          Características Principales
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    p: 3,
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      {/* Testimonials Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 6 }}>
            Lo Que Dicen Nuestros Usuarios
          </Typography>
          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ height: '100%', p: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={testimonial.image}
                      sx={{ width: 56, height: 56, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.role}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" color="text.secondary">
                    "{testimonial.content}"
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ py: 8 }}>
        <Container>
          <Typography variant="h4" align="center" sx={{ mb: 6 }}>
            Planes y Precios
          </Typography>
          <Grid container spacing={4} alignItems="center">
            {plans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    p: 4,
                    height: '100%',
                    position: 'relative',
                    transition: 'transform 0.2s ease-in-out',
                    ...(plan.popular && {
                      transform: 'scale(1.05)',
                      border: `2px solid ${theme.palette.primary.main}`,
                    }),
                  }}
                >
                  {plan.popular && (
                    <Chip
                      icon={<StarIcon />}
                      label="Más Popular"
                      color="primary"
                      sx={{
                        // position: 'fixed',
                        // top: 0,
                        // zIndex: 9999,
                        // right: 24,
                        mb: 2,
                      }}
                    />
                  )}
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    {plan.title}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
                    <Typography variant="h3" component="span" fontWeight="bold">
                      ${plan.price}
                    </Typography>
                    <Typography variant="subtitle1" component="span" color="text.secondary">
                      {plan.period}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Stack spacing={2} sx={{ mb: 4 }}>
                    {plan.features.map((feature, idx) => (
                      <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CheckCircleIcon sx={{ color: 'success.main' }} />
                        <Typography variant="body2">{feature}</Typography>
                      </Box>
                    ))}
                  </Stack>
                  <Button
                    variant={plan.popular ? 'contained' : 'outlined'}
                    fullWidth
                    size="large"
                    onClick={() => navigate('/register')}
                  >
                    {plan.buttonText}
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Contact Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Contáctanos
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Estamos aquí para ayudarte. Contáctanos por cualquiera de estos medios:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Email"
                    secondary="contacto@medchoices.com"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Teléfono"
                    secondary="+54 11 1234-5678"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocationIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Dirección"
                    secondary="Av. Corrientes 1234, CABA, Argentina"
                  />
                </ListItem>
              </List>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <IconButton color="primary">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="primary">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="primary">
                  <InstagramIcon />
                </IconButton>
                <IconButton color="primary">
                  <LinkedInIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                  Envíanos un mensaje
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <TextField
                      label="Nombre completo"
                      fullWidth
                      {...register('name', { required: 'Este campo es requerido' })}
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                    />
                    <TextField
                      label="Email"
                      fullWidth
                      type="email"
                      {...register('email', { 
                        required: 'Este campo es requerido',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Email inválido'
                        }
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message?.toString()}
                    />
                    <TextField
                      label="Mensaje"
                      fullWidth
                      multiline
                      rows={4}
                      {...register('message', { required: 'Este campo es requerido' })}
                      error={!!errors.message}
                      helperText={errors.message?.message?.toString()}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                    >
                      Enviar Mensaje
                    </Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider', py: 4 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Stethoscope size={24} color={theme.palette.primary.main} />
                <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
                  MedChoices
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                La plataforma líder en preparación para exámenes médicos en Argentina.
                Ayudamos a los futuros médicos a alcanzar sus metas.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    Productos
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Banco de Preguntas" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Simulaciones" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Recursos" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    Compañía
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Sobre Nosotros" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Blog" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Carreras" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    Legal
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Términos de Uso" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Privacidad" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Cookies" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} MedChoices. Todos los derechos reservados.
          </Typography>
        </Container>
      </Box>

      {/* WhatsApp Button */}
      <Fab
        color="success"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={() => window.open('https://wa.me/5491112345678', '_blank')}
      >
        <WhatsAppIcon />
      </Fab>

      {/* Contact Dialog */}
      <Dialog 
        open={contactDialogOpen} 
        onClose={() => setContactDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Contáctanos
          <IconButton
            onClick={() => setContactDialogOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ mt: 2 }}>
              <TextField
                label="Nombre completo"
                fullWidth
                {...register('name', { required: 'Este campo es requerido' })}
                error={!!errors.name}
                helperText={errors.name?.message?.toString()}
              />
              <TextField
                label="Email"
                fullWidth
                type="email"
                {...register('email', { 
                  required: 'Este campo es requerido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email inválido'
                  }
                })}
                error={!!errors.email}
                helperText={errors.email?.message?.toString()}
              />
              <TextField
                label="Mensaje"
                fullWidth
                multiline
                rows={4}
                {...register('message', { required: 'Este campo es requerido' })}
                error={!!errors.message}
                helperText={errors.message?.message?.toString()}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContactDialogOpen(false)}>Cancelar</Button>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LandingPage;