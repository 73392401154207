// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import AdminDashboard from './pages/Admin/AdminDashboard';
import UserPanel from './pages/Admin/UserPanel';
import MateriasList from './pages/Admin/Materias/MateriasList';
import MateriasForm from './pages/Admin/Materias/MateriasForm';
import ProvasList from './pages/Admin/Provas/ProvasList';
import ProvasForm from './pages/Admin/Provas/ProvasForm';
import QuestoesList from './pages/Admin/Questoes/QuestoesList';
import QuestoesForm from './pages/Admin/Questoes/QuestoesForm';
import Choices from './pages/Choices';
import AvisosList from './pages/Admin/Avisos/AvisosList';
import AvisosForm from './pages/Admin/Avisos/AvisosForm';
import HistoricoUsuarios from './pages/Admin/Historico/HistoricoUsuarios';
import DetalhesProva from './pages/Admin/Historico/DetalhesProva';
import HistoricoUsuario from './pages/HistoricoUsuario';
import Admin from './pages/AdminPage';
import LandingPage from './pages/LandingPage'; // Importar a LandingPage
import RecuperarSenha from './components/RecuperarSenha'; // Importar RecuperarSenha
import RedefinirSenha from './components/RedefinirSenha'; // Importar RedefinirSenha
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute'; // Importar o PublicRoute
import VerifyEmail from './VerifyEmail'; // Importe o componente VerifyEmail
import ResendVerification from './ResendVerification';
import Faculdades from './pages/Faculdades'; // Importe o componente Faculdades

import Navbar from './components/Navbar';
import { Box } from '@mui/material';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Box sx={{ marginTop: '64px' }}> {/* Adiciona espaço para a Navbar */}
          <Routes>
            {/* Página Inicial - Landing Page */}
            <Route
              path="/"
              element={
                <PublicRoute>
                  <LandingPage />
                </PublicRoute>
              }
            />

            {/* Rotas Públicas */}
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/recuperar-senha"
              element={
                <PublicRoute>
                  <RecuperarSenha />
                </PublicRoute>
              }
            />
            <Route
              path="/redefinir-senha/:token"
              element={
                <PublicRoute>
                  <RedefinirSenha />
                </PublicRoute>
              }
            />

            <Route
              path="/verificar-email/:token" // Adiciona a rota de verificação de e-mail
              element={
                <PublicRoute>
                  <VerifyEmail />
                </PublicRoute>
              }
            />
            <Route
              path="/reenviar-verificacao"
              element={
                <PublicRoute>
                  <ResendVerification />
                </PublicRoute>
              }
            />
            {/* Rotas Protegidas para Usuários Autenticados */}
            <Route
              path="/inicio"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/choices"
              element={
                <PrivateRoute>
                  <Choices />
                </PrivateRoute>
              }
            />
            <Route
              path="/historico"
              element={
                <PrivateRoute>
                  <HistoricoUsuario />
                </PrivateRoute>
              }
            />
            <Route
              path="/historico/:resultadoId"
              element={
                <PrivateRoute>
                  <DetalhesProva />
                </PrivateRoute>
              }
            />

            {/* Rotas Administrativas Protegidas para Admins */}
            <Route
              path="/admin"
              element={
                <PrivateRoute requiredRole="admin">
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/relatorios"
              element={
                <PrivateRoute requiredRole="admin">
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute requiredRole="admin">
                  <UserPanel />
                </PrivateRoute>
              }
            />

            {/* Rotas para Matérias */}
            <Route
              path="/admin/materias"
              element={
                <PrivateRoute requiredRole="admin">
                  <MateriasList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/materias/create"
              element={
                <PrivateRoute requiredRole="admin">
                  <MateriasForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/materias/edit/:id"
              element={
                <PrivateRoute requiredRole="admin">
                  <MateriasForm />
                </PrivateRoute>
              }
            />

            {/* Rotas para Provas */}
            <Route
              path="/admin/provas"
              element={
                <PrivateRoute requiredRole="admin">
                  <ProvasList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/provas/create"
              element={
                <PrivateRoute requiredRole="admin">
                  <ProvasForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/provas/edit/:id"
              element={
                <PrivateRoute requiredRole="admin">
                  <ProvasForm />
                </PrivateRoute>
              }
            />

            {/* Rotas para Questões */}
            <Route
              path="/admin/questoes"
              element={
                <PrivateRoute requiredRole="admin">
                  <QuestoesList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/questoes/create"
              element={
                <PrivateRoute requiredRole="admin">
                  <QuestoesForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/questoes/edit/:id"
              element={
                <PrivateRoute requiredRole="admin">
                  <QuestoesForm />
                </PrivateRoute>
              }
            />

            {/* Rotas para Avisos */}
            <Route
              path="/admin/avisos"
              element={
                <PrivateRoute requiredRole="admin">
                  <AvisosList />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/avisos/create"
              element={
                <PrivateRoute requiredRole="admin">
                  <AvisosForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/avisos/edit/:id"
              element={
                <PrivateRoute requiredRole="admin">
                  <AvisosForm />
                </PrivateRoute>
              }
            />
             <Route
              path="/admin/faculdades"
              element={
                <PrivateRoute requiredRole="admin">
                  <Faculdades />
                </PrivateRoute>
              }
            />

            {/* Rotas para Histórico */}
            <Route
              path="/admin/historico"
              element={
                <PrivateRoute requiredRole="admin">
                  <HistoricoUsuarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/historico/:resultadoId"
              element={
                <PrivateRoute requiredRole="admin">
                  <DetalhesProva />
                </PrivateRoute>
              }
            />

            {/* Página 404 Personalizada */}
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Routes>
        </Box>
      </Router>
    </AuthProvider>
  );
}

export default App;
