import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MateriasList from './Materias/MateriasList';
import MateriasForm from './Materias/MateriasForm';
import ProvasList from './Provas/ProvasList';
import ProvasForm from './Provas/ProvasForm';
import QuestoesList from './Questoes/QuestoesList';
import QuestoesForm from './Questoes/QuestoesForm';
import { Box } from '@mui/material';

const AdminDashboard = () => {
  return (
    <Box>
      <Box>
        <Box>
          <Routes>
            {/* Redirecionamento para a página inicial de administração (home ou painel) */}
            <Route path="/" element={<Navigate to="/admin/relatorios" />} />
            
            {/* Página inicial ou painel de administração */}
            <Route path="relatorios" element={<div>Painel de Administração</div>} />
            
            {/* Rotas para Materias */}
            <Route path="materias" element={<MateriasList />} />
            <Route path="materias/create" element={<MateriasForm />} />
            <Route path="materias/edit/:id" element={<MateriasForm />} />
            
            {/* Rotas para Provas */}
            <Route path="provas" element={<ProvasList />} />
            <Route path="provas/create" element={<ProvasForm />} />
            <Route path="provas/edit/:id" element={<ProvasForm />} />
            
            {/* Rotas para Questoes */}
            <Route path="questoes" element={<QuestoesList />} />
            <Route path="questoes/create" element={<QuestoesForm />} />
            <Route path="questoes/edit/:id" element={<QuestoesForm />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
