import React, { useEffect, useState, useRef, useCallback } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton, 
  Button,
  Typography,
  CircularProgress,
  Alert,
  Tooltip,
  TextField,
  Box,
  Checkbox,
  Snackbar,
  Grid,
  Card,
  CardContent,
  useTheme,
  alpha,
  InputAdornment,
  Chip,
  Breadcrumbs,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Quiz as QuizIcon, 
  Search as SearchIcon, 
  Add as AddIcon,
  AccessTime as AccessTimeIcon,
  School as SchoolIcon,
  CalendarToday as CalendarTodayIcon,
  UploadFile as UploadFileIcon,
  Download as DownloadIcon,
  Home as HomeIcon,
  List as ListIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

// Custom Alert component for Snackbar
const AlertSnackbar = React.forwardRef((props, ref) => (
  <Alert elevation={6} variant="filled" ref={ref} {...props} />
));

const ProvasList = () => {
  const theme = useTheme();
  const [provas, setProvas] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [error, setError] = useState('');
  const [totalProvas, setTotalProvas] = useState(0);
  
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const searchParam = params.get('search') || '';

  const [search, setSearch] = useState(searchParam);
  const [selectedProvas, setSelectedProvas] = useState([]);
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });

  // Estados para ordenação
  const [sortField, setSortField] = useState('id'); // Campo padrão
  const [sortOrder, setSortOrder] = useState('asc'); // Ordem padrão: crescente

  // State for handling duplicate Prova names during import
  const [duplicateProvas, setDuplicateProvas] = useState([]);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [importedProvas, setImportedProvas] = useState([]);

  // Estados para modal de exclusão
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null); // Pode ser objeto (única) ou array de IDs (múltiplas)

  // Fetch provas with timeout
  const fetchWithTimeout = async (url, options = {}, timeout = 10000) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal
      });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  };

  // Fetch provas
  const fetchProvas = useCallback(async () => {
    if (loadingFetch) return;
    
    setLoadingFetch(true);
    setError('');
    
    try {
      // Construir a URL usando URL e URLSearchParams
      const baseUrl = 'https://medchoices.com.br:3001/admin/provas';
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      
      if (search) {
        params.append('search', search);
      }
      
      if (sortField) {
        params.append('sortField', sortField);
      }
      
      if (sortOrder) {
        params.append('sortOrder', sortOrder);
      }
      
      url.search = params.toString();
      
      const response = await fetchWithTimeout(url.toString(), {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar provas. Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setProvas(data.provas);
        setTotalProvas(data.provas.length);
        setSelectedProvas([]);
      } else {
        throw new Error(data.message || 'Erro ao buscar Provas.');
      }
    } catch (error) {
      console.error('Erro ao buscar Provas:', error);
      setError(error.message || 'Erro ao buscar Provas.');
    } finally {
      setLoadingFetch(false);
    }
  }, [search, sortField, sortOrder]);

  useEffect(() => {
    fetchProvas();
  }, [fetchProvas]);

  // Delete single prova - Atualizada para abrir o modal
  const handleDelete = (id) => {
    const prova = provas.find(p => p.id === id);
    if (prova) {
      handleOpenDeleteModal(prova);
    }
  };

  // Delete selected provas - Atualizada para abrir o modal
  const handleDeleteSelected = () => {
    if (selectedProvas.length === 0) return;
    handleOpenDeleteModal(selectedProvas); // Passa o array de IDs
  };

  // Abre o modal de exclusão
  const handleOpenDeleteModal = (target) => {
    setDeleteTarget(target); // target pode ser uma única prova ou um array de IDs
    setIsDeleteModalOpen(true);
  };

  // Fecha o modal de exclusão
  const handleCloseDeleteModal = () => {
    setDeleteTarget(null);
    setIsDeleteModalOpen(false);
  };

  // Confirma a exclusão
  const confirmDelete = async () => {
    if (!deleteTarget) return;

    try {
      if (Array.isArray(deleteTarget)) {
        // Exclusão múltipla
        const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ ids: deleteTarget }),
        });

        if (!response.ok) {
          throw new Error(`Erro ao deletar provas. Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setSnackbar({ 
            open: true, 
            message: 'Provas deletadas com sucesso!', 
            severity: 'success' 
          });
          await fetchProvas();
        } else {
          throw new Error(data.message || 'Erro ao deletar Provas.');
        }
      } else {
        // Exclusão única
        const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas/${deleteTarget.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erro ao deletar prova. Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setSnackbar({
            open: true,
            message: 'Prova deletada com sucesso!',
            severity: 'success',
          });
          await fetchProvas();
        } else {
          throw new Error(data.message || 'Erro ao deletar prova.');
        }
      }
    } catch (error) {
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao deletar provas.', 
        severity: 'error' 
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  // Handle search with debounce
  const debouncedSearch = useCallback(
    debounce((value) => {
      const newParams = new URLSearchParams(location.search);
      if (value) {
        newParams.set('search', value);
      } else {
        newParams.delete('search');
      }
      navigate(`?${newParams.toString()}`);
    }, 500),
    [location.search, navigate]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    debouncedSearch(value);
  };

  // Handle selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedProvas(provas.map((p) => p.id));
    } else {
      setSelectedProvas([]);
    }
  };

  const handleSelectOne = (event, id) => {
    if (event.target.checked) {
      setSelectedProvas((prev) => [...prev, id]);
    } else {
      setSelectedProvas((prev) => prev.filter((pid) => pid !== id));
    }
  };

  // Export single prova as TXT (mantendo console logs)
  const handleExportSingleProva = async (provaId) => {
    try {
      setLoadingExport(true);
      console.log(`Iniciando exportação da prova ID: ${provaId}`);
      const response = await fetchWithTimeout(`https://medchoices.com.br:3001/admin/provas/${provaId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar prova. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Resposta do servidor ao buscar prova:', data);
      if (data.success) {
        const prova = data.prova;
        // Remover campos desnecessários
        const { id, materia_nome, created_at, updated_at, ...provaSemId } = prova;
        // Envolver a prova em um array para compatibilidade com o backend
        const provasArray = [provaSemId];
        const blob = new Blob([JSON.stringify(provasArray, null, 2)], { 
          type: 'text/plain' 
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        // Sanitize file name to remove invalid characters
        const sanitizedTitle = prova.titulo.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        link.href = url;
        link.download = `prova_${sanitizedTitle}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        console.log(`Prova exportada como: prova_${sanitizedTitle}.txt`);
        setSnackbar({ 
          open: true, 
          message: 'Prova exportada com sucesso!', 
          severity: 'success' 
        });
      } else {
        throw new Error(data.message || 'Erro ao exportar prova.');
      }
    } catch (error) {
      console.error('Erro durante a exportação da prova:', error);
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao exportar prova.', 
        severity: 'error' 
      });
    } finally {
      setLoadingExport(false);
    }
  };

  // Import TXT
  const handleImportJSONClick = () => {
    fileInputRef.current?.click();
  };

  const handleImportJSON = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoadingImport(true);
      console.log(`Iniciando importação do arquivo: ${file.name}`);
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const textData = e.target?.result;
          console.log('Conteúdo do arquivo lido:', textData);
          const jsonData = JSON.parse(textData);
          console.log('JSON parseado:', jsonData);
          if (!Array.isArray(jsonData)) {
            throw new Error('Formato JSON inválido. Deve ser um array de provas.');
          }

          // Remover campos desnecessários das provas importadas
          const provasImportadas = jsonData.map(prova => {
            const { id, materia_nome, created_at, updated_at, ...provaSemId } = prova;
            return provaSemId;
          });

          // Check for duplicate names
          const existingProvaNames = new Set(provas.map(p => p.titulo));
          const duplicates = provasImportadas.filter(p => existingProvaNames.has(p.titulo));

          console.log('Provas duplicadas encontradas:', duplicates);

          if (duplicates.length > 0) {
            setDuplicateProvas(duplicates);
            setImportedProvas(provasImportadas);
            setIsDuplicateModalOpen(true);
          } else {
            // No duplicates, proceed with import
            console.log('Nenhuma duplicata encontrada. Procedendo com a importação.');
            await importProvas(provasImportadas);
          }
        } catch (err) {
          console.error('Erro ao processar o arquivo importado:', err);
          setSnackbar({ 
            open: true, 
            message: err.message || 'Arquivo JSON inválido ou estrutura incorreta.', 
            severity: 'error' 
          });
          setLoadingImport(false);
        }
      };
      
      reader.onerror = (e) => {
        console.error('Erro ao ler o arquivo:', e);
        setSnackbar({ 
          open: true, 
          message: 'Erro ao ler o arquivo.', 
          severity: 'error' 
        });
        setLoadingImport(false);
      };
      
      reader.readAsText(file);
    } catch (error) {
      console.error('Erro durante a importação das provas:', error);
      setSnackbar({ 
        open: true, 
        message: 'Erro ao importar provas.', 
        severity: 'error' 
      });
      setLoadingImport(false);
    }
  };

  // Function to import provas after handling duplicates
  const importProvas = async (provasToImport) => {
    try {
      console.log('Enviando provas para o servidor:', provasToImport);
      const response = await fetchWithTimeout('https://medchoices.com.br:3001/admin/provas/import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ provas: provasToImport }),
      });

      console.log('Resposta do servidor após importação:', response);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Erro ao importar provas. Status: ${response.status}`, errorText);
        throw new Error(`Erro ao importar provas. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Resposta do servidor após importação:', data);
      if (data.success) {
        setSnackbar({ 
          open: true, 
          message: 'Provas importadas com sucesso!', 
          severity: 'success' 
        });
        await fetchProvas();
      } else {
        throw new Error(data.message || 'Erro ao importar provas.');
      }
    } catch (error) {
      console.error('Erro ao importar provas:', error);
      setSnackbar({ 
        open: true, 
        message: error.message || 'Erro ao importar provas.', 
        severity: 'error' 
      });
    } finally {
      setLoadingImport(false);
    }
  };

  // Handle renaming duplicates and proceed with import
  const handleResolveDuplicates = async () => {
    // Atualizar os títulos das provas importadas com os novos títulos fornecidos pelo usuário
    const updatedProvas = importedProvas.map(prova => {
      const duplicate = duplicateProvas.find(dp => dp.titulo === prova.titulo);
      if (duplicate && duplicate.newTitle) {
        return { ...prova, titulo: duplicate.newTitle };
      }
      return prova;
    });

    console.log('Provas atualizadas após renomear duplicatas:', updatedProvas);

    setIsDuplicateModalOpen(false);
    setDuplicateProvas([]);
    setImportedProvas([]);

    // Proceder com a importação
    await importProvas(updatedProvas);
  };

  // Atualizar o título duplicado
  const handleDuplicateTitleChange = (titulo, newTitle) => {
    setDuplicateProvas(prev => prev.map(prova => 
      prova.titulo === titulo ? { ...prova, newTitle } : prova
    ));
  };

  // Função para lidar com a ordenação nos cabeçalhos
  const handleSort = (field) => {
    if (sortField === field) {
      // Alterna a direção da ordenação
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Define o novo campo de ordenação e reseta a direção para crescente
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 3 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Matérias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
      </Breadcrumbs>

      {/* Header Card */}
      <Card 
        elevation={0}
        sx={{ 
          backgroundColor: alpha(theme.palette.primary.main, 0.05),
          mb: 3,
          borderRadius: 2
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Lista de Provas
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalProvas} provas cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/provas/create"
                  sx={{ 
                    borderRadius: 2,
                    textTransform: 'none',
                    px: 3,
                    py: 1.5
                  }}
                >
                  Nova Prova
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<UploadFileIcon />}
                  onClick={handleImportJSONClick}
                  disabled={loadingImport}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                  }}
                >
                  {loadingImport ? <CircularProgress size={24} /> : 'Importar TXT'}
                </Button>
                <input
                  type="file"
                  accept=".txt"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImportJSON}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Search Card */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Buscar provas..."
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Delete Selected Button */}
      {selectedProvas.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button 
            variant="contained"
            color="error"
            onClick={handleDeleteSelected}
            startIcon={<DeleteIcon />}
            sx={{ 
              borderRadius: 2,
              textTransform: 'none'
            }}
          >
            Deletar {selectedProvas.length} {selectedProvas.length === 1 ? 'Prova' : 'Provas'}
          </Button>
        </Box>
      )}

      {/* Main Content */}
      {loadingFetch ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2, borderRadius: 2 }}>{error}</Alert>
      ) : (
        <Card sx={{ borderRadius: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.05) }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProvas.length === provas.length && provas.length > 0}
                      onChange={handleSelectAll}
                      indeterminate={selectedProvas.length > 0 && selectedProvas.length < provas.length}
                    />
                  </TableCell>
                  {/* ID */}
                  <TableCell onClick={() => handleSort('id')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      ID
                      {sortField === 'id' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Título */}
                  <TableCell onClick={() => handleSort('titulo')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Título
                      {sortField === 'titulo' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Matéria */}
                  <TableCell onClick={() => handleSort('materia_nome')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Matéria
                      {sortField === 'materia_nome' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Tempo */}
                  <TableCell onClick={() => handleSort('tempo')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Tempo
                      {sortField === 'tempo' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Questões */}
                  <TableCell onClick={() => handleSort('total_questoes')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Questões
                      {sortField === 'total_questoes' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Data de Criação */}
                  <TableCell onClick={() => handleSort('created_at')} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Data de Criação
                      {sortField === 'created_at' && (
                        sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Box>
                  </TableCell>
                  {/* Ações */}
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {provas.map((prova) => (
                  <TableRow 
                    key={prova.id} 
                    hover
                    sx={{ '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.02) } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedProvas.includes(prova.id)}
                        onChange={(e) => handleSelectOne(e, prova.id)}
                      />
                    </TableCell>
                    <TableCell>#{prova.id}</TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{prova.titulo}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={<SchoolIcon sx={{ fontSize: 16 }} />}
                        label={prova.materia_nome}
                        size="small"
                        sx={{ borderRadius: 1 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTimeIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                        <Typography variant="body2">{prova.tempo} min</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<QuizIcon />}
                        component={Link}
                        to={`/admin/questoes?prova_id=${prova.id}${prova.materia_id ? `&materia_id=${prova.materia_id}` : ''}`}
                        sx={{ 
                          borderRadius: 1,
                          textTransform: 'none'
                        }}
                      >
                        {prova.total_questoes || 0} Questões
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalendarTodayIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                        <Typography variant="body2">
                          {new Date(prova.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Editar">
                          <IconButton 
                            size="small"
                            color="primary"
                            component={Link} 
                            to={`/admin/provas/edit/${prova.id}`}
                            sx={{ 
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2)
                              }
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Exportar">
                          <IconButton 
                            size="small"
                            color="info"
                            onClick={() => handleExportSingleProva(prova.id)}
                            sx={{ 
                              backgroundColor: alpha(theme.palette.info.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.info.main, 0.2)
                              }
                            }}
                          >
                            <DownloadIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Deletar">
                          <IconButton 
                            size="small"
                            color="error"
                            onClick={() => handleDelete(prova.id)}
                            sx={{ 
                              backgroundColor: alpha(theme.palette.error.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.error.main, 0.2)
                              }
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {provas.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center" sx={{ py: 8 }}>
                      <Typography variant="h6" color="text.secondary">
                        Nenhuma Prova encontrada
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Tente ajustar os filtros ou criar uma nova prova
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}

      {/* Feedback Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertSnackbar 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>

      {/* Duplicate Provas Modal */}
      <Dialog open={isDuplicateModalOpen} onClose={() => {}} maxWidth="sm" fullWidth>
        <DialogTitle>Provas com Nomes Duplicados</DialogTitle>
        <DialogContent>
          <Typography>
            Algumas provas que você está tentando importar possuem nomes que já existem. Por favor, altere os nomes para evitar duplicatas.
          </Typography>
          <Box component="form" sx={{ mt: 2 }}>
            {duplicateProvas.map((prova, index) => {
              const isDuplicate = provas.some(p => p.titulo === (prova.newTitle || prova.titulo));
              return (
                <TextField
                  key={prova.titulo}
                  label={`Novo Título para "${prova.titulo}"`}
                  fullWidth
                  margin="normal"
                  value={prova.newTitle || prova.titulo}
                  onChange={(e) => handleDuplicateTitleChange(prova.titulo, e.target.value)}
                  error={isDuplicate}
                  helperText={isDuplicate ? 'Este título já está em uso. Por favor, escolha outro.' : ''}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              // Permitir que o usuário cancele a importação
              setIsDuplicateModalOpen(false);
              setDuplicateProvas([]);
              setImportedProvas([]);
              setLoadingImport(false);
              setSnackbar({ 
                open: true, 
                message: 'Importação cancelada.', 
                severity: 'info' 
              });
            }} 
            color="secondary"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleResolveDuplicates} 
            variant="contained" 
            color="primary"
            disabled={
              duplicateProvas.some(prova => 
                !prova.newTitle || 
                prova.newTitle.trim() === '' ||
                provas.some(p => p.titulo === prova.newTitle)
              )
            }
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Confirmação de Exclusão */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          {Array.isArray(deleteTarget) ? 'Confirmar Exclusão' : 'Confirmar Exclusão'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Array.isArray(deleteTarget)
              ? `Tem certeza que deseja deletar ${deleteTarget.length} prova(s) selecionada(s)? Esta ação não pode ser desfeita.`
              : `Tem certeza que deseja deletar a prova "${deleteTarget?.titulo}"? Esta ação não pode ser desfeita.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProvasList;
