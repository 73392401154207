import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  Alert,
  Paper,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Container,
  Card,
  CardContent,
  Chip,
  Fade,
  alpha,
  useTheme,
  Tooltip,
  Stack
} from '@mui/material';
import { 
  School as SchoolIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIcon from '@mui/icons-material/Assignment';

const HistoricoUsuario = () => {
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchHistorico = async () => {
      setLoading(true);
      setError(null);
      try {
 // Atualizando a URL para incluir o parâmetro 'search' corretamente
 const url = `https://medchoices.com.br:3001/admin/historico?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`;

        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        const data = await response.json();

        if (data.success) {
          setHistorico(data.historico);
          setTotal(data.total);
        } else {
          setError('Erro ao carregar o histórico. Tente novamente.');
        }
      } catch (error) {
        setError('Erro ao conectar com o servidor.');
      } finally {
        setLoading(false);
      }
    };
    fetchHistorico();
  }, [page, rowsPerPage, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const getPontuacaoColor = (pontuacao) => {
    if (pontuacao >= 80) return theme.palette.success.main;
    if (pontuacao >= 60) return theme.palette.info.main;
    if (pontuacao >= 40) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  const formatarTempo = (tempo) => {
    const minutos = Math.floor(tempo / 60);
    const segundos = tempo % 60;
    return `${minutos}m ${segundos}s`;
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Card 
          elevation={0}
          sx={{ 
            bgcolor: alpha(theme.palette.primary.main, 0.04),
            mb: 3,
            borderRadius: 3,
            position: 'relative',
            overflow: 'visible'
          }}
        >
          <CardContent sx={{ position: 'relative' }}>
            <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <AssignmentIcon 
                    sx={{ 
                      fontSize: 40, 
                      color: theme.palette.primary.main,
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      p: 1,
                      borderRadius: 2
                    }} 
                  />
                  <Typography variant="h4" color="primary">
                    Meu Histórico de Provas
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Buscar por título da prova ou nome do usuário..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      '&:hover': {
                        '& > fieldset': {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3, 
                  borderRadius: 2,
                  '& .MuiAlert-icon': {
                    fontSize: '1.5rem'
                  }
                }}
                variant="filled"
              >
                {error}
              </Alert>
            )}

            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress size={60} />
              </Box>
            ) : (
              <Fade in={!loading}>
                <Paper 
                  elevation={2} 
                  sx={{ 
                    borderRadius: 3,
                    overflow: 'hidden',
                    bgcolor: 'background.paper',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      boxShadow: theme.shadows[4]
                    }
                  }}
                >
                  <TableContainer>
                    <Table sx={{ minWidth: 800 }}>
                      <TableHead>
                        <TableRow sx={{ bgcolor: alpha(theme.palette.primary.main, 0.03) }}>
                          <TableCell sx={{ fontWeight: 600, py: 2 }}>Prova</TableCell>
                          <TableCell sx={{ fontWeight: 600, py: 2 }}>Usuário</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, py: 2 }}>Desempenho</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, py: 2 }}>Tempo</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, py: 2 }}>Data</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, py: 2 }}>Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historico.length > 0 ? (
                          historico.map((item) => (
                            <TableRow 
                              key={item.resultado_id}
                              hover
                              sx={{ 
                                '&:last-child td, &:last-child th': { border: 0 },
                                transition: 'all 0.2s',
                                '&:hover': {
                                  bgcolor: alpha(theme.palette.primary.main, 0.02)
                                }
                              }}
                            >
                              <TableCell>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                <Chip 
                                icon={<SchoolIcon sx={{ fontSize: 16 }} />}
                                label={item.prova_titulo}
                                size="small"
                                sx={{ borderRadius: 1 }}
                                />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                  {item.usuario_nome}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Stack spacing={1.5} alignItems="center">
                                  <Chip
                                    label={`${item.pontuacao}%`}
                                    sx={{
                                      bgcolor: getPontuacaoColor(item.pontuacao),
                                      color: 'white',
                                      fontWeight: 500,
                                      fontSize: '0.9rem',
                                      py: 0.5
                                    }}
                                  />
                                  <Stack direction="row" spacing={1}>
                                    <Tooltip title="Acertos">
                                      <Chip
                                        size="small"
                                        icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
                                        label={item.acertos}
                                        color="success"
                                        variant="outlined"
                                        sx={{ fontWeight: 500 }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Erros">
                                      <Chip
                                        size="small"
                                        icon={<CancelIcon sx={{ fontSize: 16 }} />}
                                        label={item.erros}
                                        color="error"
                                        variant="outlined"
                                        sx={{ fontWeight: 500 }}
                                      />
                                    </Tooltip>
                                  </Stack>
                                </Stack>
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  icon={<AccessTimeIcon />}
                                  label={formatarTempo(item.tempo_utilizado)}
                                  // variant="outlined"
                                  color="primary"
                                  sx={{ fontWeight: 500 }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    color: theme.palette.text.secondary,
                                    fontWeight: 500 
                                  }}
                                >
                                  {new Date(item.data_realizacao).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Ver detalhes">
                                  <IconButton
                                    color="primary"
                                    onClick={() => navigate(`/historico/${item.resultado_id}`)}
                                    sx={{
                                      transition: 'all 0.2s',
                                      '&:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        transform: 'scale(1.1)'
                                      }
                                    }}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center" sx={{ py: 8 }}>
                              <Stack spacing={2} alignItems="center">
                                <AssignmentIcon 
                                  sx={{ 
                                    fontSize: 60,
                                    color: theme.palette.text.secondary,
                                    opacity: 0.5
                                  }} 
                                />
                                <Typography variant="h6" color="textSecondary">
                                  Nenhum histórico encontrado
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Realize algumas provas para ver seu histórico aqui
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Itens por página"
                    labelDisplayedRows={({ from, to, count }) => 
                      `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
                    }
                    sx={{
                      borderTop: `1px solid ${theme.palette.divider}`,
                      '.MuiTablePagination-select': {
                        fontWeight: 500
                      }
                    }}
                  />
                </Paper>
              </Fade>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default HistoricoUsuario;
