// frontend/src/pages/Admin/Questoes/QuestoesList.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Stack,
  Divider,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  Preview as PreviewIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';

// Componente de Alerta
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Componente para confirmar exclusão
const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

// Componente para o Diálogo de Confirmação de Deleção (para múltiplas seleções)
const DeleteSelectedConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

const QuestoesList = () => {
  // Estados
  const [questoes, setQuestoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [totalQuestoes, setTotalQuestoes] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Novo estado para itens por página
  const [selectedQuestoes, setSelectedQuestoes] = useState([]);
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewQuestao, setPreviewQuestao] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [handleDuplicates, setHandleDuplicates] = useState('ignore');
  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = useState(false); // Novo estado para deleção múltipla
  const [importLoading, setImportLoading] = useState(false); // Adicionado para controle de loading

  const location = useLocation();
  const navigate = useNavigate();

  // Extrair prova_id, search e page da URL
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const prova_id = params.get('prova_id') || '';
  const searchParam = params.get('search') || '';
  const pageParam = parseInt(params.get('page') || '1', 10);
  const limitParam = parseInt(params.get('limit') || '10', 10); // Novo parâmetro para limite

  useEffect(() => {
    setSearch(searchParam);
    setCurrentPage(pageParam);
    setItemsPerPage(limitParam);
  }, [searchParam, pageParam, limitParam]);

  // Debounce para otimizar buscas
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const newParams = new URLSearchParams(location.search);
        if (value) {
          newParams.set('search', value);
        } else {
          newParams.delete('search');
        }
        newParams.set('page', '1');
        newParams.set('limit', itemsPerPage.toString()); // Atualizar limite
        navigate(`?${newParams.toString()}`);
      }, 500),
    [location.search, navigate, itemsPerPage]
  );

  // Função para buscar questões
  const fetchQuestoes = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      let url = `https://medchoices.com.br:3001/admin/questoes?page=${pageParam}&limit=${itemsPerPage}`;
      if (prova_id) url += `&prova_id=${prova_id}`;
      if (searchParam) url += `&search=${encodeURIComponent(searchParam)}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      console.log('Dados recebidos:', data);

      if (data.success) {
        setQuestoes(data.questoes);
        setTotalQuestoes(data.totalQuestoes);
        setTotalPages(data.totalPages);
        setCurrentPage(data.currentPage);
        setSelectedQuestoes([]); // Resetar seleção ao buscar
      } else {
        setError(data.message || 'Erro ao buscar Questões.');
      }
    } catch (error) {
      console.error('Erro ao buscar Questões:', error);
      setError('Erro ao buscar Questões.');
    } finally {
      setLoading(false);
    }
  }, [prova_id, searchParam, pageParam, itemsPerPage]);

  useEffect(() => {
    fetchQuestoes();
  }, [fetchQuestoes]);

  // Função para deletar uma questão individual
  const handleDelete = async () => {
    if (!deleteTarget) return;

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/${deleteTarget}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Questão deletada com sucesso!', severity: 'success' });
        fetchQuestoes();
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar Questão.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar Questão:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar Questão.', severity: 'error' });
    } finally {
      setLoading(false);
      setDeleteTarget(null);
      setDeleteDialogOpen(false);
    }
  };

  // Função para deletar múltiplas questões selecionadas
  const handleDeleteSelected = async () => {
    if (selectedQuestoes.length === 0) return;

    setLoading(true);
    try {
      // Supondo que o backend suporte a exclusão em lote via DELETE com IDs no corpo
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ ids: selectedQuestoes }),
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Questões deletadas com sucesso!', severity: 'success' });
        fetchQuestoes();
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar Questões.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar Questões:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar Questões.', severity: 'error' });
    } finally {
      setLoading(false);
      setSelectedQuestoes([]);
      setDeleteSelectedDialogOpen(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handlePageChange = (event, value) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set('page', value.toString());
    newParams.set('limit', itemsPerPage.toString()); // Atualizar limite
    navigate(`?${newParams.toString()}`);
  };

  const handleItemsPerPageChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setItemsPerPage(newLimit);
    const newParams = new URLSearchParams(location.search);
    newParams.set('limit', newLimit.toString());
    newParams.set('page', '1'); // Resetar para a primeira página
    navigate(`?${newParams.toString()}`);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = questoes.map((q) => q.id);
      setSelectedQuestoes(allIds);
    } else {
      setSelectedQuestoes([]);
    }
  };

  const handleSelectOne = (e, id) => {
    if (e.target.checked) {
      setSelectedQuestoes((prev) => [...prev, id]);
    } else {
      setSelectedQuestoes((prev) => prev.filter((qid) => qid !== id));
    }
  };

  const handlePreview = (questao) => {
    setPreviewQuestao(questao);
    setPreviewOpen(true);
  };

  const isAllSelected = questoes.length > 0 && selectedQuestoes.length === questoes.length;

  // Função para exportar as questões
  const handleExportQuestoes = async () => {
    if (!prova_id) {
      console.error('prova_id não definido!');
      setSnackbar({ open: true, message: 'Selecione uma prova para exportar as questões.', severity: 'warning' });
      return;
    }

    console.log('Iniciando exportação das questões...');
    console.log('prova_id para exportação:', prova_id);

    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/export?prova_id=${prova_id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const text = await response.text();
      console.log('Resposta do servidor (exportação):', text);

      if (!response.ok) {
        const error = JSON.parse(text);
        throw new Error(error.message || 'Erro ao exportar questões.');
      }

      const blob = new Blob([text], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `questoes_prova_${prova_id}.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbar({ open: true, message: 'Questões exportadas com sucesso!', severity: 'success' });
    } catch (error) {
      console.error('Erro ao exportar questões:', error);
      setSnackbar({ open: true, message: error.message || 'Erro ao exportar questões.', severity: 'error' });
    }
  };

  // Função para abrir o diálogo de importação
  const openImportDialog = () => {
    if (!prova_id) {
      setSnackbar({ open: true, message: 'prova_id é necessário para importar questões.', severity: 'error' });
      return;
    }
    setImportDialogOpen(true);
  };

  // Função para fechar o diálogo de importação
  const closeImportDialog = () => {
    setImportDialogOpen(false);
    setSelectedFileName(''); // Resetar nome do arquivo selecionado
    setHandleDuplicates('ignore'); // Resetar escolha de tratamento de duplicatas
  };

  // Função para importar questões
  const handleImportQuestoes = async (event) => {
    event.preventDefault();
    const fileInput = event.target.elements.file;
    const file = fileInput.files[0];
    if (!file) {
      setSnackbar({ open: true, message: 'Nenhum arquivo selecionado.', severity: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('handle_duplicates', handleDuplicates);

    setImportLoading(true); // Início do loading de importação
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/questoes/import?prova_id=${prova_id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        let mensagem = `Importação concluída: ${data.totalImportadas} importadas, ${data.totalFalhas} falhas.`;
        if (data.falhas && data.falhas.length > 0) {
          const falhasDetalhadas = data.falhas.map(f => `Linha ${f.linha}: ${f.erro}`).join('\n');
          mensagem += `\nFalhas:\n${falhasDetalhadas}`;
        }
        setSnackbar({ open: true, message: mensagem, severity: 'success' });
        fetchQuestoes();
        closeImportDialog();
      } else {
        // Exibir detalhes das falhas, se disponíveis
        const mensagemErro = data.falhas && data.falhas.length > 0
          ? `Falhas:\n${data.falhas.map(f => `Linha ${f.linha}: ${f.erro}`).join('\n')}`
          : data.message || 'Erro ao importar questões.';
        setSnackbar({ open: true, message: mensagemErro, severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao importar questões:', error);
      setSnackbar({ open: true, message: 'Erro ao importar questões.', severity: 'error' });
    } finally {
      setImportLoading(false); // Fim do loading de importação
    }
  };

  // Função para lidar com a seleção de arquivo e exibir o nome
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Arquivo selecionado:', file); // Log para depuração
    if (file) {
      setSelectedFileName(file.name);
    } else {
      setSelectedFileName('');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Função para fechar o diálogo de pré-visualização
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewQuestao(null);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Materias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
        <Typography color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Questões
        </Typography>
      </Breadcrumbs>
      {/* Cabeçalho */}
      <Card
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main + '0A', // 10% opacity
          mb: 3,
          borderRadius: 2
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Lista de Questões
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalQuestoes} questões cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={Link}
                to={`/admin/questoes/create${prova_id ? `?prova_id=${prova_id}` : ''}`}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5
                }}
              >
                Criar Nova Questão
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Campo de Busca e Botões de Exportar/Importar */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar Questões"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 2 }}>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="items-per-page-label">Por Página</InputLabel>
                <Select
                  labelId="items-per-page-label"
                  id="items-per-page-select"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Por Página"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Button variant="outlined" color="primary" startIcon={<DownloadIcon />} onClick={handleExportQuestoes}>
                Exportar Questões
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<UploadIcon />}
                onClick={openImportDialog}
              >
                Importar Questões
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Botões de Ações para Múltiplas Seleções */}
      {selectedQuestoes.length > 0 && (
        <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                {selectedQuestoes.length} selecionadas
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteSelectedDialogOpen(true)}
              >
                Deletar Selecionadas
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Tabela */}
      <Card sx={{ borderRadius: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, py: 4 }}>
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2, borderRadius: 2, m: 2 }}>{error}</Alert>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: (theme) => theme.palette.primary.main + '1A' }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        indeterminate={selectedQuestoes.length > 0 && selectedQuestoes.length < questoes.length}
                        inputProps={{ 'aria-label': 'Selecionar todas as questões' }}
                      />
                    </TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Matéria</TableCell>
                    <TableCell>Prova</TableCell>
                    <TableCell>Enunciado</TableCell>
                    <TableCell>Opções</TableCell>
                    <TableCell>Resposta Correta</TableCell>
                    <TableCell>Imagem</TableCell>
                    <TableCell>Data de Criação</TableCell>
                    <TableCell>Data de Atualização</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questoes.map((questao, index) => {
                    // Calcular o número da questão com base na página e no limite
                    const questionNumber = (currentPage - 1) * itemsPerPage + index + 1;
                    return (
                      <TableRow key={questao.id} hover>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedQuestoes.includes(questao.id)}
                            onChange={(e) => handleSelectOne(e, questao.id)}
                            inputProps={{ 'aria-labelledby': `questao-${questao.id}` }}
                          />
                        </TableCell>
                        <TableCell>{questionNumber}</TableCell>
                        <TableCell>{questao.id}</TableCell>
                        <TableCell>{questao.materia_nome}</TableCell>
                        <TableCell>{questao.prova_titulo}</TableCell>
                        <TableCell>{questao.enunciado}</TableCell>
                        <TableCell>
                          <Stack spacing={0.5}>
                            <Typography variant="body2"><strong>A:</strong> {questao.opcao_a}</Typography>
                            <Typography variant="body2"><strong>B:</strong> {questao.opcao_b}</Typography>
                            <Typography variant="body2"><strong>C:</strong> {questao.opcao_c}</Typography>
                            <Typography variant="body2"><strong>D:</strong> {questao.opcao_d}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip label={questao.resposta_correta.toUpperCase()} color="success" />
                        </TableCell>
                        <TableCell>
                          {questao.imagem ? (
                            <Tooltip title="Ver Imagem">
                              <IconButton
                                color="primary"
                                component="a"
                                href={questao.imagem.startsWith('http') ? questao.imagem : `https://medchoices.com.br:3001/${questao.imagem}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ImageIcon />
                              </IconButton>
                            </Tooltip>
                          ) : 'N/A'}
                        </TableCell>
                        <TableCell>{new Date(questao.created_at).toLocaleString()}</TableCell>
                        <TableCell>{new Date(questao.updated_at).toLocaleString()}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Editar">
                            <IconButton color="primary" sx={{
                              backgroundColor: (theme) => theme.palette.primary.main + '2A', // 10% opacity
                              mb: 1,
                            }} component={Link} to={`/admin/questoes/edit/${questao.id}`}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Deletar">
                            <IconButton color="error" sx={{
                              backgroundColor: (theme) => theme.palette.error.main + '2A', mb: 1,

                            }} onClick={() => { setDeleteTarget(questao.id); setDeleteDialogOpen(true); }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Pré-visualizar">
                            <IconButton color="info" sx={{
                              backgroundColor: (theme) => theme.palette.info.main + '2A', mb: 1,
                              // 10% opacity
                            }} onClick={() => handlePreview(questao)}>
                              <PreviewIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {questoes.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Nenhuma Questão encontrada.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              {/* Contador de Questões */}
              <Typography variant="body2">
                Página {currentPage} de {totalPages}
              </Typography>
              {/* Paginação */}
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Card>

      {/* Diálogo de Pré-visualização */}
      {previewQuestao && (
        <Dialog
          open={previewOpen}
          onClose={handleClosePreview}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Pré-visualização da Questão</DialogTitle>
          <DialogContent dividers>
            <Typography variant="h6" gutterBottom>
              {previewQuestao.enunciado}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography><strong>A:</strong> {previewQuestao.opcao_a}</Typography>
              <Typography><strong>B:</strong> {previewQuestao.opcao_b}</Typography>
              <Typography><strong>C:</strong> {previewQuestao.opcao_c}</Typography>
              <Typography><strong>D:</strong> {previewQuestao.opcao_d}</Typography>
            </Box>
            <Typography variant="subtitle1">
              Resposta Correta: {previewQuestao.resposta_correta.toUpperCase()}
            </Typography>
            {previewQuestao.imagem && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Imagem:</Typography>
                <img
                  src={previewQuestao.imagem.startsWith('http') ? previewQuestao.imagem : `https://medchoices.com.br:3001/${previewQuestao.imagem}`}
                  alt="Questao"
                  style={{ maxWidth: '100%', height: '500px', objectFit: 'contain' }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePreview} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Diálogo de Importação */}
      <Dialog
        open={importDialogOpen}
        onClose={closeImportDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Importar Questões</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione o arquivo .txt contendo as questões que deseja importar.
          </DialogContentText>
          <form onSubmit={handleImportQuestoes}>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                disabled={importLoading} // Desativar durante o loading
              >
                Selecionar Arquivo
                <input
                  type="file"
                  accept=".txt"
                  hidden
                  name="file"
                  onChange={handleFileChange}
                  disabled={importLoading} // Desativar durante o loading
                />
              </Button>
              {selectedFileName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Arquivo selecionado: {selectedFileName}
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 3 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tratamento de Duplicatas</FormLabel>
                <RadioGroup
                  aria-label="handle_duplicates"
                  name="handle_duplicates"
                  value={handleDuplicates}
                  onChange={(e) => setHandleDuplicates(e.target.value)}
                >
                  <FormControlLabel value="ignore" control={<Radio />} label="Ignorar Duplicatas" />
                  <FormControlLabel value="allow" control={<Radio />} label="Adicionar Duplicatas" />
                </RadioGroup>
              </FormControl>
            </Box>
            <DialogActions sx={{ mt: 3 }}>
              <Button onClick={closeImportDialog} color="secondary" disabled={importLoading}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={importLoading}>
                {importLoading ? <CircularProgress size={24} /> : 'Importar'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Diálogo de Confirmação de Deleção */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => { setDeleteDialogOpen(false); setDeleteTarget(null); }}
        onConfirm={handleDelete}
        title="Confirmar Deleção"
        description="Tem certeza que deseja deletar esta Questão? Esta ação não pode ser desfeita."
      />

      {/* Diálogo de Confirmação de Deleção para Seleções Múltiplas */}
      <DeleteSelectedConfirmationDialog
        open={deleteSelectedDialogOpen}
        onClose={() => setDeleteSelectedDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        title="Confirmar Deleção das Questões Selecionadas"
        description="Tem certeza que deseja deletar todas as questões selecionadas? Esta ação não pode ser desfeita."
      />

      {/* Snackbar para Mensagens */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Container>
  );
};

export default QuestoesList;
