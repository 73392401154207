import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Paper,
  Container,
  Alert,
  Fade,
  TableContainer,
  Breadcrumbs,
  Link,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useTheme,
  Chip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

const Faculdades = () => {
  const [faculdades, setFaculdades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentFaculdade, setCurrentFaculdade] = useState({ id: null, nome: '' });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [operationLoading, setOperationLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [faculdadeToDelete, setFaculdadeToDelete] = useState(null);
  const theme = useTheme();

  const fetchFaculdades = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://medchoices.com.br:3001/admin/faculdades', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();
      if (data.success) {
        setFaculdades(data.faculdades);
      } else {
        throw new Error(data.message || 'Erro ao carregar faculdades.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaculdades();
  }, []);

  const handleDialogOpen = (faculdade = { id: null, nome: '' }) => {
    setCurrentFaculdade(faculdade);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentFaculdade({ id: null, nome: '' });
  };

  const handleSave = async () => {
    const { id, nome } = currentFaculdade;

    if (!nome.trim()) {
      setSnackbarMessage('O nome da faculdade é obrigatório.');
      return;
    }

    setOperationLoading(true);
    try {
      const response = await fetch(
        id
          ? `https://medchoices.com.br:3001/admin/faculdades/${id}`
          : 'https://medchoices.com.br:3001/admin/faculdades',
        {
          method: id ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ nome }),
        }
      );
      const data = await response.json();

      if (data.success) {
        setSnackbarMessage('Faculdade salva com sucesso!');
        fetchFaculdades();
        handleDialogClose();
      } else {
        throw new Error(data.message || 'Erro ao salvar a faculdade.');
      }
    } catch (err) {
      setSnackbarMessage(err.message);
    } finally {
      setOperationLoading(false);
    }
  };

  const handleDelete = (faculdade) => {
    setFaculdadeToDelete(faculdade);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!faculdadeToDelete) return;

    setDeleteLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/faculdades/${faculdadeToDelete.id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();

      if (data.success) {
        setSnackbarMessage('Faculdade excluída com sucesso!');
        fetchFaculdades();
        setDeleteDialogOpen(false);
      } else {
        throw new Error(data.message || 'Erro ao excluir a faculdade.');
      }
    } catch (err) {
      setSnackbarMessage(err.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Breadcrumbs>
          <Link
            component="button"
            variant="body1"
            onClick={() => window.history.back()}
            color="inherit"
          >
            Admin
          </Link>
          <Typography color="text.primary">Faculdades</Typography>
        </Breadcrumbs>
      </Box>

      <Paper
        elevation={0}
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.05),
          mb: 3,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              color="primary"
              component="h1"
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              Gerenciar Faculdades
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {faculdades.length > 0 ? `Total de ${faculdades.length} faculdades` : 'Nenhuma faculdade cadastrada'}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleDialogOpen()}
              sx={{ borderRadius: 2, textTransform: 'none', px: 3, py: 1.5 }}
            >
              Nova Faculdade
            </Button>
          </Box>
        </Box>
      </Paper>

      {error && (
        <Fade in={Boolean(error)}>
          <Alert
            severity="error"
            sx={{ mb: 3 }}
            onClose={() => setError('')}
          >
            {error}
          </Alert>
        </Fade>
      )}

      <Paper elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faculdades.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center" sx={{ py: 8 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                      <WarningIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
                      <Typography variant="h6" color="text.secondary">
                        Nenhuma faculdade encontrada
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Clique em "Nova Faculdade" para adicionar a primeira
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                faculdades.map((faculdade) => (
                  <TableRow key={faculdade.id} hover>
                    <TableCell>{faculdade.id}</TableCell>
                    <TableCell>{faculdade.nome}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Editar">
                        <IconButton
                          size="small"
                          onClick={() => handleDialogOpen(faculdade)}
                          color="primary"
                          sx={{
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.primary.main, 0.2),
                            },
                            mr: 1,
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(faculdade)}
                          color="error"
                          sx={{
                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.error.main, 0.2),
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{currentFaculdade.id ? 'Editar Faculdade' : 'Nova Faculdade'}</DialogTitle>
        <DialogContent>
          {operationLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={100}>
              <CircularProgress />
            </Box>
          ) : (
            <TextField
              fullWidth
              label="Nome da Faculdade"
              value={currentFaculdade.nome}
              onChange={(e) =>
                setCurrentFaculdade((prev) => ({ ...prev, nome: e.target.value }))
              }
              sx={{ mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleDialogClose} startIcon={<CloseIcon />} variant="outlined" disabled={operationLoading}>
            Cancelar
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />} variant="contained" disabled={operationLoading}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          {deleteLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={100}>
              <CircularProgress />
            </Box>
          ) : (
            <Typography>
              Tem certeza que deseja excluir a faculdade "{faculdadeToDelete?.nome}"? Esta ação não pode ser desfeita.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
            disabled={deleteLoading}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            disabled={deleteLoading}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Faculdades;
