import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  Alert,
  Paper,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Container,
  Card,
  CardContent,
  Chip,
  Fade,
  alpha,
  useTheme,
  Tooltip,
  Stack,
  Toolbar,
  TableSortLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button, // Importação do Button
} from '@mui/material';
import {
  School as SchoolIcon,
  Search as SearchIcon,
  AccessTime as AccessTimeIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  Assignment as AssignmentIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import useMediaQuery from '@mui/material/useMediaQuery';

const HistoricoUsuario = () => {
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState('desc'); // 'asc' ou 'desc'
  const [orderBy, setOrderBy] = useState('data_realizacao'); // Coluna padrão para ordenação
  const navigate = useNavigate();
  const theme = useTheme();

  // Definição do estilo visuallyHidden
  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  };

  // Verifica se o dispositivo é móvel
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Supondo que a função do usuário esteja salva no localStorage após o login
  const userFuncao = localStorage.getItem('userFuncao'); // 'admin' ou 'usuario'

  // Função para buscar o histórico
  const fetchHistorico = async (search, sortBy, sortOrder) => {
    setLoading(true);
    setError(null);
    try {
      const url = `https://medchoices.com.br:3001/admin/me/historico?page=${page + 1}&limit=${rowsPerPage}&search=${encodeURIComponent(search)}&sortBy=${sortBy}&order=${sortOrder}`;

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const data = await response.json();

      if (data.success) {
        setHistorico(data.historico);
        setTotal(data.total);
      } else {
        setError(data.message || 'Erro ao carregar o histórico. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };

  // Debounce da busca
  const debouncedFetchHistorico = useMemo(
    () => debounce((search, sortBy, sortOrder) => fetchHistorico(search, sortBy, sortOrder), 300),
    [page, rowsPerPage]
  );

  useEffect(() => {
    debouncedFetchHistorico(searchTerm, orderBy, order);
    // Cleanup do debounce
    return () => debouncedFetchHistorico.cancel();
  }, [searchTerm, orderBy, order, debouncedFetchHistorico]);

  useEffect(() => {
    fetchHistorico(searchTerm, orderBy, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getPontuacaoColor = (pontuacao) => {
    if (pontuacao >= 80) return theme.palette.success.main;
    if (pontuacao >= 60) return theme.palette.info.main;
    if (pontuacao >= 40) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', marginTop: '64px' }}> {/* Adiciona margem superior */}
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Card
          elevation={0}
          sx={{
            bgcolor: alpha(theme.palette.primary.main, 0.04),
            mb: 3,
            borderRadius: 3,
            position: 'relative',
            overflow: 'visible'
          }}
        >
          <CardContent sx={{ position: 'relative' }}>
            <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <AssignmentIcon
                    sx={{
                      fontSize: isMobile ? 30 : 40, // Ajuste o tamanho conforme o dispositivo
                      color: theme.palette.primary.main,
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      p: 1,
                      borderRadius: 2
                    }}
                  />
                  <Typography variant={isMobile ? "h5" : "h4"} color="primary">
                    Meu Histórico de Provas
                  </Typography>
                </Stack>
                {/* Exibindo o número total de provas realizadas */}
                <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
                  Total de Provas Realizadas: {total}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Buscar por título da prova..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                  aria-label="Buscar por título da prova"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      '&:hover': {
                        '& > fieldset': {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            {error && (
              <Alert
                severity="error"
                sx={{
                  mb: 3,
                  borderRadius: 2,
                  '& .MuiAlert-icon': {
                    fontSize: '1.5rem'
                  }
                }}
                variant="filled"
                aria-live="assertive"
              >
                {error}
              </Alert>
            )}

            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress size={isMobile ? 40 : 60} aria-label="Carregando histórico" />
              </Box>
            ) : (
              <Fade in={!loading}>
                {isMobile ? (
                  // Visualização de Cards para Mobile
                  <Box>
                    <Typography variant="subtitle1" display="flex" sx={{
                      color: 'grey.500',
                      mb: 2,
                      animation: 'bounce 2s infinite',
                      '@keyframes bounce': {
                        '0%, 100%': { transform: 'translateY(0)' },
                        '50%': { transform: 'translateY(8px)' },
                      },
                    }}>Clique para abrir os detalhes
                      <ExpandMoreIcon
                        sx={{
                          color: 'grey.500',
                          animation: 'bounce 2s infinite',
                          '@keyframes bounce': {
                            '0%, 100%': { transform: 'translateY(0)' },
                            '50%': { transform: 'translateY(8px)' },
                          },
                        }}
                      /></Typography>

                    {historico.length > 0 ? (
                      historico.map((item) => (
                        <Accordion key={item.resultado_id} sx={{ mb: 2 }}>
                          <AccordionSummary

                            aria-controls={`panel-content-${item.resultado_id}`}
                            id={`panel-header-${item.resultado_id}`}
                            variant="contained"
                            color="secondary"
                            // startIcon={<VisibilityIcon />}
                            onClick={() => navigate(`/historico/${item.resultado_id}`)}
                            aria-label={`Ver detalhes da prova ${item.prova_titulo}`}
                          >
                            <Stack spacing={1} width="100%">
                              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                <Chip
                                  icon={<SchoolIcon sx={{ fontSize: 16 }} />}
                                  label={item.prova_titulo}
                                  size="small"
                                  sx={{ borderRadius: 1 }}
                                />
                                <Chip
                                  icon={<AccessTimeIcon />}
                                  size="small"
                                  label={`${Math.floor(item.tempo_utilizado / 60)}m ${item.tempo_utilizado % 60}s`}
                                  color="primary" // Reativando a cor primária para consistência visual
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '0.75rem', // Reduz o tamanho da fonte
                                    padding: '2px 6px', // Ajusta o padding para um Chip mais compacto
                                    maxWidth: '100px', // Define uma largura máxima para evitar expansão excessiva
                                    display: 'inline-flex', // Garante que o Chip ocupe apenas o espaço necessário
                                    whiteSpace: 'nowrap', // Evita que o texto quebre em múltiplas linhas
                                    ml: 2
                                  }}
                                  aria-label={`Tempo utilizado: ${Math.floor(item.tempo_utilizado / 60)} minutos e ${item.tempo_utilizado % 60} segundos`}
                                />
                              </Typography>
                              <Stack direction="row" spacing={1} alignItems="center">
                                <Chip
                                  label={`${item.pontuacao}%`}
                                  sx={{
                                    bgcolor: getPontuacaoColor(item.pontuacao),
                                    color: 'white',
                                    fontWeight: 500,
                                    fontSize: '0.9rem',
                                    py: 0.5
                                  }}
                                />
                                <Chip
                                  size="small"
                                  icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
                                  label={`Acertos: ${item.acertos}`}
                                  color="success"
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                                <Chip
                                  size="small"
                                  icon={<CancelIcon sx={{ fontSize: 16 }} />}
                                  label={`Erros: ${item.erros}`}
                                  color="error"
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              </Stack>

                              <Typography
                                variant="body2"
                                sx={{
                                  color: theme.palette.text.secondary,
                                  fontWeight: 500
                                }}
                              >
                                {new Date(item.data_realizacao).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                })}
                              </Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box>
                              {/* Adicione mais detalhes conforme necessário */}
                              <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<VisibilityIcon />}
                                onClick={() => navigate(`/historico/${item.resultado_id}`)}
                                aria-label={`Ver detalhes da prova ${item.prova_titulo}`}
                              >
                                Ver Detalhes
                              </Button>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : (
                      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="400px">
                        <AssignmentIcon
                          sx={{
                            fontSize: 60,
                            color: theme.palette.text.secondary,
                            opacity: 0.5
                          }}
                        />
                        <Typography variant="h6" color="textSecondary">
                          Nenhum histórico encontrado
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Realize algumas provas para ver seu histórico aqui
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : (
                  // Visualização de Tabela para Desktop
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: 3,
                      overflow: 'hidden',
                      bgcolor: 'background.paper',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        boxShadow: theme.shadows[4]
                      }
                    }}
                  >
                    <TableContainer sx={{ overflowX: 'auto' }}>
                      <Table sx={{ minWidth: 800 }} aria-label="Histórico de Provas">
                        <TableHead>
                          <TableRow sx={{ bgcolor: alpha(theme.palette.primary.main, 0.03) }}>
                            {userFuncao === 'admin' && (
                              <TableCell
                                sortDirection={orderBy === 'usuario_nome' ? order : false}
                                sx={{ fontWeight: 600, py: 2 }}
                              >
                                <TableSortLabel
                                  active={orderBy === 'usuario_nome'}
                                  direction={orderBy === 'usuario_nome' ? order : 'asc'}
                                  onClick={() => handleRequestSort('usuario_nome')}
                                >
                                  Usuário
                                  {orderBy === 'usuario_nome' ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                            )}
                            <TableCell
                              sortDirection={orderBy === 'prova_titulo' ? order : false}
                              sx={{ fontWeight: 600, py: 2 }}
                            >
                              <TableSortLabel
                                active={orderBy === 'prova_titulo'}
                                direction={orderBy === 'prova_titulo' ? order : 'asc'}
                                onClick={() => handleRequestSort('prova_titulo')}
                              >
                                Prova
                                {orderBy === 'prova_titulo' ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="center"
                              sortDirection={orderBy === 'pontuacao' ? order : false}
                              sx={{ fontWeight: 600, py: 2 }}
                            >
                              <TableSortLabel
                                active={orderBy === 'pontuacao'}
                                direction={orderBy === 'pontuacao' ? order : 'asc'}
                                onClick={() => handleRequestSort('pontuacao')}
                              >
                                Desempenho
                                {orderBy === 'pontuacao' ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="center"
                              sortDirection={orderBy === 'tempo_utilizado' ? order : false}
                              sx={{ fontWeight: 600, py: 2 }}
                            >
                              <TableSortLabel
                                active={orderBy === 'tempo_utilizado'}
                                direction={orderBy === 'tempo_utilizado' ? order : 'asc'}
                                onClick={() => handleRequestSort('tempo_utilizado')}
                              >
                                Tempo
                                {orderBy === 'tempo_utilizado' ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="center"
                              sortDirection={orderBy === 'data_realizacao' ? order : false}
                              sx={{ fontWeight: 600, py: 2 }}
                            >
                              <TableSortLabel
                                active={orderBy === 'data_realizacao'}
                                direction={orderBy === 'data_realizacao' ? order : 'asc'}
                                onClick={() => handleRequestSort('data_realizacao')}
                              >
                                Data
                                {orderBy === 'data_realizacao' ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 600, py: 2 }}>
                              Ações
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {historico.length > 0 ? (
                            historico.map((item) => (
                              <TableRow
                                key={item.resultado_id}
                                hover
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  transition: 'all 0.2s',
                                  '&:hover': {
                                    bgcolor: alpha(theme.palette.primary.main, 0.02)
                                  }
                                }}
                              >
                                {userFuncao === 'admin' && (
                                  <TableCell>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                      {item.usuario_nome} {item.usuario_sobrenome}
                                    </Typography>
                                  </TableCell>
                                )}
                                <TableCell>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                    <Chip
                                      icon={<SchoolIcon sx={{ fontSize: 16 }} />}
                                      label={item.prova_titulo}
                                      size="small"
                                      sx={{ borderRadius: 1 }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Stack spacing={1.5} alignItems="center">
                                    <Chip
                                      label={`${item.pontuacao}%`}
                                      sx={{
                                        bgcolor: getPontuacaoColor(item.pontuacao),
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.9rem',
                                        py: 0.5
                                      }}
                                    />
                                    <Stack direction="row" spacing={1}>
                                      <Tooltip title="Acertos">
                                        <Chip
                                          size="small"
                                          icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
                                          label={item.acertos}
                                          color="success"
                                          variant="outlined"
                                          sx={{ fontWeight: 500 }}
                                        />
                                      </Tooltip>
                                      <Tooltip title="Erros">
                                        <Chip
                                          size="small"
                                          icon={<CancelIcon sx={{ fontSize: 16 }} />}
                                          label={item.erros}
                                          color="error"
                                          variant="outlined"
                                          sx={{ fontWeight: 500 }}
                                        />
                                      </Tooltip>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">
                                  <Chip
                                    icon={<AccessTimeIcon />}
                                    label={`${Math.floor(item.tempo_utilizado / 60)}m ${item.tempo_utilizado % 60}s`}
                                    color="primary"
                                    sx={{ fontWeight: 500 }}
                                    aria-label={`Tempo utilizado: ${Math.floor(item.tempo_utilizado / 60)} minutos e ${item.tempo_utilizado % 60} segundos`}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: theme.palette.text.secondary,
                                      fontWeight: 500
                                    }}
                                  >
                                    {new Date(item.data_realizacao).toLocaleDateString('pt-BR', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit'
                                    })}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Ver detalhes">
                                    <IconButton
                                      color="primary"
                                      onClick={() => navigate(`/historico/${item.resultado_id}`)}
                                      sx={{
                                        transition: 'all 0.2s',
                                        '&:hover': {
                                          bgcolor: alpha(theme.palette.primary.main, 0.1),
                                          transform: 'scale(1.1)'
                                        }
                                      }}
                                      aria-label={`Ver detalhes da prova ${item.prova_titulo}`}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={userFuncao === 'admin' ? 6 : 5} align="center" sx={{ py: 8 }}>
                                <Stack spacing={2} alignItems="center">
                                  <AssignmentIcon
                                    sx={{
                                      fontSize: 60,
                                      color: theme.palette.text.secondary,
                                      opacity: 0.5
                                    }}
                                  />
                                  <Typography variant="h6" color="textSecondary">
                                    Nenhum histórico encontrado
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Realize algumas provas para ver seu histórico aqui
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="Itens por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
                      }
                      sx={{
                        borderTop: `1px solid ${theme.palette.divider}`,
                        '.MuiTablePagination-select': {
                          fontWeight: 500
                        }
                      }}
                    />
                  </Paper>
                )}
              </Fade>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default HistoricoUsuario;
