import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
  useTheme,
  Chip,
  Fade,
  Alert,
  Snackbar,
  IconButton,
  Divider,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  School as SchoolIcon,
  Notifications as NotificationsIcon,
  HelpOutline as HelpOutlineIcon,
  Timer as TimerIcon,
  QuestionAnswer as QuestionAnswerIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon, // Para total de provas realizadas
  Equalizer as EqualizerIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Instrucoes from './Instrucoes';


const Home = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [materias, setMaterias] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [avisosPage, setAvisosPage] = useState(1);
  const [materiasPage, setMateriasPage] = useState(1);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Declarado no nível superior
  const [relatorios, setRelatorios] = useState(null);
  const [horaAtual, setHoraAtual] = useState(new Date());
  const [mostrarInstrucoes, setMostrarInstrucoes] = useState(false);
  const [instrucoesVisiveis, setInstrucoesVisiveis] = useState(true);
  const [userName, setUserName] = useState(auth.user?.nome || '');

  const itemsPerPage = 5;

  useEffect(() => {
    const fetchRelatorios = async () => {
      try {
        const token = localStorage.getItem('token');
  
        if (!token) {
          throw new Error('Token de autenticação não encontrado.');
        }
  
        const response = await fetch('https://medchoices.com.br:3001/admin/relatorios/usuario', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Adicionado para garantir compatibilidade
          },
        });
  
        if (!response.ok) {
          console.error('Resposta da API:', response.status, response.statusText);
          throw new Error('Erro ao buscar relatórios do usuário.');
        }
  
        const data = await response.json();
        console.log('Dados recebidos da API:', data); // Log para verificar a estrutura do retorno
        setRelatorios(data);
      } catch (error) {
        console.error('Erro ao carregar relatórios do usuário:', error);
        setError('Erro ao carregar os relatórios. Tente novamente mais tarde.');
        setShowSnackbar(true);
      }
    };
  
    fetchRelatorios();
  }, []);
  
  
    // Sincronizar nome do usuário com o armazenamento local
    useEffect(() => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser?.nome) {
        setUserName(storedUser.nome);
      }
    }, [auth.user]);

  useEffect(() => {
    // Simulação de API para verificar se é a primeira vez
    const verificarPrimeiroAcesso = async () => {
      try {
        const response = await fetch('https://medchoices.com.br:3001/admin/primeiro-acesso', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
    
        if (data?.primeiro_acesso === true) {
          setMostrarInstrucoes(true);
        }
      } catch (error) {
        console.error('Erro ao verificar primeiro acesso:', error);
        setMostrarInstrucoes(false); // Certifique-se de esconder instruções em caso de erro
      }
    };    
  
    verificarPrimeiroAcesso();
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => setHoraAtual(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);


  const fecharInstrucoes = () => {
    setInstrucoesVisiveis(false); // Ocultar com transição
    setTimeout(() => setMostrarInstrucoes(false), 300); // Remover do DOM após 300ms
  };

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const materiasResponse = await fetch('https://medchoices.com.br:3001/admin/materias', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const provasResponse = await fetch('https://medchoices.com.br:3001/admin/provas', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const avisosResponse = await fetch('https://medchoices.com.br:3001/admin/avisos', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!materiasResponse.ok || !provasResponse.ok || !avisosResponse.ok) {
          throw new Error('Erro ao buscar dados.');
        }

        const materiasData = await materiasResponse.json();
        const provasData = await provasResponse.json();
        const avisosData = await avisosResponse.json();

        if (materiasData.success && provasData.success) {
          const materiasComProvas = materiasData.materias.map((materia) => ({
            ...materia,
            provas: provasData.provas.filter(
              (prova) => prova.materia_id === materia.id && prova.total_questoes > 0
            ),
          }));
          setMaterias(materiasComProvas.filter(materia => materia.provas.length > 0));
        } else {
          setMaterias([]);
        }

        if (avisosData.success) {
          setAvisos(avisosData.avisos || []);
        } else {
          setAvisos([]);
        }
      } catch (error) {
        setError('Erro ao carregar dados. Por favor, tente novamente mais tarde.');
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchDados();
  }, []);

  const filteredMaterias = materias.filter((materia) =>
    materia.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedMaterias = filteredMaterias.slice(
    (materiasPage - 1) * itemsPerPage,
    materiasPage * itemsPerPage
  );

  const paginatedAvisos = avisos.slice(
    (avisosPage - 1) * itemsPerPage,
    avisosPage * itemsPerPage
  );

  // if (!auth.user || loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '100vh',
  //         flexDirection: 'column',
  //         gap: 2,
  //         bgcolor: 'background.default',
  //       }}
  //     >
  //       <CircularProgress size={60} />
  //       <Typography variant="h6" color="text.secondary">
  //         Carregando...
  //       </Typography>
  //     </Box>
  //   );
  // }
  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography color="error">Erro ao carregar dados.</Typography>
      </Box>
    );
  }
  
  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Box sx={{ mb: 6 }}>
        <Box
  sx={{
    mb: 0,
    textAlign: 'center',
    py: 4,
    bgcolor: 'grey.100',
    borderRadius: 2,
    px: 2,
  }}
>
  {/* Cabeçalho Bem-vindo */}
  <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 2 }}>
    <SchoolIcon
      sx={{
        fontSize: 32,
        color: theme.palette.primary.main,
        mr: 1,
      }}
    />
    <Typography
      variant="h5"
      sx={{
        fontWeight: 600,
        color: theme.palette.primary.main,
      }}
    >
      Bem-vindo, {userName}!
    </Typography>
  </Box>

  <Typography
    variant="body1"
    sx={{
      color: 'text.secondary',
      mb: 1,
    }}
  >
    Hoje é {horaAtual.toLocaleDateString('pt-BR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}.
  </Typography>
  <Typography
    variant="body1"
    sx={{
      color: 'text.secondary',
      mb: 3,
    }}
  >
    Agora são {horaAtual.toLocaleTimeString('pt-BR')}.
  </Typography>

  {/* Detalhes do Relatório */}
  {relatorios && (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
        gap: 2,
        mt: 4,
        textAlign: 'center',
      }}
    >
      {/* Última prova */}
      <Box>
        <QuestionAnswerIcon sx={{ fontSize: 24, color: 'grey.600' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            fontWeight: 'bold',
            mt: 1,
          }}
        >
          Última prova feita
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {relatorios.ultima_prova_feita
            ? `${relatorios.ultima_prova_feita} em ${new Date(
                relatorios.data_ultima_prova
              ).toLocaleString('pt-BR')}`
            : 'Não disponível'}
        </Typography>
      </Box>

      {/* Total de provas */}
      <Box>
        <AssignmentTurnedInIcon sx={{ fontSize: 24, color: 'grey.600' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            fontWeight: 'bold',
            mt: 1,
          }}
        >
          Total de provas realizadas
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {relatorios.total_provas_realizadas || 0}
        </Typography>
      </Box>

      {/* Aproveitamento médio */}
      <Box>
        <EqualizerIcon sx={{ fontSize: 24, color: 'grey.600' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            fontWeight: 'bold',
            mt: 1,
          }}
        >
          Aproveitamento médio
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {relatorios.aproveitamento_medio
            ? `${parseFloat(relatorios.aproveitamento_medio).toFixed(2)}%`
            : 'Não disponível'}
        </Typography>
      </Box>

      {/* Tempo médio por prova */}
      <Box>
        <AccessTimeIcon sx={{ fontSize: 24, color: 'grey.600' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            fontWeight: 'bold',
            mt: 1,
          }}
        >
          Tempo médio em provas
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {relatorios.tempo_medio_prova
            ? relatorios.tempo_medio_prova >= 60
              ? `${(relatorios.tempo_medio_prova / 60).toFixed(2)} minutos`
              : `${parseFloat(relatorios.tempo_medio_prova).toFixed(2)} segundos`
            : 'Não disponível'}
        </Typography>
      </Box>
    </Box>
  )}
</Box>

{/* Indicador */}


<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 4, // Espaço acima do indicador
    mb: 4, // Espaço abaixo do indicador
  }}
>
  {isSmallScreen && (
   <>
   <Typography
     variant="body2"
     sx={{
       color: 'grey.500',
       mb: 1,
       textAlign: 'center',
       animation: 'bounce 2s infinite',
       '@keyframes bounce': {
         '0%, 100%': { transform: 'translateY(0)' },
         '50%': { transform: 'translateY(8px)' },
       },
     }}
   >
     Desça para ver avisos e matérias disponíveis
   </Typography>
   <ExpandMoreIcon
     sx={{
       fontSize: 40,
       color: 'grey.500',
       animation: 'bounce 2s infinite',
     }}
   />
 </>
)}
</Box>

{mostrarInstrucoes && (
  <Instrucoes onFinalizar={fecharInstrucoes} auth={auth} />

)}


          <Grid container spacing={4}>
            {/* <Grid item xs={12} md={6}>
              <Fade in timeout={500}>
                <Card
                  elevation={2}
                  sx={{
                    height: '100%',
                    transition: '0.3s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <IconButton
                        sx={{
                          bgcolor: 'primary.light',
                          color: 'white',
                          mr: 2,
                          '&:hover': { bgcolor: 'primary.main' },
                        }}
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                      <Typography variant="h5">Instruções do Sistema</Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <List>
                      {[
                        'Acesse o menu no canto superior e selecione "Choices".',
                        'Selecione a matéria desejada e depois a prova desejada.',
                        'A prova possui limite de tempo e será finalizada automaticamente.',
                      ].map((text, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 2,
                            mb: 1,
                          }}
                        >
                          <Chip
                            label={index + 1}
                            size="small"
                            color="primary"
                            sx={{ mt: 0.5 }}
                          />
                          <Typography variant="body1">{text}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Fade>
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Fade in timeout={700}>
                <Card elevation={2}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <IconButton
                        sx={{
                          bgcolor: 'secondary.light',
                          color: 'white',
                          mr: 2,
                          '&:hover': { bgcolor: 'secondary.main' },
                        }}
                      >
                        <NotificationsIcon />
                      </IconButton>
                      <Typography variant="h5">Avisos</Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    {paginatedAvisos.length > 0 ? (
                      <List>
                        {paginatedAvisos.map((aviso, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              bgcolor: 'grey.50',
                              borderRadius: 2,
                              mb: 1,
                              p: 2,
                              '&:hover': {
                                bgcolor: 'grey.100',
                              },
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: 'text.primary', mb: 0.5 }}
                              >
                                {aviso.titulo || 'Aviso Importante'}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ color: 'text.secondary', mb: 1 }}
                              >
                                {new Date(aviso.data || new Date()).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {aviso.mensagem}
                              </Typography>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Alert
                        severity="info"
                        sx={{
                          borderRadius: 2,
                          '& .MuiAlert-icon': { alignItems: 'center' },
                        }}
                      >
                        Nenhum aviso disponível no momento.
                      </Alert>
                    )}
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        variant="outlined"
                        disabled={avisosPage === 1}
                        onClick={() => setAvisosPage((prev) => prev - 1)}
                        startIcon={<ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />}
                      >
                        Anterior
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={avisosPage * itemsPerPage >= avisos.length}
                        onClick={() => setAvisosPage((prev) => prev + 1)}
                        endIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
                      >
                        Próximo
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>


            <Grid item xs={12} md={6}>
              <Fade in timeout={900}>
                <Card elevation={2}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <IconButton
                        sx={{
                          bgcolor: 'primary.light',
                          color: 'white',
                          mr: 2,
                          '&:hover': { bgcolor: 'primary.main' },
                        }}
                      >
                        <SchoolIcon />
                      </IconButton>
                      <Typography variant="h5">Matérias Disponíveis</Typography>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

                    <TextField
                      fullWidth
                      placeholder="Buscar Matéria"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                        ),
                      }}
                      sx={{
                        mb: 3,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                        },
                      }}
                    />

                    {paginatedMaterias.length > 0 ? (
                      paginatedMaterias.map((materia) => (
                        <Accordion
                          key={materia.id}
                          sx={{
                            mb: 2,
                            borderRadius: 2,
                            '&:before': { display: 'none' },
                            boxShadow: theme.shadows[2],
                            bgcolor: 'background.paper',
                            '&:hover': {
                              transform: 'translateY(-3px)',
                              boxShadow: theme.shadows[4],
                            },
                            transition: '0.3s',
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              borderRadius: 2,
                              '&.Mui-expanded': {
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                              transition: '0.3s',
                              bgcolor: 'grey.100',
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  color: 'primary.main',
                                }}
                              >
                                {materia.nome}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: 'text.secondary',
                                  mt: 0.5,
                                }}
                              >
                                {materia.provas.length} {materia.provas.length === 1 ? 'prova disponível' : 'provas disponíveis'}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'text.secondary',
                                mb: 2,
                                fontStyle: 'italic',
                                textAlign: 'center', // Centraliza a mensagem
                              }}
                            >
                              Clique em uma prova para iniciar imediatamente.
                            </Typography>
                            <Box
                              sx={{
                                overflowX: 'auto', // Rolagem horizontal em telas pequenas
                              }}
                            >
                              <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ fontWeight: 'bold' }}>Título</TableCell>
                                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Questões
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Tempo
                                      </TableCell>
                                      <TableCell
                                        sx={{ fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap' }}
                                      >
                                        Ação
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {materia.provas.map((prova) => (
                                      <TableRow
                                        key={prova.id}
                                        sx={{
                                          '&:hover': {
                                            backgroundColor: 'grey.100',
                                            cursor: 'pointer',
                                          },
                                        }}
                                        onClick={() => navigate(`/choices?prova_id=${prova.id}`)}
                                      >
                                        <TableCell>{prova.titulo}</TableCell>
                                        <TableCell align="center">{prova.total_questoes}</TableCell>
                                        <TableCell align="center">
                                          {prova.tempo
                                            ? `${Math.floor(prova.tempo)} min`
                                            : 'Sem tempo definido'}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            sx={{
                                              textTransform: 'none',
                                              padding: { xs: '4px 8px', sm: '6px 12px' },
                                              minWidth: { xs: 'auto', sm: '64px' }, // Botão menor em telas pequenas
                                              fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                            }}
                                            startIcon={!isSmallScreen ? <ExpandMoreIcon /> : null} // Ícone apenas em telas grandes
                                          >
                                            {!isSmallScreen && 'Abrir'} {/* Texto apenas em telas grandes */}
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </AccordionDetails>


                        </Accordion>
                      ))
                    ) : (
                      <Alert
                        severity="info"
                        sx={{
                          borderRadius: 2,
                          '& .MuiAlert-icon': { alignItems: 'center' },
                        }}
                      >
                        Nenhuma matéria disponível no momento.
                      </Alert>
                    )}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        variant="contained"
                        disabled={materiasPage === 1}
                        onClick={() => setMateriasPage((prev) => prev - 1)}
                        startIcon={<ExpandMoreIcon sx={{ transform: 'rotate(90deg)' }} />}
                      >
                        Anterior
                      </Button>
                      <Button
                        variant="contained"
                        disabled={materiasPage * itemsPerPage >= filteredMaterias.length}
                        onClick={() => setMateriasPage((prev) => prev + 1)}
                        endIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
                      >
                        Próximo
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSnackbar(false)}
            severity="error"
            sx={{
              width: '100%',
              borderRadius: 2,
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </Box>

    
  );
};

export default Home;