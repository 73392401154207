import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Alert,
  Card,
  CardContent,
  LinearProgress,
  Container,
  useTheme,
  Fade,
  Stepper,
  Step,
  StepLabel,
  Paper,
  IconButton,
  Stack,
  Divider,
  Chip,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  School as SchoolIcon,
  Notifications as NotificationsIcon,
  HelpOutline as HelpOutlineIcon,
  QuestionAnswer as QuestionAnswerIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon, // Para total de provas realizadas
  Equalizer as EqualizerIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import TimerIcon from '@mui/icons-material/Timer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { alpha } from '@mui/material/styles';
import { debounce } from 'lodash';

// Registrar componentes do Chart.js
ChartJS.register(ArcElement, ChartTooltip, Legend);

// URL base da API
const API_BASE_URL = 'https://medchoices.com.br:3001';
const ADMIN_BASE_URL = `${API_BASE_URL}/admin`;

// Função utilitária para embaralhar arrays usando o algoritmo Fisher-Yates
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const Choices = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const initialProvaId = searchParams.get('prova_id');
  const [materias, setMaterias] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [provas, setProvas] = useState([]);
  const [selectedProva, setSelectedProva] = useState(null);
  const [questoes, setQuestoes] = useState([]);
  const [loadingMaterias, setLoadingMaterias] = useState(false);
  const [loadingProvas, setLoadingProvas] = useState(false);
  const [answers, setAnswers] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [userHistorico, setUserHistorico] = useState([]);
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const [provaIniciadaId, setProvaIniciadaId] = useState(null); // ID da prova iniciada

  const steps = ['Selecionar Matéria', 'Selecionar Prova', 'Responder Questões'];

  // Debounced fetch para buscar matérias
  const fetchMateriasDebounced = useMemo(
    () =>
      debounce(async (inputValue) => {
        setLoadingMaterias(true);
        try {
          const response = await fetch(
            `${ADMIN_BASE_URL}/materias?search=${encodeURIComponent(inputValue)}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          const data = await response.json();
          if (data.success) {
            setMaterias(data.materias);
          } else {
            console.error(data.message || 'Erro ao buscar matérias.');
            setMaterias([]);
          }
        } catch (error) {
          console.error('Erro ao buscar matérias:', error);
          setMaterias([]);
        } finally {
          setLoadingMaterias(false);
        }
      }, 300),
    []
  );

  const handleMateriasInputChange = (event, value) => {
    fetchMateriasDebounced(value);
  };

  // Função para buscar provas existentes com questões para uma matéria específica
  const fetchProvasExistentes = async (materiaId) => {
    if (!materiaId) {
      setProvas([]);
      return;
    }

    setLoadingProvas(true);
    try {
      const response = await fetch(
        `${ADMIN_BASE_URL}/provas/com-questoes?materia_id=${materiaId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        const pontuacaoMap = getMaxPontuacaoPerProva();
        const provasProcessadas = data.provas.map((prova) => ({
          ...prova,
          jaRealizada: pontuacaoMap[prova.id] ? true : false,
          maiorPontuacao: pontuacaoMap[prova.id] || null,
        }));

        setProvas(provasProcessadas);
      } else {
        console.error(data.message || 'Erro ao buscar provas existentes.');
        setProvas([]);
      }
    } catch (error) {
      console.error('Erro ao buscar provas existentes:', error);
      setProvas([]);
    } finally {
      setLoadingProvas(false);
    }
  };

  // Função para buscar histórico do usuário
  const fetchUserHistorico = async () => {
    try {
      const response = await fetch(`${ADMIN_BASE_URL}/me/historico`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao buscar histórico do usuário:', errorText);
        return;
      }

      const data = await response.json();
      if (data.success) {
        setUserHistorico(data.historico);
      } else {
        console.error(data.message || 'Erro ao buscar histórico do usuário.');
      }
    } catch (error) {
      console.error('Erro ao buscar histórico do usuário:', error);
    }
  };

  useEffect(() => {
    fetchUserHistorico();
  }, []);

  // Função para obter a maior pontuação por prova a partir do histórico do usuário
  const getMaxPontuacaoPerProva = () => {
    const pontuacaoMap = {};
    userHistorico.forEach((item) => {
      const provaId = item.prova_id;
      const pontuacao = parseFloat(item.pontuacao);
      if (!pontuacaoMap[provaId] || pontuacao > pontuacaoMap[provaId]) {
        pontuacaoMap[provaId] = pontuacao;
      }
    });
    return pontuacaoMap;
  };

  // Efeito para buscar uma prova específica se `prova_id` estiver na URL
  useEffect(() => {
    const fetchProva = async () => {
      if (initialProvaId) {
        try {
          const response = await fetch(
            `${ADMIN_BASE_URL}/provas/${initialProvaId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );

          const data = await response.json();

          if (data.success && data.prova) {
            setSelectedMateria({ id: data.prova.materia_id, nome: data.prova.materia_nome });
            setSelectedProva(data.prova.id);
            setActiveStep(2);
            fetchProvasExistentes(data.prova.materia_id);
          } else {
            console.error('Prova não encontrada.');
          }
        } catch (error) {
          console.error('Erro ao buscar Prova:', error);
        }
      }
    };

    fetchProva();
  }, [initialProvaId]);

  // Função para registrar início da prova
  const iniciarProva = async (provaId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/iniciar-prova`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ prova_id: provaId }),
      });

      const data = await response.json();
      if (data.success) {
        setProvaIniciadaId(data.prova_iniciada_id);
        console.log('Prova iniciada com ID:', data.prova_iniciada_id);
      } else {
        console.error('Erro ao iniciar prova:', data.message);
      }
    } catch (error) {
      console.error('Erro ao iniciar prova:', error);
    }
  };

  // Função para finalizar prova
  const finalizarProva = async () => {
    if (!provaIniciadaId) {
      console.warn('Nenhuma prova iniciada para finalizar.');
      return;
    }

    console.log('Chamando rota /finalizar-prova com prova_iniciada_id:', provaIniciadaId);

    try {
      const response = await fetch(`${ADMIN_BASE_URL}/finalizar-prova`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ prova_iniciada_id: provaIniciadaId }),
      });

      console.log('Resposta da rota /finalizar-prova:', response);

      const data = await response.json();
      console.log('Dados retornados pela API:', data);

      if (data.success) {
        console.log('Prova finalizada com sucesso:', data.message);
      } else {
        console.error('Erro ao finalizar prova:', data.message);
      }
    } catch (error) {
      console.error('Erro ao finalizar prova:', error);
    }
  };

  // Função para processar e embaralhar questões e alternativas
  const processarQuestoes = (questoesOriginais) => {
    // Transformar alternativas em um array e identificar a correta
    const questoesComAlternativas = questoesOriginais.map((questao) => {
      const alternativas = ['a', 'b', 'c', 'd'].map((letra) => ({
        letra,
        texto: questao[`opcao_${letra}`],
        correta: questao.resposta_correta === letra,
      }));

      // Embaralhar as alternativas
      const alternativasEmbaralhadas = shuffleArray(alternativas);

      return {
        ...questao,
        alternativas: alternativasEmbaralhadas,
      };
    });

    // Embaralhar a ordem das questões
    return shuffleArray(questoesComAlternativas);
  };

  // Efeito para buscar e processar questões quando uma prova é selecionada
  useEffect(() => {
    const fetchQuestoes = async () => {
      if (!selectedProva) return;
      setLoadingProvas(true);
      try {
        const responseProva = await fetch(
          `${ADMIN_BASE_URL}/provas/${selectedProva}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const dataProva = await responseProva.json();

        if (dataProva.success) {
          // Registrar início da prova
          await iniciarProva(selectedProva);

          setStartTime(Date.now());
          if (dataProva.prova.tempo) {
            const tempoEmSegundos = dataProva.prova.tempo * 60;
            setTotalTime(tempoEmSegundos);
            setTimeRemaining(tempoEmSegundos);
          }

          // Processar e embaralhar as questões
          const questoesProcessadas = processarQuestoes(dataProva.prova.questoes || []);
          setQuestoes(questoesProcessadas);
        }
      } catch (error) {
        console.error('Erro ao buscar questões:', error);
        setQuestoes([]);
      } finally {
        setLoadingProvas(false);
      }
    };
    fetchQuestoes();
  }, [selectedProva]);

  // Efeito para iniciar o timer
  useEffect(() => {
    if (!startTime || totalTime <= 0) return;

    timerRef.current = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const remaining = Math.max(0, totalTime - elapsed);
      setTimeRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timerRef.current);
        handleSubmit();
      }
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [startTime, totalTime]);

  // Efeito para atualizar o progresso das respostas
  useEffect(() => {
    if (questoes.length > 0) {
      const answeredCount = Object.keys(answers).length;
      setProgress((answeredCount / questoes.length) * 100);
    }
  }, [answers, questoes.length]);

  // Função para lidar com a mudança de resposta
  const handleAnswerChange = (questaoId, resposta) => {
    setAnswers({
      ...answers,
      [questaoId]: resposta,
    });
  };

  // Função para formatar o tempo restante
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Função para lidar com o envio das respostas
  const handleSubmit = async () => {
    if (showResults) return;

    setSubmitting(true);
    setTimeRemaining(0);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    // Calcular acertos
    const correctAnswers = questoes.filter((questao) => {
      const respostaUsuario = answers[questao.id];
      const alternativaCorreta = questao.alternativas.find((alt) => alt.correta);
      return respostaUsuario === alternativaCorreta.letra;
    });

    const resultData = {
      total: questoes.length,
      correct: correctAnswers.length,
      incorrect: questoes.length - correctAnswers.length,
      percentage: ((correctAnswers.length / questoes.length) * 100).toFixed(2),
      timeTaken: Math.floor((Date.now() - startTime) / 1000),
    };

    setResults(resultData);
    setShowResults(true);
    setSubmitting(false);

    // Finalizar prova antes de salvar histórico
    await finalizarProva();

    // Preparar dados para salvar no histórico
    const historicoData = {
      prova_id: selectedProva,
      acertos: correctAnswers.length,
      erros: questoes.length - correctAnswers.length,
      tempo_utilizado: Math.floor((Date.now() - startTime) / 1000),
      pontuacao: ((correctAnswers.length / questoes.length) * 100).toFixed(2),
      total_questoes: questoes.length,
      respostas: questoes.map((questao) => {
        const respostaUsuario = answers[questao.id];
        const alternativaEscolhida = questao.alternativas.find((alt) => alt.letra === respostaUsuario);
        const alternativaCorreta = questao.alternativas.find((alt) => alt.correta);
        return {
          questao_id: questao.id,
          alternativa_escolhida: respostaUsuario || null,
          correta: alternativaEscolhida?.correta || false,
        };
      }),
    };

    // Enviar dados para salvar no histórico
    try {
      const response = await fetch(`${ADMIN_BASE_URL}/me/historico`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(historicoData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Erro ao salvar histórico:', errorText);
        return;
      }

      const data = await response.json();
      if (!data.success) {
        console.error('Erro ao salvar histórico:', data.message);
      } else {
        console.log('Histórico salvo com sucesso:', data);
      }
    } catch (error) {
      console.error('Erro ao salvar histórico:', error);
    }
  };

  // Dados para o gráfico de resultados
  const chartData = {
    labels: ['Acertos', 'Erros'],
    datasets: [
      {
        data: [results?.correct || 0, results?.incorrect || 0],
        backgroundColor: [theme.palette.success.main, theme.palette.error.main],
        borderColor: [theme.palette.success.light, theme.palette.error.light],
        borderWidth: 1,
      },
    ],
  };

  // Função para sair da prova e navegar para a página inicial
  const handleExit = () => {
    navigate('/inicio');
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderRadius: 3,
          bgcolor: alpha(theme.palette.background.paper, 0.98),
          backdropFilter: 'blur(8px)',
        }}
      >
        {/* Se nenhuma prova está selecionada, exibir seleção de matéria e prova */}
        {!selectedProva ? (
          <>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mb: 4,
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              Seleção de Prova
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                mb: 6,
                '& .MuiStepLabel-root .Mui-completed': {
                  color: theme.palette.success.main,
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ maxWidth: 600, mx: 'auto' }}>
              {/* Autocomplete para seleção de matéria */}
              <Autocomplete
                options={materias}
                getOptionLabel={(option) => option.nome}
                value={selectedMateria}
                onChange={(event, newValue) => {
                  setSelectedMateria(newValue);
                  setSelectedProva(null);
                  setActiveStep(newValue ? 1 : 0);
                  if (newValue) {
                    fetchProvasExistentes(newValue.id);
                  }
                }}
                onInputChange={handleMateriasInputChange}
                loading={loadingMaterias}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Matéria"
                    variant="outlined"
                    fullWidth
                    required
                    helperText="Digite para buscar e selecionar a matéria desejada."
                    placeholder="Ex: Anatomia, Fisiologia..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingMaterias ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                noOptionsText="Digite para buscar a matéria desejada."
                sx={{ mb: 4 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />

              {/* Autocomplete para seleção de prova */}
              <Autocomplete
                options={provas}
                getOptionLabel={(option) => option.titulo}
                value={selectedProva ? provas.find((prova) => prova.id === selectedProva) : null}
                onChange={(event, newValue) => {
                  setSelectedProva(newValue ? newValue.id : null);
                  setActiveStep(newValue ? 2 : 1);
                }}
                loading={loadingProvas}
                disabled={!selectedMateria}
                renderOption={(props, option) => {
                  const isLowAproveitamento = option.maiorPontuacao < 40;
                  return (
                    <Box
                      component="li"
                      {...props}
                      sx={{
                        bgcolor: option.jaRealizada
                          ? alpha(theme.palette.success.main, 0.05)
                          : 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 1,
                        borderRadius: 2,
                        mb: 1,
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, textAlign: 'center' }}>
                        <Typography variant="body1">
                          {option.titulo}
                        </Typography>
                        {option.jaRealizada && (
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                            <Chip
                              label={`Último Aproveitamento: ${option.maiorPontuacao}%`}
                              color={isLowAproveitamento ? 'error' : 'success'}
                              size="small"
                              sx={{
                                bgcolor: isLowAproveitamento
                                  ? alpha(theme.palette.error.main, 0.1)
                                  : alpha(theme.palette.success.main, 0.1),
                                color: isLowAproveitamento
                                  ? theme.palette.error.main
                                  : theme.palette.success.main,
                              }}
                            />
                            <Typography variant="caption" color="text.secondary">
                              Prova já realizada
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Prova"
                    variant="outlined"
                    fullWidth
                    required
                    helperText={
                      selectedMateria
                        ? provas.length > 0
                          ? 'Selecione a prova referente à matéria escolhida.'
                          : 'Nenhuma prova disponível para a matéria selecionada.'
                        : 'Selecione uma matéria primeiro.'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProvas ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                noOptionsText={
                  selectedMateria
                    ? provas.length > 0
                      ? 'Nenhuma prova corresponde à busca.'
                      : 'Nenhuma prova disponível para a matéria selecionada.'
                    : 'Selecione uma matéria primeiro.'
                }
                sx={{ mb: 4 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
          </>
        ) : loadingProvas ? (
          // Exibir loading enquanto as questões estão sendo carregadas
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60vh',
            }}
          >
            <CircularProgress size={60} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              As questões estão sendo carregadas, boa sorte!
            </Typography>
          </Box>
        ) : (
          // Renderizar as questões e alternativas
          <Box>
            {questoes.map((questao, index) => (
              <Box
                key={questao.id}
                sx={{
                  mb: 4,
                  p: 0,
                  borderBottom: '1px solid',
                  borderColor: 'lightgrey',
                  bgcolor: showResults
                    ? questao.alternativas.find((alt) => alt.letra === answers[questao.id])?.correta
                      ? alpha(theme.palette.success.main, 0.05)
                      : alpha(theme.palette.error.main, 0.05)
                    : theme.palette.background.paper,
                }}
              >
                {/* Cabeçalho da questão com progresso e timer */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={((index + 1) / questoes.length) * 100}
                    sx={{
                      width: '65%',
                      height: 8,
                      borderRadius: 4,
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                      },
                    }}
                  />
                  <Chip
                    icon={<TimerIcon />}
                    label={formatTime(timeRemaining)}
                    color={timeRemaining < 60 ? 'error' : 'primary'}
                    variant="outlined"
                    sx={{
                      fontWeight: 600,
                      fontSize: '0.9rem',
                    }}
                  />
                </Box>

                {/* Enunciado da questão */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                    mb: 2,
                    fontSize: '1rem',
                  }}
                >
                  Questão {index + 1} de {questoes.length}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {questao.enunciado}
                </Typography>

                {/* Imagem da questão, se houver */}
                {questao.imagem && (
                  <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <img
                      src={
                        questao.imagem.startsWith('http')
                          ? questao.imagem
                          : `${ADMIN_BASE_URL.replace('/admin', '')}/uploads/${questao.imagem.replace(/^\/+/, '')}`
                      }
                      alt={`Imagem da questão ${index + 1}`}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        borderRadius: 12,
                        boxShadow: theme.shadows[2],
                      }}
                    />
                  </Box>
                )}

                {/* Alternativas embaralhadas */}
                <RadioGroup
                  value={answers[questao.id] || ''}
                  onChange={(e) => handleAnswerChange(questao.id, e.target.value)}
                  disabled={submitting || showResults}
                >
                  {questao.alternativas.map((option) => (
                    <Box
                      key={option.letra}
                      onClick={() => handleAnswerChange(questao.id, option.letra)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 1,
                        mb: 1,
                        borderRadius: 2,
                        cursor: 'pointer',
                        bgcolor: showResults
                          ? option.correta
                            ? alpha(theme.palette.success.main, 0.1)
                            : answers[questao.id] === option.letra
                              ? alpha(theme.palette.error.main, 0.1)
                              : alpha(theme.palette.grey[100], 0.5)
                          : alpha(theme.palette.grey[100], 0.5),
                        border: `1px solid ${showResults
                          ? option.correta
                            ? alpha(theme.palette.success.main, 0.3)
                            : answers[questao.id] === option.letra
                              ? alpha(theme.palette.error.main, 0.3)
                              : 'transparent'
                          : 'transparent'
                          }`,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          boxShadow: theme.shadows[3],
                          bgcolor: alpha(theme.palette.primary.light, 0.2),
                        },
                      }}
                    >
                      <FormControlLabel
                        value={option.letra}
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            sx={{
                              color: showResults
                                ? option.correta
                                  ? theme.palette.success.main
                                  : answers[questao.id] === option.letra
                                    ? theme.palette.error.main
                                    : theme.palette.text.primary
                                : theme.palette.text.primary,
                              fontWeight:
                                showResults && option.correta
                                  ? 600
                                  : 400,
                            }}
                          >
                            {option.texto}
                          </Typography>
                        }
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </Box>
            ))}

            {/* Botões de sair e finalizar prova */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleExit}
                sx={{
                  borderRadius: 2,
                  px: 4,
                }}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={showResults || timeRemaining <= 0 || submitting}
                endIcon={<CheckCircleIcon />}
                sx={{
                  borderRadius: 2,
                  px: 4,
                }}
              >
                Finalizar Prova
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      {/* Modal de Resultados Atualizado */}
      <Dialog
        open={showResults}
        onClose={() => { }}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            borderRadius: 4,
            p: 4,
            boxShadow: 24,
            position: 'relative',
            background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 100%)`,
          },
        }}
      >
        <Fade in={showResults}>
          <Box>
            {/* Título do Modal */}
            <Typography
              variant="h5"
              align="center"
              color="primary"
              sx={{ fontWeight: 700, mb: 3 }}
            >
              Resultados da Prova
            </Typography>

            {/* Informações de Tempo e Aproveitamento */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {/* Tempo */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: alpha(theme.palette.info.light, 0.1),
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <TimerIcon color="info" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Tempo
                    </Typography>
                    <Typography variant="h6">
                      {Math.floor(results?.timeTaken / 60)}min {results?.timeTaken % 60}s
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Aproveitamento */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: alpha(theme.palette.success.light, 0.1),
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <EqualizerIcon color="success" sx={{ mr: 1 }} />
                  <Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Aproveitamento
                    </Typography>
                    <Typography variant="h6">{results?.percentage}%</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Acertos e Erros */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
              {/* Acertos */}
              <Paper
                elevation={3}
                sx={{
                  flex: 1,
                  p: 2,
                  bgcolor: alpha(theme.palette.success.main, 0.05),
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <CheckCircleIcon color="success" />
                <Box>
                  <Typography variant="h6" color="success.main" sx={{ fontWeight: 600 }}>
                    {results?.correct}
                  </Typography>
                  <Typography variant="body2" color="success.main">
                    Acertos
                  </Typography>
                </Box>
              </Paper>

              {/* Erros */}
              <Paper
                elevation={3}
                sx={{
                  flex: 1,
                  p: 2,
                  bgcolor: alpha(theme.palette.error.main, 0.05),
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <ErrorIcon color="error" />
                <Box>
                  <Typography variant="h6" color="error.main" sx={{ fontWeight: 600 }}>
                    {results?.incorrect}
                  </Typography>
                  <Typography variant="body2" color="error.main">
                    Erros
                  </Typography>
                </Box>
              </Paper>
            </Stack>

            {/* Gráfico de Resultados */}
            <Box
              sx={{
                height: { xs: 200, sm: 250 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Pie
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                      labels: {
                        font: {
                          size: 14,
                          weight: '500',
                        },
                        padding: 20,
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const label = context.label || '';
                          const value = context.parsed;
                          const total = context.chart._metasets[context.datasetIndex].total;
                          const percentage = ((value / total) * 100).toFixed(2);
                          return `${label}: ${percentage}%`;
                        },
                      },
                    },
                  },
                }}
              />
            </Box>

            <Divider sx={{ mb: 3 }} />

            {/* Ações do Modal */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
              <Button
                variant="outlined"
                onClick={() => navigate('/home')}
                startIcon={<ArrowBackIcon />}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  width: { xs: '100%', sm: 'auto' },
                  textTransform: 'none',
                }}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowReview(true)}
                endIcon={<VisibilityIcon />}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  width: { xs: '100%', sm: 'auto' },
                  textTransform: 'none',
                }}
              >
                Revisar Questões
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Dialog>

      {/* Modal de Revisão */}
      <Modal
        open={showReview}
        onClose={() => setShowReview(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={showReview}>
          <Box
            sx={{
              width: { xs: '95%', sm: '80%', md: '70%' },
              maxHeight: '90vh',
              bgcolor: 'background.paper',
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
              position: 'relative',
              overflow: 'auto',
            }}
          >
            <IconButton
              onClick={() => setShowReview(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <Stack spacing={4}>
              <Typography
                variant="h5"
                align="center"
                color="primary"
                sx={{ fontWeight: 600, mb: 2 }}
              >
                Revisão das Questões
              </Typography>

              {questoes.map((questao, index) => {
                const alternativaCorreta = questao.alternativas.find((alt) => alt.correta);
                const respostaUsuario = answers[questao.id];
                const alternativaEscolhida = questao.alternativas.find((alt) => alt.letra === respostaUsuario);

                return (
                  <Card
                    key={questao.id}
                    sx={{
                      borderRadius: 2,
                      bgcolor: respostaUsuario === alternativaCorreta.letra
                        ? alpha(theme.palette.success.main, 0.05)
                        : alpha(theme.palette.error.main, 0.05),
                      border: `1px solid ${
                        respostaUsuario === alternativaCorreta.letra
                          ? alpha(theme.palette.success.main, 0.2)
                          : alpha(theme.palette.error.main, 0.2)
                      }`,
                    }}
                  >
                    <CardContent>
                      <Stack spacing={3}>
                        {/* Cabeçalho da revisão da questão */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6" fontWeight={'700'}>
                            Questão {index + 1}
                          </Typography>
                          <Chip
                            icon={respostaUsuario === alternativaCorreta.letra ? <CheckCircleIcon /> : <ErrorIcon />}
                            label={respostaUsuario === alternativaCorreta.letra ? 'Correta' : 'Incorreta'}
                            color={respostaUsuario === alternativaCorreta.letra ? 'success' : 'error'}
                            sx={{ fontWeight: 600 }}
                          />
                        </Box>

                        {/* Enunciado da questão */}
                        <Typography sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
                          {questao.enunciado}
                        </Typography>

                        {/* Imagem da questão, se houver */}
                        {questao.imagem && (
                          <Box sx={{ textAlign: 'center' }}>
                            <img
                              src={
                                questao.imagem.startsWith('http')
                                  ? questao.imagem
                                  : `${ADMIN_BASE_URL.replace('/admin', '')}/uploads/${questao.imagem.replace(/^\/+/, '')}`
                              }
                              alt={`Imagem da questão ${index + 1}`}
                              style={{
                                maxWidth: '100%',
                                height: 'auto',
                                borderRadius: 12,
                                boxShadow: theme.shadows[2],
                              }}
                            />
                          </Box>
                        )}

                        {/* Alternativas embaralhadas com indicações de corretas e incorretas */}
                        <Stack spacing={2}>
                          {questao.alternativas.map((option) => {
                            const isCorrect = option.correta;
                            const isUserAnswer = respostaUsuario === option.letra;

                            return (
                              <Paper
                                key={option.letra}
                                elevation={0}
                                sx={{
                                  p: 2,
                                  borderRadius: 2,
                                  bgcolor: isCorrect
                                    ? alpha(theme.palette.success.main, 0.1)
                                    : isUserAnswer
                                      ? alpha(theme.palette.error.main, 0.1)
                                      : alpha(theme.palette.grey[100], 0.5),
                                  border: `1px solid ${
                                    isCorrect
                                      ? alpha(theme.palette.success.main, 0.3)
                                      : isUserAnswer
                                        ? alpha(theme.palette.error.main, 0.3)
                                        : 'transparent'
                                  }`,
                                }}
                              >
                                <Stack direction="row" spacing={2} alignItems="center">
                                  {isCorrect && <CheckCircleIcon color="success" />}
                                  {isUserAnswer && !isCorrect && <ErrorIcon color="error" />}
                                  <Typography
                                    sx={{
                                      color: isCorrect
                                        ? theme.palette.success.main
                                        : isUserAnswer
                                          ? theme.palette.error.main
                                          : theme.palette.text.primary,
                                      fontWeight: isCorrect || isUserAnswer ? 600 : 400,
                                    }}
                                  >
                                    {option.texto}
                                  </Typography>

                                  {isUserAnswer && !isCorrect && (
                                    <Typography
                                      sx={{
                                        color: theme.palette.error.main,
                                        fontStyle: 'italic',
                                        fontWeight: 400,
                                        ml: 2,
                                      }}
                                    >
                                      (Sua escolha)
                                    </Typography>
                                  )}
                                </Stack>
                              </Paper>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}

              {/* Botão para fechar a revisão */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => setShowReview(false)}
                  endIcon={<ArrowBackIcon />}
                  sx={{ borderRadius: 2, px: 3 }}
                >
                  Voltar
                </Button>
              </Box>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default Choices;
