import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
  Avatar,
  Divider,
  Fade,
  useTheme,
  useMediaQuery,
  Stack,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Person,
  Email,
  Lock,
  Save,
} from '@mui/icons-material';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { auth, updateUserName, updateUserSurname, updateUserEmail } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    senha: '',
    confirmarSenha: '',
    faculdade: '',
  });

  const [faculdades, setFaculdades] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState({
    senha: false,
    confirmarSenha: false,
  });
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setFormValues({
          nome: storedUser.nome || '',
          sobrenome: storedUser.sobrenome || '',
          email: storedUser.email || '',
          senha: '',
          confirmarSenha: '',
          faculdade: storedUser.faculdade || '',
        });
      } else if (auth.user) {
        setFormValues({
          nome: auth.user.nome || '',
          sobrenome: auth.user.sobrenome || '',
          email: auth.user.email || '',
          senha: '',
          confirmarSenha: '',
          faculdade: auth.user.faculdade || '',
        });
      }
    };

    fetchUserData();
  }, [auth.user]);

  useEffect(() => {
    const fetchFaculdades = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          'https://medchoices.com.br:3001/admin/faculdades',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        const data = await response.json();

        if (data.success) {
          setFaculdades(data.faculdades || []);

          if (auth.user?.faculdade) {
            const faculdadeAssociada = data.faculdades.find(
              (faculdade) => faculdade.id === auth.user.faculdade
            );

            setFormValues((prev) => ({
              ...prev,
              faculdade: faculdadeAssociada ? faculdadeAssociada.id : '',
            }));
          }
        } else {
          console.error('Erro ao carregar faculdades:', data.message);
        }
      } catch (err) {
        console.error('Erro ao conectar com o servidor de faculdades:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFaculdades();
  }, [auth.user, auth.token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const validatePassword = (password) => {
    if (password === '') return true;
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._-])[A-Za-z\d@$!%*?&._-]{8,}$/;
    return regex.test(password);
  };

  const validate = () => {
    const errors = {};

    if (!formValues.nome.trim()) {
      errors.nome = 'O nome é obrigatório.';
    } else if (formValues.nome.trim().length < 2) {
      errors.nome = 'O nome deve ter pelo menos 2 caracteres.';
    }

    if (!formValues.sobrenome.trim()) {
      errors.sobrenome = 'O sobrenome é obrigatório.';
    } else if (formValues.sobrenome.trim().length < 2) {
      errors.sobrenome = 'O sobrenome deve ter pelo menos 2 caracteres.';
    }

    if (!formValues.email) {
      errors.email = 'O email é obrigatório.';
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'O formato do email é inválido.';
    }

    if (!formValues.faculdade) {
      errors.faculdade = 'A faculdade é obrigatória.';
    }

    if (formValues.senha) {
      if (!validatePassword(formValues.senha)) {
        errors.senha =
          'A senha deve ter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, dígitos e caractere especial.';
      }

      if (formValues.confirmarSenha !== formValues.senha) {
        errors.confirmarSenha = 'As senhas não correspondem.';
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError('');
    setSuccessMessage('');

    if (validate()) {
      setLoading(true);
      try {
        const response = await fetch('https://medchoices.com.br:3001/admin/profile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify({
            nome: formValues.nome,
            sobrenome: formValues.sobrenome,
            email: formValues.email,
            senha: formValues.senha,
            faculdade: formValues.faculdade,
          }),
        });

        const data = await response.json();

        if (data.success) {
          setSuccessMessage('Perfil atualizado com sucesso!');

          const updatedUser = {
            ...auth.user,
            nome: formValues.nome,
            sobrenome: formValues.sobrenome,
            email: formValues.email,
            faculdade: formValues.faculdade,
          };

          updateUserName(formValues.nome);
          updateUserSurname(formValues.sobrenome);
          updateUserEmail(formValues.email);

          localStorage.setItem('user', JSON.stringify(updatedUser));

          setFormValues({
            nome: updatedUser.nome,
            sobrenome: updatedUser.sobrenome,
            email: updatedUser.email,
            senha: '',
            confirmarSenha: '',
            faculdade: updatedUser.faculdade,
          });
        } else {
          setApiError(data.message || 'Erro ao atualizar o perfil.');
        }
      } catch (err) {
        setApiError('Erro ao conectar com o servidor.');
      } finally {
        setLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Toolbar sx={{ minHeight: '64px' }} />
      <Box sx={{ mt: -2 }}>
        <Paper
          elevation={3}
          sx={{
            p: { xs: 3, sm: 4 },
            borderRadius: 2,
            background: theme.palette.background.paper,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <Avatar
              sx={{
                width: 80,
                height: 80,
                bgcolor: theme.palette.primary.main,
                mb: 2,
              }}
            >
              {formValues.nome[0]?.toUpperCase() || 'U'}
            </Avatar>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              Meu Perfil
            </Typography>
          </Box>

          <Fade in={!!apiError || !!successMessage}>
            <Box sx={{ mb: 3 }}>
              {apiError && <Alert severity="error">{apiError}</Alert>}
              {successMessage && <Alert severity="success">{successMessage}</Alert>}
            </Box>
          </Fade>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Stack spacing={3}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                  gap: 2,
                }}
              >
                <TextField
                  label="Nome"
                  variant="outlined"
                  required
                  fullWidth
                  name="nome"
                  value={formValues.nome}
                  onChange={handleChange}
                  error={!!formErrors.nome}
                  helperText={formErrors.nome}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Sobrenome"
                  variant="outlined"
                  required
                  fullWidth
                  name="sobrenome"
                  value={formValues.sobrenome}
                  onChange={handleChange}
                  error={!!formErrors.sobrenome}
                  helperText={formErrors.sobrenome}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <TextField
                label="Email"
                variant="outlined"
                required
                fullWidth
                name="email"
                value={formValues.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="action" />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Faculdade</InputLabel>
                <Select
                  name="faculdade"
                  value={formValues.faculdade || ''}
                  onChange={handleChange}
                  displayEmpty
                >
                  {faculdades.map((faculdade) => (
                    <MenuItem key={faculdade.id} value={faculdade.id}>
                      {faculdade.nome}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.faculdade && (
                  <Typography color="error" variant="body2">
                    {formErrors.faculdade}
                  </Typography>
                )}
              </FormControl>

              <Divider sx={{ my: 2 }}>
                <Typography color="textSecondary" variant="body2">
                  Alterar Senha (opcional)
                </Typography>
              </Divider>

              <TextField
                label="Nova Senha"
                variant="outlined"
                fullWidth
                name="senha"
                type={showPassword.senha ? 'text' : 'password'}
                value={formValues.senha}
                onChange={handleChange}
                error={!!formErrors.senha}
                helperText={formErrors.senha}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            senha: !prev.senha,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.senha ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Confirmar Nova Senha"
                variant="outlined"
                fullWidth
                name="confirmarSenha"
                type={showPassword.confirmarSenha ? 'text' : 'password'}
                value={formValues.confirmarSenha}
                onChange={handleChange}
                error={!!formErrors.confirmarSenha}
                helperText={formErrors.confirmarSenha}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            confirmarSenha: !prev.confirmarSenha,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword.confirmarSenha ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Save />}
                sx={{
                  mt: 3,
                  py: 1.5,
                  fontSize: '0.8rem',
                  position: 'relative',
                }}
              >
                {loading ? 'Atualizando...' : 'Atualizar Perfil'}
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Profile;
