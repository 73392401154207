import React, { useState, useContext } from 'react';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import {
  Menu as MenuIcon,
  inicio as inicioIcon,
  Book as BookIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  ExitToApp as ExitToAppIcon,
  History,
  Dashboard,
  Group,
  School,
  Assignment,
  QuestionAnswer,
  Announcement,
  Person as PersonIcon,
  Home as HomeIcon,
  ExpandLess,
  ExpandMore,
  AccountBalance, 
} from '@mui/icons-material';

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { alpha } from '@mui/material/styles';
import LogoMedChoices from '../logomedchoices.svg';


const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, logout } = useContext(AuthContext); // Usando o contexto de autenticação
  const [mobileOpen, setMobileOpen] = useState(false);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleAdminMenuOpen = (event) => setAdminMenuAnchor(event.currentTarget);
  const handleAdminMenuClose = () => setAdminMenuAnchor(null);
  const handleAdminDropdownToggle = () => setAdminDropdownOpen(!adminDropdownOpen);

  const handleLogout = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Se por algum motivo não tiver token, apenas faz logout local
      logout();
      navigate('/login');
      return;
    }

    fetch('https://medchoices.com.br:3001/logout', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('Resposta do logout no servidor:', data);
        // Independente do resultado, efetua logout local
        logout();
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch(err => {
        console.error('Erro ao fazer logout no servidor:', err);
        // Se der erro no servidor, ainda assim efetua logout local
        logout();
        localStorage.removeItem('token');
        navigate('/login');
      });
  };

  const menuItems = [
    { text: 'Início', path: '/inicio', icon: <HomeIcon /> },
    { text: 'Choices', path: '/choices', icon: <BookIcon /> },
    { text: 'Meu Histórico', path: '/historico', icon: <History /> },
    { text: 'Perfil', path: '/profile', icon: <PersonIcon /> },
  ];

  const adminMenuItems = [
    { text: 'Relatorios', path: '/admin', icon: <Dashboard /> },
    { text: 'Painel de Usuários', path: '/admin/users', icon: <Group /> },
    { text: 'Matérias', path: '/admin/materias', icon: <School /> },
    { text: 'Provas', path: '/admin/provas', icon: <Assignment /> },
    { text: 'Questões', path: '/admin/questoes', icon: <QuestionAnswer /> },
    { text: 'Avisos', path: '/admin/avisos', icon: <Announcement /> },
    { text: 'Histórico de Usuários', path: '/admin/historico', icon: <History /> },
    { text: 'Faculdades', path: '/admin/faculdades', icon: <AccountBalance /> }, // Adicione aqui
  ];

  // Função para fechar o Drawer (sidebar)
  const closeDrawer = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const renderNavItems = (items) =>
    items.map((item) => (
      <ListItemButton
        key={item.text}
        onClick={() => {
          closeDrawer(); // Fecha o Drawer
          navigate(item.path, { replace: true }); // Força a navegação
          window.location.reload(); // Recarrega a página
        }}
        selected={location.pathname === item.path}
        sx={{
          borderRadius: 1,
          mx: 1,
          mb: 0.5,
          '&.Mui-selected': {
            bgcolor: theme.palette.action.selected,
          },
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    ));

    const renderAdminMenuItems = (items) =>
      items.map((item) => (
        <ListItemButton
          key={item.text}
          onClick={() => {
            closeDrawer(); // Fecha o Drawer
            navigate(item.path, { replace: true }); // Força a navegação
            window.location.reload(); // Recarrega a página
          }}
          sx={{ pl: 4 }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ));
  const drawerContent = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          bgcolor: theme.palette.background.default,
        }}
      >
        {/* Verifique se auth.user está disponível antes de acessar suas propriedades */}
        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
          {auth.user?.nome?.[0] || 'M'}
        </Avatar>
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
          {auth.user?.nome || 'Medchoices'}
          </Typography>
          {/* Verifique se auth.user está disponível antes de acessar email */}
          <Typography variant="body2" color="text.secondary">
            {auth.user?.email || 'usuario@exemplo.com'}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <List sx={{ flex: 1 }}>
        {renderNavItems(menuItems)}
        {auth.user?.funcao === 'admin' && (
          <>
            <ListItemButton onClick={handleAdminDropdownToggle}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administração" />
              {adminDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={adminDropdownOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderAdminMenuItems(adminMenuItems)}
              </List>
            </Collapse>
          </>
        )}
      </List>
      <Divider />
      <List>
        <ListItemButton
          onClick={() => {
            handleLogout();
            closeDrawer(); // Fecha o Drawer após logout
          }}
          sx={{
            borderRadius: 1,
            mx: 1,
            mb: 1,
            color: theme.palette.error.main,
          }}
        >
          <ListItemIcon sx={{ color: 'inherit' }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </List>
    </Box>
  );

  // Não exibe o Navbar se o usuário não estiver autenticado
  if (!auth.isAuthenticated) return null;

  return (
    <>
      <AppBar
        position="fixed" // Garantir que o AppBar fique fixo no topo
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          width: '100%', // Garantir 100% de largura
          boxShadow: 'none', // Remover sombra se necessário
        }}
      >
        <Toolbar
          sx={{
            px: { xs: 1, sm: 2 }, // Ajustar padding para dispositivos móveis
            minHeight: '64px', // Garantir altura consistente
          }}
        >
<IconButton
  color="inherit"
  aria-label="open drawer"
  edge="start"
  onClick={handleDrawerToggle}
  sx={{ mr: 2, display: { sm: 'none' } }}
>
  <MenuIcon sx={{ fontSize: 36 }} /> {/* Aumentando o tamanho do ícone */}
</IconButton>

          <Box
  sx={{
    flexGrow: 1,
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'flex-start', // Centraliza no mobile
    alignItems: 'center',
  }}
>
  <RouterLink to="/inicio" style={{ textDecoration: 'none', color: 'inherit' }}>
    <img
      src={LogoMedChoices}
      alt="MedChoices Logo"
      style={{
        height: '60px', // Ajuste o tamanho da logo conforme necessário
        objectFit: 'contain',
      }}
    />
  </RouterLink>
</Box>
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  color="inherit"
                  component={RouterLink}
                  to={item.path}
                  startIcon={item.icon}
                  onClick={closeDrawer} // Opcional: fechar Drawer se estiver aberto
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  {item.text}
                </Button>
              ))}
              {auth.user?.funcao === 'admin' && (
                <>
                  <Button
                    color="inherit"
                    startIcon={<AdminPanelSettingsIcon />}
                    onClick={handleAdminMenuOpen}
                    endIcon={Boolean(adminMenuAnchor) ? <ExpandLess /> : <ExpandMore />}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    Administração
                  </Button>
                  <Menu
                    anchorEl={adminMenuAnchor}
                    open={Boolean(adminMenuAnchor)}
                    onClose={handleAdminMenuClose}
                    sx={{
                      '& .MuiPaper-root': {
                        mt: 1,
                        minWidth: 200,
                      },
                    }}
                  >
                    {adminMenuItems.map((item) => (
                      <MenuItem
                        key={item.text}
                        component={RouterLink}
                        to={item.path}
                        onClick={() => {
                          handleAdminMenuClose();
                          closeDrawer(); // Fecha o Drawer após clicar no item
                        }}
                        sx={{
                          gap: 2,
                          minHeight: 48,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              <Button
                color="inherit"
                onClick={() => {
                  handleLogout();
                  closeDrawer(); // Opcional: fechar Drawer se estiver aberto
                }}
                startIcon={<ExitToAppIcon />}
                sx={{
                  textTransform: 'none',
                }}
              >
                Sair
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Melhor performance em dispositivos móveis
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Navbar;
