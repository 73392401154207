// frontend/src/components/RedefinirSenha.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

const RedefinirSenha = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokenValido, setTokenValido] = useState(false);
  const [verificandoToken, setVerificandoToken] = useState(true);

  useEffect(() => {
    // Verificar a validade do token ao montar o componente
    const verificarToken = async () => {
      console.log('Verificando validade do token:', token);
      try {
          const response = await fetch(`https://medchoices.com.br:3001/recuperar-senha/${token}`);
          if (!response.ok) {
              console.warn('Erro na resposta do servidor:', response.status, response.statusText);
              throw new Error('Erro ao verificar token.');
          }
          const data = await response.json();
          console.log('Resposta da verificação do token:', data);
  
          if (data.success) {
              setTokenValido(true);
          } else {
              setErro(data.message || 'Token inválido ou expirado.');
          }
      } catch (err) {
          console.error('Erro ao conectar com o servidor durante a verificação do token:', err);
          setErro('Erro ao conectar com o servidor.');
      } finally {
          setVerificandoToken(false);
      }
  };

    verificarToken();
}, [token]);

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Tentando redefinir senha...');
  console.log('Nova Senha:', novaSenha);
  console.log('Confirmar Senha:', confirmarSenha);

  setErro('');
  setMensagem('');

  if (!novaSenha || !confirmarSenha) {
      setErro('Por favor, preencha todos os campos.');
      console.warn('Erro: Campos vazios.');
      return;
  }

  if (novaSenha !== confirmarSenha) {
      setErro('As senhas não coincidem.');
      console.warn('Erro: As senhas não coincidem.');
      return;
  }

  if (novaSenha.length < 6) {
      setErro('A senha deve ter pelo menos 6 caracteres.');
      console.warn('Erro: Senha curta demais.');
      return;
  }

  setLoading(true);
  try {
      const response = await fetch('https://medchoices.com.br:3001/redefinir-senha', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token, novaSenha }),
      });
      const data = await response.json();
      console.log('Resposta da API /redefinir-senha:', data);

      if (data.success) {
          setMensagem('Senha atualizada com sucesso. Você será redirecionado para o login em breve.');
          console.log('Senha redefinida com sucesso.');
          setTimeout(() => {
              navigate('/login');
          }, 3000);
      } else {
          setErro(data.message || 'Erro ao redefinir a senha.');
      }
  } catch (err) {
      console.error('Erro ao conectar com o servidor:', err);
      setErro('Erro ao conectar com o servidor.');
  } finally {
      setLoading(false);
  }
};

  const handleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  if (verificandoToken) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
          py: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (erro && !tokenValido) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
          py: 4,
        }}
      >
        <Box maxWidth="sm" width="100%">
          <Paper
            elevation={6}
            sx={{
              p: { xs: 3, sm: 6 },
              borderRadius: 2,
            }}
          >
            <Alert severity="error">{erro}</Alert>
          </Paper>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
        py: 4,
      }}
    >
      <Box maxWidth="sm" width="100%">
        <Paper
          elevation={6}
          sx={{
            p: { xs: 3, sm: 6 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>
              Redefinir Senha
            </Typography>
          </Box>

          {erro && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {erro}
            </Alert>
          )}

          {mensagem && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {mensagem}
            </Alert>
          )}

          {tokenValido && (
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                name="novaSenha"
                label="Nova Senha"
                type={mostrarSenha ? 'text' : 'password'}
                id="novaSenha"
                value={novaSenha}
                onChange={(e) => setNovaSenha(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Mostrar/Esconder senha"
                        onClick={handleMostrarSenha}
                        edge="end"
                      >
                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmarSenha"
                label="Confirmar Senha"
                type={mostrarSenha ? 'text' : 'password'}
                id="confirmarSenha"
                value={confirmarSenha}
                onChange={(e) => setConfirmarSenha(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Mostrar/Esconder senha"
                        onClick={handleMostrarSenha}
                        edge="end"
                      >
                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
                sx={{
                  py: 1.5,
                  mb: 3,
                  mt: 2,
                }}
              >
                {loading ? 'Redefinindo...' : 'Redefinir Senha'}
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default RedefinirSenha;
