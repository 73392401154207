import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  Button,
  Paper,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  ThemeProvider,
  createTheme,
  CssBaseline,
  IconButton,
  Tooltip,
  Fade,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  SaveAlt as SaveAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Refresh as RefreshIcon,
  TrendingUp as TrendingUpIcon,
  People as PeopleIcon,
  School as SchoolIcon,
  Assignment as AssignmentIcon,
  Close as CloseIcon,
  CalendarToday as CalendarTodayIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  Done as DoneIcon,
  Info as InfoIcon,
  BarChart as BarChartIcon,
  EmojiEvents as EmojiEventsIcon,
  Timer as TimerIcon,
} from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format, parseISO } from 'date-fns';
import { subDays, subWeeks, subMonths, subYears } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import debounce from 'lodash.debounce'; // Para implementar debounce nas buscas
import { Modal } from '@mui/material';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);



// Funções de fetch
async function fetchReports({ queryKey }) {
  const [_key, { startDate, endDate, interval }] = queryKey;
  const token = localStorage.getItem('token');

  const params = new URLSearchParams();
  if (startDate) params.append('startDate', startDate.toISOString());
  if (endDate) params.append('endDate', endDate.toISOString());
  if (interval) params.append('interval', interval);

  const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!res.ok) throw new Error('Erro ao obter relatórios');
  return res.json();
}

async function fetchUserReport(userId) {
  const token = localStorage.getItem('token');
  const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios/usuario/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!res.ok) throw new Error('Usuário não encontrado ou erro no servidor.');
  return res.json();
}

async function fetchProvaReport(provaId) {
  const token = localStorage.getItem('token');
  const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios/prova/${provaId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!res.ok) throw new Error('Prova não encontrada ou erro no servidor.');
  return res.json();
}

async function fetchUsuariosList(search = '') {
  const token = localStorage.getItem('token');
  const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios/usuarios?search=${encodeURIComponent(search)}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!res.ok) throw new Error('Erro ao buscar usuários');
  return res.json();
}

async function fetchProvasList(search = '') {
  const token = localStorage.getItem('token');
  const res = await fetch(`https://medchoices.com.br:3001/admin/relatorios/provas?search=${encodeURIComponent(search)}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!res.ok) throw new Error('Erro ao buscar provas');
  return res.json();
}


// Tema personalizado
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3D9A8B',
    },
    secondary: {
      main: '#114C5F',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
        },
      },
    },
  },
});


// Componente de cartão de estatística
const StatCard = ({ title, value, icon: Icon, color = 'primary' }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ p: 1.5, borderRadius: 2, bgcolor: `${color}.light` }}>
          <Icon sx={{ color: `${color}.main` }} />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle2" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {value}
          </Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const AdminPage = () => {
  // Estados principais
  const [tabValue, setTabValue] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [interval, setInterval] = useState('daily');
  const [tabLoading, setTabLoading] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [provaModalOpen, setProvaModalOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedProvaData, setSelectedProvaData] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (e, newValue) => {
    setTabLoading(true);
    setTabValue(newValue);
    setTimeout(() => setTabLoading(false), 500);
  };

  const handleSelectUser = async (user) => {
    setLoadingModal(true);
    setUserModalOpen(true);
    try {
      const data = await fetchUserReport(user.id);
      if (data) {
        setSelectedUserData(data);
      } else {
        console.error("Nenhum dado encontrado para o usuário.");
      }
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      setSelectedUserData(null);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleSelectProva = async (prova) => {
    setLoadingModal(true);
    setProvaModalOpen(true);
    try {
      const data = await fetchProvaReport(prova.id);
      setSelectedProvaData(data);
    } catch (error) {
      console.error(error);
      setSelectedProvaData(null);
    } finally {
      setLoadingModal(false);
    }
  };

  const modalStyle = {
    width: '90%',
    maxWidth: 800,
    margin: 'auto',
    mt: 4,
    p: 0,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 4,
    overflow: 'hidden',
  };



  // Modal de Usuário
  const UserModal = ({ userModalOpen, setUserModalOpen, selectedUserData, loadingModal }) => (
    <Modal open={userModalOpen} onClose={() => setUserModalOpen(false)}>
      <Box sx={modalStyle}>
        {/* Cabeçalho */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            bgcolor: 'primary.main',
            color: 'white',
          }}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PersonIcon />
            Detalhes do Usuário
          </Typography>
          <IconButton onClick={() => setUserModalOpen(false)} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
  
        {/* Conteúdo */}
        {loadingModal ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
            <CircularProgress />
          </Box>
        ) : selectedUserData ? (
          <CardContent>
            <Stack spacing={3} divider={<Divider />}>
              {/* Informações Pessoais */}
              <Box>
                <Typography variant="subtitle1" sx={{ color: 'primary.main', mb: 1, fontWeight: 'bold' }}>
                  Informações Pessoais
                </Typography>
                <Stack direction="row" spacing={4}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PersonIcon color="action" />
                    <Typography variant="body1">
                      <strong>Nome Completo:</strong> {selectedUserData.nome} {selectedUserData.sobrenome}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <EmailIcon color="action" />
                    <Typography variant="body1">
                      <strong>Email:</strong> {selectedUserData.email}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                  <CalendarTodayIcon color="action" />
                  <Typography variant="body1">
                    <strong>Data de Criação:</strong>{' '}
                    {new Date(selectedUserData.criado_em).toLocaleString()}
                  </Typography>
                </Stack>
              </Box>
  
              {/* Estatísticas */}
              <Box>
                <Typography variant="subtitle1" sx={{ color: 'primary.main', mb: 1, fontWeight: 'bold' }}>
                  Estatísticas
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>Acessos: {selectedUserData.qtdAcessos}</Typography>
                  <Typography>Sessões: {selectedUserData.qtdSessoes}</Typography>
                  <Typography>Provas Realizadas: {selectedUserData.qtdProvasFeitas}</Typography>
                  <Typography>Não Finalizadas: {selectedUserData.provasNaoFinalizadas}</Typography>
                </Stack>
              </Box>
  
              {/* Aproveitamento por Prova */}
              <Box>
                <Typography variant="subtitle1" sx={{ color: 'primary.main', mb: 1, fontWeight: 'bold' }}>
                  Aproveitamento por Prova
                </Typography>
                {selectedUserData.aproveitamentoProvas?.length > 0 ? (
                  selectedUserData.aproveitamentoProvas.map((prova) => (
                    <Stack
                      key={prova.prova_id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        p: 1,
                        borderRadius: 2,
                        bgcolor: 'background.default',
                        mb: 1,
                        boxShadow: 1,
                      }}
                    >
                      <Typography>{prova.prova_titulo}</Typography>
                      <Chip
                        icon={<DoneIcon />}
                        label={`${Number(prova.aproveitamento).toFixed(1)}%`}
                        color={
                          prova.aproveitamento >= 70
                            ? 'success'
                            : prova.aproveitamento >= 50
                            ? 'warning'
                            : 'error'
                        }
                        size="small"
                      />
                    </Stack>
                  ))
                ) : (
                  <Typography color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
                    O usuário ainda não realizou nenhuma prova.
                  </Typography>
                )}
              </Box>
            </Stack>
          </CardContent>
        ) : (
          <Typography color="error" sx={{ p: 2, textAlign: 'center' }}>
            Erro ao carregar dados do usuário.
          </Typography>
        )}
      </Box>
    </Modal>
  );
  

const ProvaModal = ({ provaModalOpen, setProvaModalOpen, selectedProvaData, loadingModal }) => (
  <Modal open={provaModalOpen} onClose={() => setProvaModalOpen(false)}>
    <Box sx={modalStyle}>
      {/* Cabeçalho */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          bgcolor: 'secondary.main',
          color: 'white',
        }}
      >
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <BarChartIcon />
          Detalhes da Prova
        </Typography>
        <IconButton onClick={() => setProvaModalOpen(false)} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Conteúdo */}
      {loadingModal ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <CircularProgress />
        </Box>
      ) : selectedProvaData ? (
        <CardContent>
          <Stack spacing={3} divider={<Divider />}>
            {/* Informações Gerais */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ color: 'secondary.main', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <InfoIcon />
                Informações da Prova
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Título:</strong> {selectedProvaData.titulo}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Total de Realizações:</strong> {selectedProvaData.qtdUsuariosFizeram}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Estatísticas */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ color: 'secondary.main', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <BarChartIcon />
                Estatísticas de Aproveitamento
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Média Geral:</strong> {Number(selectedProvaData.mediaGeralAproveitamento).toFixed(1)}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Não Finalizadas:</strong> {selectedProvaData.qtdNaoFinalizadas}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Recordes */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ color: 'secondary.main', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <EmojiEventsIcon />
                Recordes
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
                    <Typography variant="body2">Maior Aproveitamento</Typography>
                    <Typography>
                      {selectedProvaData.maiorAproveitamento.nome}{' '}
                      {selectedProvaData.maiorAproveitamento.sobrenome}
                    </Typography>
                    <Typography color="success.main">
                      {Number(selectedProvaData.maiorAproveitamento.valor).toFixed(1)}%
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
                    <Typography variant="body2">Menor Aproveitamento</Typography>
                    <Typography>
                      {selectedProvaData.menorAproveitamento.nome}{' '}
                      {selectedProvaData.menorAproveitamento.sobrenome}
                    </Typography>
                    <Typography color="error.main">
                      {Number(selectedProvaData.menorAproveitamento.valor).toFixed(1)}%
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            {/* Tempos de Realização */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ color: 'secondary.main', mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <TimerIcon />
                Tempos de Realização
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
                    <Typography variant="body2">Menor Tempo</Typography>
                    <Typography>
                      {selectedProvaData.menorTempo.nome} {selectedProvaData.menorTempo.sobrenome}
                    </Typography>
                    <Typography color="success.main">
                      {Number(selectedProvaData.menorTempo.valor)} minutos
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
                    <Typography variant="body2">Maior Tempo</Typography>
                    <Typography>
                      {selectedProvaData.maiorTempo.nome} {selectedProvaData.maiorTempo.sobrenome}
                    </Typography>
                    <Typography color="error.main">
                      {Number(selectedProvaData.maiorTempo.valor)} minutos
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      ) : (
        <Typography color="error" sx={{ p: 2 }}>
          Erro ao carregar dados da prova.
        </Typography>
      )}
    </Box>
  </Modal>
);


  // Dados gerais
  const { data: reportsData, error, isLoading, refetch } = useQuery({
    queryKey: ['relatorios', { startDate, endDate, interval }],
    queryFn: fetchReports,
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });

  const {
    qtd_usuarios,
    ultimo_usuario_cadastrado,
    qtd_materias,
    qtd_provas,
    qtd_questoes,
    usuario_mais_provas,
    media_provas_por_dia,
    media_aproveitamento_provas,
    media_tempo_provas,
    qtd_provas_realizadas_hoje,
    acessosPorDia = [],
  } = reportsData || {};

  // Estados e hooks para a aba de Usuários
  const [userSearch, setUserSearch] = useState('');
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(5);
  const [userOrder, setUserOrder] = useState('asc');
  const [userOrderBy, setUserOrderBy] = useState('nome');
  const [selectedUser, setSelectedUser] = useState(null);


  const { data: usuariosListData, error: usuariosError, isLoading: usuariosLoading } = useQuery({
    queryKey: ['usuariosList', userSearch],
    queryFn: () => fetchUsuariosList(userSearch),
    keepPreviousData: true,
    staleTime: 300000, // 5 minutos
  });
  // console.log(usuariosListData);

  // Estados e hooks para a aba de Provas
  const [provaSearch, setProvaSearch] = useState('');
  const [provaPage, setProvaPage] = useState(0);
  const [provaRowsPerPage, setProvaRowsPerPage] = useState(5);
  const [provaOrder, setProvaOrder] = useState('asc');
  const [provaOrderBy, setProvaOrderBy] = useState('titulo');
  const [selectedProva, setSelectedProva] = useState(null);


  const { data: provasListData, error: provasError, isLoading: provasLoading } = useQuery({
    queryKey: ['provasList', provaSearch],
    queryFn: () => fetchProvasList(provaSearch),
    keepPreviousData: true,
    staleTime: 300000, // 5 minutos
  });

  // Estados para exportação
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      ['Quantidade de Usuários', qtd_usuarios],
      ['Quantidade de Matérias', qtd_materias],
      ['Quantidade de Provas', qtd_provas],
      ['Quantidade de Questões', qtd_questoes],
      ['Provas Realizadas Hoje', qtd_provas_realizadas_hoje],
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Relatorio');
    XLSX.writeFile(wb, 'relatorio.xlsx');
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text('Relatório Administrativo', 14, 20);

    const tableData = [
      ['Métrica', 'Valor'],
      ['Quantidade de Usuários', qtd_usuarios],
      ['Quantidade de Matérias', qtd_materias],
      ['Quantidade de Provas', qtd_provas],
      ['Quantidade de Questões', qtd_questoes],
      ['Provas Realizadas Hoje', qtd_provas_realizadas_hoje],
    ];

    doc.autoTable({
      head: [tableData[0]],
      body: tableData.slice(1),
      startY: 30,
      theme: 'grid',
    });

    doc.save('relatorio.pdf');
  };

  // Funções para ordenar tabelas
  const handleRequestSort = (property, type = 'user') => {
    if (type === 'user') {
      const isAsc = userOrderBy === property && userOrder === 'asc';
      setUserOrder(isAsc ? 'desc' : 'asc');
      setUserOrderBy(property);
    } else if (type === 'prova') {
      const isAsc = provaOrderBy === property && provaOrder === 'asc';
      setProvaOrder(isAsc ? 'desc' : 'asc');
      setProvaOrderBy(property);
    }
  };

  // Funções para paginar tabelas
  const handleChangePage = (event, newPage, type = 'user') => {
    if (type === 'user') {
      setUserPage(newPage);
    } else if (type === 'prova') {
      setProvaPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event, type = 'user') => {
    if (type === 'user') {
      setUserRowsPerPage(parseInt(event.target.value, 10));
      setUserPage(0);
    } else if (type === 'prova') {
      setProvaRowsPerPage(parseInt(event.target.value, 10));
      setProvaPage(0);
    }
  };

  // Filtragem e ordenação de usuários
  const filteredUsuarios = useMemo(() => {
    return usuariosListData?.usuarios?.filter(
      (user) =>
        user.nome.toLowerCase().includes(userSearch.toLowerCase()) ||
        user.email.toLowerCase().includes(userSearch.toLowerCase()) ||
        user.funcao.toLowerCase().includes(userSearch.toLowerCase())
    ) || [];
  }, [userSearch, usuariosListData]);

  const sortedUsuarios = useMemo(() => {
    return [...filteredUsuarios].sort((a, b) => {
      if (a[userOrderBy] < b[userOrderBy]) return userOrder === 'asc' ? -1 : 1;
      if (a[userOrderBy] > b[userOrderBy]) return userOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredUsuarios, userOrder, userOrderBy]);

  const paginatedUsuarios = useMemo(() => {
    const start = userPage * userRowsPerPage;
    return sortedUsuarios.slice(start, start + userRowsPerPage);
  }, [sortedUsuarios, userPage, userRowsPerPage]);

  // Filtragem e ordenação de provas
  const filteredProvas = useMemo(() => {
    return provasListData?.provas?.filter(
      (prova) =>
        prova.titulo.toLowerCase().includes(provaSearch.toLowerCase())
    ) || [];
  }, [provaSearch, provasListData]);

  const sortedProvas = useMemo(() => {
    return [...filteredProvas].sort((a, b) => {
      if (a[provaOrderBy] < b[provaOrderBy]) return provaOrder === 'asc' ? -1 : 1;
      if (a[provaOrderBy] > b[provaOrderBy]) return provaOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredProvas, provaOrder, provaOrderBy]);

  const paginatedProvas = useMemo(() => {
    const start = provaPage * provaRowsPerPage;
    return sortedProvas.slice(start, start + provaRowsPerPage);
  }, [sortedProvas, provaPage, provaRowsPerPage]);

  // Efeitos para buscar e selecionar usuários
  useEffect(() => {
    if (selectedUser) {
      // Utilize react-query para buscar dados detalhados do usuário
      const fetchData = async () => {
        try {
          const data = await fetchUserReport(selectedUser.id);
          setSelectedUserData(data);
        } catch (error) {
          console.error(error);
          setSelectedUserData(null);
        }
      };
      fetchData();
    } else {
      setSelectedUserData(null);
    }
  }, [selectedUser]);

  // Efeitos para buscar e selecionar provas
  useEffect(() => {
    if (selectedProva) {
      const fetchData = async () => {
        try {
          const data = await fetchProvaReport(selectedProva.id);
          setSelectedProvaData(data);
        } catch (error) {
          console.error(error);
          setSelectedProvaData(null);
        }
      };
      fetchData();
    } else {
      setSelectedProvaData(null);
    }
  }, [selectedProva]);

  // Dados para o gráfico
  const chartData = useMemo(() => {
    if (!acessosPorDia.length) return null;
    const labels = acessosPorDia
      .map((item) => {
        const date = item.data ? new Date(item.data) : null;
        return date && !isNaN(date) ? format(date, 'dd/MM') : 'Data inválida';
      });
    const valores = acessosPorDia.map((item) => item.acessos);
    return {
      labels,
      datasets: [
        {
          label: 'Acessos',
          data: valores,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }, [acessosPorDia]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Acessos no Período',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Título dinâmico para Relatórios Gerais
  const hoje = format(new Date(), 'dd/MM/yyyy');

  // Manipuladores de ordenação para Usuários e Provas
  const createSortHandler = (property, type) => (event) => {
    handleRequestSort(property, type);
  };

  // Implementação do debounce para buscas
  const debouncedUserSearch = useMemo(
    () => debounce((value) => setUserSearch(value), 300),
    []
  );

  const handleUserSearchChange = (e) => {
    debouncedUserSearch(e.target.value);
    setUserPage(0);
  };

  const debouncedProvaSearch = useMemo(
    () => debounce((value) => setProvaSearch(value), 300),
    []
  );

  const handleProvaSearchChange = (e) => {
    debouncedProvaSearch(e.target.value);
    setProvaPage(0);
  };

  // Limpar o debounce quando o componente desmonta
  useEffect(() => {
    return () => {
      debouncedUserSearch.cancel();
      debouncedProvaSearch.cancel();
    };
  }, [debouncedUserSearch, debouncedProvaSearch]);

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <Typography color="error">Erro ao carregar dados.</Typography>
      </Box>
    );
  }

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <UserModal
          userModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
          selectedUserData={selectedUserData}
          loadingModal={loadingModal}
        />
        <ProvaModal
          provaModalOpen={provaModalOpen}
          setProvaModalOpen={setProvaModalOpen}
          selectedProvaData={selectedProvaData}
          loadingModal={loadingModal}
        />
        <Box p={isMobile ? 2 : 4}>
          {/* Filtros e ações */}
          <Paper elevation={0} sx={{ p: 2, mb: 4, borderRadius: 2 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                alignItems="center"
                flex={1}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Data Início"
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                  <DatePicker
                    label="Data Fim"
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>

                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel>Intervalo</InputLabel>
                  <Select
                    value={interval}
                    label="Intervalo"
                    onChange={(e) => setInterval(e.target.value)}
                  >
                    <MenuItem value="daily">Diário</MenuItem>
                    <MenuItem value="weekly">Semanal</MenuItem>
                    <MenuItem value="monthly">Mensal</MenuItem>
                    <MenuItem value="yearly">Anual</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={1}>
  <Tooltip title="Atualizar dados">
    <IconButton onClick={() => window.location.reload()}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
  <Tooltip title="Exportar PDF">
    <Button
      variant="outlined"
      startIcon={<PictureAsPdfIcon />}
      onClick={handleExportPDF}
    >
      {!isMobile && 'PDF'}
    </Button>
  </Tooltip>
  <Tooltip title="Exportar Excel">
    <Button
      variant="outlined"
      startIcon={<SaveAltIcon />}
      onClick={handleExportExcel}
    >
      {!isMobile && 'Excel'}
    </Button>
  </Tooltip>
</Stack>
            </Stack>
          </Paper>

          {/* Abas */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              mb: 3,
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTabs-indicator': {
                height: 3,
                borderRadius: '3px 3px 0 0',
              },
            }}
          >
            <Tab label={`Relatórios Gerais - ${hoje}`} />
            <Tab label="Relatórios de Usuários" />
            <Tab label="Relatórios de Provas" />
          </Tabs>

          <Fade in={true}>
            <Box>
              {/* Relatórios Gerais */}
              {tabValue === 0 && (
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                      <StatCard
                        title="Total de Usuários"
                        value={qtd_usuarios}
                        icon={PeopleIcon}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <StatCard
                        title="Total de Matérias"
                        value={qtd_materias}
                        icon={SchoolIcon}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <StatCard
                        title="Total de Provas"
                        value={qtd_provas}
                        icon={AssignmentIcon}
                        color="success"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <StatCard
                        title="Provas Hoje"
                        value={qtd_provas_realizadas_hoje}
                        icon={TrendingUpIcon}
                        color="info"
                      />
                    </Grid>

                    {ultimo_usuario_cadastrado && (
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Último Usuário Cadastrado
                            </Typography>
                            <Stack spacing={1}>
                              <Typography variant="subtitle1">
                                {ultimo_usuario_cadastrado.nome} {ultimo_usuario_cadastrado.sobrenome}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {ultimo_usuario_cadastrado.email}
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                Cadastrado em: {ultimo_usuario_cadastrado.created_at &&
                                  format(new Date(ultimo_usuario_cadastrado.created_at), 'dd/MM/yyyy HH:mm')}

                              </Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {usuario_mais_provas && (
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Usuário Mais Ativo
                            </Typography>
                            <Stack spacing={1}>
                              <Typography variant="subtitle1">
                                {usuario_mais_provas.nome} {usuario_mais_provas.sobrenome}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Total de provas: {usuario_mais_provas.total_provas_feitas}
                              </Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Aproveitamento por Prova
                          </Typography>
                          <Grid container spacing={2}>
                            {media_aproveitamento_provas?.map((row) => {
                              const valorPontuacao = Number(row.media_pontuacao);
                              return (
                                <Grid item xs={12} sm={6} md={4} key={row.id}>
                                  <Paper
                                    elevation={0}
                                    sx={{
                                      p: 2,
                                      bgcolor: 'background.default',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography variant="body2" noWrap sx={{ flex: 1 }}>
                                      {row.titulo}
                                    </Typography>
                                    <Chip
                                      label={isNaN(valorPontuacao) ? 'N/A' : `${valorPontuacao.toFixed(2)}%`}
                                      color="primary"
                                      size="small"
                                      sx={{ ml: 1 }}
                                    />
                                  </Paper>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    {chartData && (
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Box sx={{ height: 400, p: 1 }}>
                              <Line data={chartData} options={chartOptions} />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {/* Relatórios de Usuários */}
              {tabValue === 1 && (
                <Box>
                  {/* Busca de Usuários */}
                  <TextField
                    label="Buscar Usuário"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleUserSearchChange}
                    sx={{ mb: 2 }}
                  />

                  {/* Tabela de Usuários */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={userOrderBy === 'nome'}
                              direction={userOrderBy === 'nome' ? userOrder : 'asc'}
                              onClick={createSortHandler('nome', 'user')}
                            >
                              Nome
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={userOrderBy === 'email'}
                              direction={userOrderBy === 'email' ? userOrder : 'asc'}
                              onClick={createSortHandler('email', 'user')}
                            >
                              Email
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={userOrderBy === 'funcao'}
                              direction={userOrderBy === 'funcao' ? userOrder : 'asc'}
                              onClick={createSortHandler('funcao', 'user')}
                            >
                              Função
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={userOrderBy === 'criado_em'}
                              direction={userOrderBy === 'criado_em' ? userOrder : 'asc'}
                              onClick={createSortHandler('criado_em', 'user')}
                            >
                              Data de Criação
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usuariosLoading ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <CircularProgress size={24} />
                            </TableCell>
                          </TableRow>
                        ) : usuariosError ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography color="error">Erro ao carregar usuários.</Typography>
                            </TableCell>
                          </TableRow>
                        ) : paginatedUsuarios.length > 0 ? (
                          paginatedUsuarios.map((user) => (
                            <TableRow
                              key={user.id}
                              hover
                              onClick={() => handleSelectUser(user)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>{user.nome} {user.sobrenome}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.funcao}</TableCell>
                              <TableCell>
                                {user.created_at &&
                                  !isNaN(new Date(user.created_at)) &&
                                  format(new Date(user.created_at), 'dd/MM/yyyy')}

                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography>Nenhum usuário encontrado.</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={filteredUsuarios.length}
                      rowsPerPage={userRowsPerPage}
                      page={userPage}
                      onPageChange={(e, newPage) => handleChangePage(e, newPage, 'user')}
                      onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, 'user')}
                    />
                  </TableContainer>


                </Box>
              )}

              {/* Relatórios de Provas */}
              {tabValue === 2 && (
                <Box>
                  {/* Busca de Provas */}
                  <TextField
                    label="Buscar Prova"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleProvaSearchChange}
                    sx={{ mb: 2 }}
                  />

                  {/* Tabela de Provas */}
                  {/* Tabela de Provas */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={provaOrderBy === 'titulo'}
                              direction={provaOrderBy === 'titulo' ? provaOrder : 'asc'}
                              onClick={createSortHandler('titulo', 'prova')}
                            >
                              Título
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={provaOrderBy === 'realizacoes'}
                              direction={provaOrderBy === 'realizacoes' ? provaOrder : 'asc'}
                              onClick={createSortHandler('realizacoes', 'prova')}
                            >
                              Realizações
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Data de Criação</TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={provaOrderBy === 'iniciadas'}
                              direction={provaOrderBy === 'iniciadas' ? provaOrder : 'asc'}
                              onClick={createSortHandler('iniciadas', 'prova')}
                            >
                              Iniciadas
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {provasLoading ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <CircularProgress size={24} />
                            </TableCell>
                          </TableRow>
                        ) : provasError ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography color="error">Erro ao carregar provas.</Typography>
                            </TableCell>
                          </TableRow>
                        ) : paginatedProvas.length > 0 ? (
                          paginatedProvas.map((prova) => (
                            <TableRow
                              key={prova.id}
                              hover
                              onClick={() => handleSelectProva(prova)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>{prova.titulo}</TableCell>
                              <TableCell>{prova.qtd_realizacoes}</TableCell>
                              <TableCell>
                                {prova.data_criacao &&
                                  !isNaN(new Date(prova.data_criacao)) &&
                                  format(new Date(prova.data_criacao), 'dd/MM/yyyy')}
                              </TableCell>
                              <TableCell>{prova.qtd_iniciadas}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography>Nenhuma prova encontrada.</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={filteredProvas.length}
                      rowsPerPage={provaRowsPerPage}
                      page={provaPage}
                      onPageChange={(e, newPage) => handleChangePage(e, newPage, 'prova')}
                      onRowsPerPageChange={(e) => handleChangeRowsPerPage(e, 'prova')}
                    />
                  </TableContainer>



                </Box>
              )}
            </Box>
          </Fade>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminPage;
