import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Link as MuiLink,
  CircularProgress,
  Alert,
  Divider,
  MenuItem,
} from '@mui/material';
import { Visibility, VisibilityOff, PersonAdd, Lock, Email } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Register = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    senha: '',
    confirmarSenha: '',
    faculdade: '',
  });

  const [faculdades, setFaculdades] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [mostrarConfirmarSenha, setMostrarConfirmarSenha] = useState(false);
  const [erroApi, setErroApi] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFaculdades, setLoadingFaculdades] = useState(false);

  const fetchFaculdades = async () => {
    setLoading(true);
    setErroApi('');
    try {
      const response = await fetch('https://medchoices.com.br:3001/admin/faculdades');
      if (!response.ok) {
        throw new Error('Erro ao carregar faculdades.');
      }
      const data = await response.json();
      if (data.success) {
        setFaculdades(data.faculdades); // Mantendo a estrutura { id, nome }
      } else {
        throw new Error(data.message || 'Erro ao carregar faculdades.');
      }
    } catch (err) {
      console.error('Erro na requisição de faculdades:', err);
      setErroApi('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchFaculdades();
  }, []);
  
  
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const validarEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validarSenha = (senha) => senha.length >= 8;

  const validate = () => {
    let errors = {};

    if (!formValues.nome.trim()) errors.nome = 'O nome é obrigatório.';
    if (!formValues.sobrenome.trim()) errors.sobrenome = 'O sobrenome é obrigatório.';
    if (!formValues.email) errors.email = 'O email é obrigatório.';
    else if (!validarEmail(formValues.email)) errors.email = 'O formato do email é inválido.';
    if (!formValues.senha) errors.senha = 'A senha é obrigatória.';
    else if (!validarSenha(formValues.senha)) errors.senha = 'A senha deve ter pelo menos 8 caracteres.';
    if (formValues.confirmarSenha !== formValues.senha) errors.confirmarSenha = 'As senhas não correspondem.';
    if (!formValues.faculdade) errors.faculdade = 'A faculdade é obrigatória.';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErroApi('');
    setMensagemSucesso('');

    if (validate()) {
      setLoading(true);
      try {
        const response = await fetch('https://medchoices.com.br:3001/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formValues),
        });

        const data = await response.json();

        if (data.success) {
          setMensagemSucesso('Registro bem-sucedido! Verifique seu e-mail para ativar sua conta.');
          setTimeout(() => navigate('/login'), 3000);
        } else {
          setErroApi(data.message || 'Erro ao cadastrar o usuário.');
        }
      } catch (err) {
        setErroApi('Erro ao conectar com o servidor.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={6}
          sx={{
            p: { xs: 3, sm: 6 },
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
            <Box
              sx={{
                width: 56,
                height: 56,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <PersonAdd sx={{ color: '#fff', fontSize: 30 }} />
            </Box>
            <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>
              Registrar-se
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Já tem uma conta?{' '}
              <MuiLink component={RouterLink} to="/login" underline="hover" color="primary">
                Faça login aqui
              </MuiLink>
            </Typography>
          </Box>

          {erroApi && <Alert severity="error" sx={{ mb: 3 }}>{erroApi}</Alert>}
          {mensagemSucesso && <Alert severity="success" sx={{ mb: 3 }}>{mensagemSucesso}</Alert>}

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="given-name"
              value={formValues.nome}
              onChange={handleChange}
              error={!!formErrors.nome}
              helperText={formErrors.nome}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="sobrenome"
              label="Sobrenome"
              name="sobrenome"
              autoComplete="family-name"
              value={formValues.sobrenome}
              onChange={handleChange}
              error={!!formErrors.sobrenome}
              helperText={formErrors.sobrenome}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
  select
  margin="normal"
  required
  fullWidth
  id="faculdade"
  label="Faculdade"
  name="faculdade"
  value={formValues.faculdade}
  onChange={handleChange}
  error={!!formErrors.faculdade}
  helperText={formErrors.faculdade}
  disabled={loadingFaculdades}
>
  {loadingFaculdades ? (
    <MenuItem disabled>Carregando...</MenuItem>
  ) : (
    faculdades.map((faculdade) => (
      <MenuItem key={faculdade.id} value={faculdade.id}>
        {faculdade.nome}
      </MenuItem>
    ))
  )}
</TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              id="senha"
              label="Senha"
              name="senha"
              type={mostrarSenha ? 'text' : 'password'}
              value={formValues.senha}
              onChange={handleChange}
              error={!!formErrors.senha}
              helperText={formErrors.senha}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setMostrarSenha(!mostrarSenha)} edge="end">
                      {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmarSenha"
              label="Confirmar Senha"
              name="confirmarSenha"
              type={mostrarConfirmarSenha ? 'text' : 'password'}
              value={formValues.confirmarSenha}
              onChange={handleChange}
              error={!!formErrors.confirmarSenha}
              helperText={formErrors.confirmarSenha}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setMostrarConfirmarSenha(!mostrarConfirmarSenha)} edge="end">
                      {mostrarConfirmarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <PersonAdd />}
              sx={{ py: 1.5, mb: 3, mt: 2 }}
            >
              {loading ? 'Registrando...' : 'Registrar'}
            </Button>
            <Box sx={{ position: 'relative', my: 3 }}>
              <Divider />
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Register;
