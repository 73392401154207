// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Certifique-se de usar a versão 3.x
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  const [loading, setLoading] = useState(true); // Estado de carregamento

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
          // Token expirado
          console.warn('Token expirado. Realizando logout.');
          localStorage.removeItem('token');
          setAuth({
            isAuthenticated: false,
            user: null,
            token: null,
          });
        } else {
          console.log('Token válido. Atualizando contexto de autenticação.');
          setAuth({
            isAuthenticated: true,
            user: {
              userId: decoded.userId,
              email: decoded.email,
              funcao: decoded.funcao,
              nome: decoded.nome,
              sobrenome: decoded.sobrenome,
            },
            token: token,
          });
        }
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        localStorage.removeItem('token');
        setAuth({
          isAuthenticated: false,
          user: null,
          token: null,
        });
      }
    }
    setLoading(false); // Finaliza o carregamento após a verificação
  }, []);

  const login = (token) => {
    try {
      const decoded = jwtDecode(token);
      setAuth({
        isAuthenticated: true,
        user: {
          userId: decoded.userId,
          email: decoded.email,
          funcao: decoded.funcao,
          nome: decoded.nome,
          sobrenome: decoded.sobrenome,
        },
        token: token,
      });
      localStorage.setItem('token', token);
      console.log('Usuário logado e contexto atualizado.');
    } catch (error) {
      console.error('Erro ao decodificar o token durante o login:', error);
      logout(); // Opcional: encerra a sessão se ocorrer erro
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuth({
      isAuthenticated: false,
      user: null,
      token: null,
    });
    console.log('Usuário deslogado e contexto resetado.');
  };

  // Função para atualizar o nome do usuário no contexto após atualização no perfil
  const updateUserName = (newName) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: {
        ...prevAuth.user,
        nome: newName, // Atualiza o nome no contexto
      },
    }));
  };

  // Função para atualizar o sobrenome do usuário no contexto (caso necessário)
  const updateUserSurname = (newSurname) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: {
        ...prevAuth.user,
        sobrenome: newSurname, // Atualiza o sobrenome no contexto
      },
    }));
  };

  // Função para atualizar o email do usuário no contexto (caso necessário)
  const updateUserEmail = (newEmail) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: {
        ...prevAuth.user,
        email: newEmail, // Atualiza o email no contexto
      },
    }));
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, updateUserName, updateUserSurname, updateUserEmail, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Validação de Props (Opcional)
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
