// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient(); // cria a instância do QueryClient

const theme = createTheme({
  // Personalize o tema aqui, se desejar
  palette: {
    primary: {
      main: '#3D9A8B'
    },
    secondary: {
      main: '#114C5F'
    },
    success: {
      main: '#2a9d8f'
    },
    warning: {
      main: '#f4a261'
    },
    info: {
      main: '#e9c46a'
    },
    error: {
      main: '#bc4749'
    },
    cinza: {
      main: '#eeee'
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  {/* Envolve o App dentro do QueryClientProvider */}
  <QueryClientProvider client={queryClient}>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
      </QueryClientProvider>
      </React.StrictMode>
);
