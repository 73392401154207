import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  MobileStepper,
  Paper,
  useTheme,
  Avatar,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  School as SchoolIcon,
  MenuBook as MenuBookIcon,
  Assessment as AssessmentIcon,
  EmojiEvents as EmojiEventsIcon,
} from '@mui/icons-material';

const Instrucoes = ({ onFinalizar, auth }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const instrucoes = [
    {
      titulo: `Seja muito bem-vindo, ${auth.user.nome}!`,
      descricao: 'Estamos aqui para facilitar seus estudos. Descubra como usar o MedChoices para alcançar seus objetivos acadêmicos.',
      icone: <SchoolIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
    },
    {
      titulo: 'Encontre suas provas facilmente',
      descricao: 'No menu "Choices", escolha a matéria desejada e veja todas as provas disponíveis. Tudo organizado para que você estude sem complicações.',
      icone: <MenuBookIcon sx={{ fontSize: 40, color: theme.palette.secondary.main }} />,
    },
    {
      titulo: 'Resultados detalhados',
      descricao: 'Após finalizar uma prova, você poderá analisar todas as suas respostas. Veja o que acertou e o que pode melhorar.',
      icone: <AssessmentIcon sx={{ fontSize: 40, color: theme.palette.info.main }} />,
    },
    {
      titulo: 'Estamos torcendo por você!',
      descricao: 'Agradecemos por confiar no MedChoices. Vamos juntos nessa jornada de aprendizado. Sucesso nos seus estudos!',
      icone: <EmojiEventsIcon sx={{ fontSize: 40, color: theme.palette.success.main }} />,
    },
  ];

  const handleNext = () => {
    if (activeStep < instrucoes.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      onFinalizar();
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={4}
        sx={{
          maxWidth: 450,
          width: '90%',
          bgcolor: theme.palette.background.paper,
          borderRadius: 3,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300,
            backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
            color: theme.palette.primary.contrastText,
            borderBottom: `4px solid ${theme.palette.primary.main}`,
          }}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.background.paper,
              width: 80,
              height: 80,
              mb: 2,
              boxShadow: theme.shadows[4],
            }}
          >
            {instrucoes[activeStep].icone}
          </Avatar>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
            {instrucoes[activeStep].titulo}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', lineHeight: 1.6 }}>
            {instrucoes[activeStep].descricao}
          </Typography>
        </Box>

        <MobileStepper
          steps={instrucoes.length}
          position="static"
          activeStep={activeStep}
          sx={{
            bgcolor: theme.palette.background.default,
            py: 2,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
          nextButton={
            <Button
              size="medium"
              onClick={handleNext}
              variant="contained"
              sx={{
                boxShadow: theme.shadows[2],
                bgcolor: theme.palette.success.main,
                '&:hover': { bgcolor: theme.palette.success.dark },
              }}
            >
              {activeStep === instrucoes.length - 1 ? 'Finalizar' : 'Próximo'}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="medium"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                color: theme.palette.grey[700],
                '&:hover': { color: theme.palette.grey[900] },
              }}
            >
              <KeyboardArrowLeft />
              Voltar
            </Button>
          }
        />
      </Paper>
    </Box>
  );
};

export default Instrucoes;
