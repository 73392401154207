import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Container,
  Chip,
  Fade,
  Divider,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  InputAdornment,
  Stack,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import QuizIcon from '@mui/icons-material/Quiz';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme, alpha } from '@mui/material/styles';

const DetalhesProva = ({ resultadoId: propResultadoId }) => {
  const theme = useTheme();
  const { resultadoId: paramResultadoId } = useParams();
  const navigate = useNavigate();
  const resultadoId = propResultadoId || paramResultadoId;
  const [detalhes, setDetalhes] = useState([]);
  const [filteredDetalhes, setFilteredDetalhes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [estatisticas, setEstatisticas] = useState({
    total: 0,
    corretas: 0,
    incorretas: 0,
  });

  useEffect(() => {
    if (!resultadoId) {
      console.error('Erro: resultadoId não fornecido ao DetalhesProva.');
      return;
    }

    const fetchDetalhes = async () => {
      setLoading(true);

      try {
        const response = await fetch(`https://medchoices.com.br:3001/admin/historico/${resultadoId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erro na resposta da API: ${response.statusText}`);
        }

        const data = await response.json();

        if (data.success) {
          setDetalhes(data.detalhes);
          setFilteredDetalhes(data.detalhes);
          
          // Calcula estatísticas
          const corretas = data.detalhes.filter(q => q.correta).length;
          setEstatisticas({
            total: data.detalhes.length,
            corretas,
            incorretas: data.detalhes.length - corretas,
          });
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes da prova:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetalhes();
  }, [resultadoId]);

  useEffect(() => {
    const filtered = detalhes.filter((questao) => {
      const term = searchTerm.toLowerCase();
      return (
        questao.enunciado.toLowerCase().includes(term) ||
        ['a', 'b', 'c', 'd'].some((opcao) =>
          questao[`opcao_${opcao}`]?.toLowerCase().includes(term)
        )
      );
    });
    setFilteredDetalhes(filtered);
  }, [searchTerm, detalhes]);

  if (!resultadoId) {
    return (
      <Container maxWidth="lg">
        <Alert 
          severity="error"
          variant="filled"
          sx={{ mt: 4 }}
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Voltar
            </Button>
          }
        >
          ID do resultado não fornecido.
        </Alert>
      </Container>
    );
  }

  if (loading) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="80vh"
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2, color: 'text.secondary' }}>
          Carregando os detalhes da prova...
        </Typography>
      </Box>
    );
  }

  if (detalhes.length === 0) {
    return (
      <Container maxWidth="lg">
        <Alert 
          severity="warning"
          variant="filled"
          sx={{ mt: 4 }}
          action={
            <Button color="inherit" size="small" onClick={() => navigate(-1)}>
              Voltar
            </Button>
          }
        >
          Nenhum detalhe encontrado para o resultado.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Paper elevation={0} sx={{ p: 3, mb: 4, backgroundColor: theme.palette.grey[50] }}>
          {/* Cabeçalho Responsivo */}
          <Box 
            display="flex" 
            flexDirection={{ xs: 'column', sm: 'row' }} 
            alignItems={{ xs: 'flex-start', sm: 'center' }} 
            justifyContent="space-between" 
            mb={3}
          >
            <Box display="flex" alignItems="center" gap={2} mb={{ xs: 2, sm: 0 }}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ 
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  // Aumenta o tamanho do botão em mobile para melhor usabilidade
                  width: { xs: 40, sm: 40 },
                  height: { xs: 40, sm: 40 },
                }}
                aria-label="Voltar"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography 
                variant="h5" 
                fontWeight="bold" 
                color="primary"
                sx={{
                  // Ajusta o tamanho da fonte para mobile
                  fontSize: { xs: '1.25rem', sm: '1.5rem' },
                  wordBreak: 'break-word', // Evita quebra de palavras no título
                }}
              >
                Detalhes da Prova
              </Typography>
            </Box>
            
            <Box 
              display="flex" 
              flexWrap="wrap" 
              gap={1} 
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            >
              <Chip
                icon={<QuizIcon />}
                label={`Total: ${estatisticas.total}`}
                color="primary"
                variant="outlined"
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                }}
              />
              <Chip
                icon={<CheckCircleIcon />}
                label={`Corretas: ${estatisticas.corretas}`}
                color="success"
                variant="outlined"
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                }}
              />
              <Chip
                icon={<CancelIcon />}
                label={`Incorretas: ${estatisticas.incorretas}`}
                color="error"
                variant="outlined"
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                }}
              />
            </Box>
          </Box>

          {/* Barra de Pesquisa Responsiva */}
          <TextField
            fullWidth
            placeholder="Buscar por enunciado ou alternativas..."
            variant="outlined"
            size="medium"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Filtrar">
                    <IconButton aria-label="Filtrar">
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: 'white',
              borderRadius: 1,
              mb: 3,
            }}
            aria-label="Buscar por enunciado ou alternativas"
          />
        </Paper>

        <Fade in={true}>
          <Box>
            {filteredDetalhes.map((questao, index) => (
              <Card 
                key={index}
                variant="outlined"
                sx={{
                  mb: 3,
                  borderColor: questao.correta ? theme.palette.success.main : theme.palette.error.main,
                  backgroundColor: questao.correta ? 'rgba(0, 200, 83, 0.04)' : 'rgba(244, 67, 54, 0.04)',
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    {/* Cabeçalho da Questão */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography 
                        variant="h6" 
                        color="primary"
                        sx={{
                          // Ajusta o tamanho da fonte para mobile
                          fontSize: { xs: '1rem', sm: '1.25rem' },
                        }}
                      >
                        Questão {index + 1}
                      </Typography>
                      {questao.correta ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </Box>

                    {/* Enunciado da Questão */}
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                        wordBreak: 'break-word', // Evita quebra de palavras no enunciado
                      }}
                    >
                      {questao.enunciado}
                    </Typography>
                    
                    <Divider />

                    {/* Alternativas */}
                    <Grid container spacing={2}>
                      {['a', 'b', 'c', 'd'].map((opcao) => (
                        <Grid item xs={12} key={opcao}>
                          <Paper
                            variant="outlined"
                            sx={{
                              p: 2,
                              borderColor: 
                                questao.resposta_correta === opcao ? 
                                  theme.palette.success.main :
                                  questao.alternativa_escolhida === opcao ?
                                    theme.palette.error.main :
                                    theme.palette.grey[300],
                              backgroundColor:
                                questao.resposta_correta === opcao ?
                                  'rgba(0, 200, 83, 0.08)' :
                                  questao.alternativa_escolhida === opcao ?
                                    'rgba(244, 67, 54, 0.08)' :
                                    'transparent',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 
                                  questao.resposta_correta === opcao ||
                                  questao.alternativa_escolhida === opcao ? 
                                    500 : 400,
                                // Ajusta o tamanho da fonte para mobile
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                              }}
                            >
                              {questao[`opcao_${opcao}`]}
                              {/* Chips Indicando Resposta Correta e Resposta do Usuário */}
                              <Box component="span" ml={1}>
                                {questao.resposta_correta === opcao && (
                                  <Chip 
                                    label="Correta"
                                    size="small"
                                    color="success"
                                    sx={{ ml: 1, fontSize: '0.625rem' }}
                                    aria-label="Resposta correta"
                                  />
                                )}
                                {questao.alternativa_escolhida === opcao && 
                                 questao.alternativa_escolhida !== questao.resposta_correta && (
                                  <Chip 
                                    label="Sua resposta"
                                    size="small"
                                    color="error"
                                    sx={{ ml: 1, fontSize: '0.625rem' }}
                                    aria-label="Sua resposta incorreta"
                                  />
                                )}
                              </Box>
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>

                    {/* Tempo Utilizado
                    <Box display="flex" alignItems="center">
                      <Chip
                        icon={<AccessTimeIcon />}
                        size="small"
                        sx={{
                          fontWeight: 500,
                          fontSize: { xs: '0.625rem', sm: '0.75rem' },
                          padding: '2px 6px',
                          maxWidth: '40px',
                          display: 'inline-flex',
                          whiteSpace: 'nowrap',
                        }}
                        aria-label={`Tempo utilizado: ${Math.floor(questao.tempo_utilizado / 60)} minutos e ${questao.tempo_utilizado % 60} segundos`}
                      />
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 1, 
                          fontSize: { xs: '0.75rem', sm: '0.875rem' }, 
                          fontWeight: 500 
                        }}
                      >
                        {`${Math.floor(questao.tempo_utilizado / 60)}m ${questao.tempo_utilizado % 60}s`}
                      </Typography>
                    </Box> */}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Fade>
      </Box>
    </Container>
  );
};

export default DetalhesProva;
