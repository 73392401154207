import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Box,
  Typography,
  IconButton,
  Stack,
  useTheme,
  Divider,
  CircularProgress
} from '@mui/material';
import { Close as CloseIcon, PersonAdd, Save } from '@mui/icons-material';

const UserForm = ({ open, handleClose, refreshUsers, user }) => {
  const theme = useTheme();
  const [nome, setNome] = useState(user ? user.nome : '');
  const [sobrenome, setSobrenome] = useState(user ? user.sobrenome : '');
  const [email, setEmail] = useState(user ? user.email : '');
  const [senha, setSenha] = useState('');
  const [funcao, setFuncao] = useState(user ? user.funcao : 'usuario');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Estado para indicar carregamento

  useEffect(() => {
    // Limpar mensagens ao abrir o modal
    if (open) {
      setError('');
      setSuccess('');
      setLoading(false);
    }
  }, [open]);

  const resetForm = () => {
    setNome('');
    setSobrenome('');
    setEmail('');
    setSenha('');
    setFuncao('usuario');
    setError('');
    setSuccess('');
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!nome || !sobrenome || !email) {
      setError('Nome, sobrenome e email são obrigatórios.');
      return;
    }

    if (!user && !senha) {
      setError('Senha é obrigatória para criação de usuário.');
      return;
    }

    setLoading(true);
    try {
      const method = user ? 'PUT' : 'POST';
      const url = user 
        ? `https://medchoices.com.br:3001/admin/users/${user.id}` 
        : 'https://medchoices.com.br:3001/admin/users';

      const body = user
        ? JSON.stringify({ nome, sobrenome, email, senha })
        : JSON.stringify({ nome, sobrenome, email, senha, funcao });

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body,
      });

      const data = await response.json();

      if (data.success) {
        // Se o backend não retornar uma mensagem, usamos uma padrão
        const successMessage = data.message || (user ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!');
        setSuccess(successMessage);
        setError('');
        refreshUsers();
        resetForm();
        handleClose();
      } else {
        setError(data.message || 'Erro ao salvar usuário.');
        setSuccess('');
      }
    } catch (err) {
      console.error('Erro ao salvar usuário:', err);
      setError('Erro ao salvar usuário.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: theme.shadows[5],
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        bgcolor: theme.palette.primary.main,
        color: 'white',
        py: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {user ? <Save /> : <PersonAdd />}
          <Typography variant="h6">
            {user ? 'Editar Usuário' : 'Criar Novo Usuário'}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <Stack spacing={2}>
          {(error || success) && (
            <Box sx={{ mt: 1 }}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              autoFocus
              label="Nome"
              type="text"
              fullWidth
              variant="outlined"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{ flex: 1 }}
            />
            <TextField
              label="Sobrenome"
              type="text"
              fullWidth
              variant="outlined"
              value={sobrenome}
              onChange={(e) => setSobrenome(e.target.value)}
              sx={{ flex: 1 }}
            />
          </Box>

          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            label={user ? "Nova Senha" : "Senha"}
            type="password"
            fullWidth
            variant="outlined"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            helperText={user ? "Deixe em branco para não alterar a senha." : ""}
          />

          {!user && (
            <FormControl fullWidth>
              <InputLabel>Função</InputLabel>
              <Select
                value={funcao}
                label="Função"
                onChange={(e) => setFuncao(e.target.value)}
              >
                <MenuItem value="usuario">Usuário</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>

      <Divider />
      
      <DialogActions sx={{ p: 2.5 }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={loading ? null : (user ? <Save /> : <PersonAdd />)}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : (user ? 'Salvar Alterações' : 'Criar Usuário')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserForm;
