// frontend/src/components/RecuperarSenha.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  Container,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Email } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const RecuperarSenha = () => {
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [erro, setErro] = useState('');
  const [loading, setLoading] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  const validarEmail = (email) => {
    const regexEmail = /\S+@\S+\.\S+/;
    return regexEmail.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErro('');
    setMensagem('');
    setSucesso(false);

    if (!email) {
      setErro('Por favor, insira seu e-mail.');
      return;
    }

    if (!validarEmail(email)) {
      setErro('O formato do e-mail é inválido.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://medchoices.com.br:3001/recuperar-senha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setSucesso(true);
        setMensagem('Um e-mail para recuperação de senha foi enviado.');
      } else {
        setErro(data.message || 'Erro ao processar a solicitação.');
      }
    } catch (err) {
      setErro('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #F3F4F6, #FFFFFF)',
        py: 4,
      }}
    >
      <Container maxWidth="sm"> {/* Adicionando Container */}
        <Paper
          elevation={6}
          sx={{
            p: { xs: 3, sm: 6 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography component="h1" variant="h4" fontWeight="bold" gutterBottom>
              Recuperar Senha
            </Typography>
          </Box>

          {erro && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {erro}
            </Alert>
          )}

          {sucesso && (
            <Alert severity="success" sx={{ mb: 3 }}>
              {mensagem}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!erro && !validarEmail(email) && email !== ''}
              helperText={
                !validarEmail(email) && email !== '' ? 'Formato de e-mail inválido' : ''
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="action" />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
              sx={{
                py: 1.5,
                mb: 3,
                mt: 2,
              }}
            >
              {loading ? 'Enviando...' : 'Enviar E-mail de Recuperação'}
            </Button>

            {/* **Link para Voltar ao Login usando Typography** */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <Typography
                component={Link}
                to="/login"
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  color: 'primary.main',
                  cursor: 'pointer',
                }}
              >
                Voltar para o Login
              </Typography>
            </Box>
            {/* **Fim do Link para Voltar ao Login** */}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default RecuperarSenha;
