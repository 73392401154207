import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Alert, Typography } from '@mui/material';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [message, setMessage] = useState('');
  const hasVerified = useRef(false); // Referência para controlar execução única

  useEffect(() => {
    const verifyEmail = async () => {
      if (hasVerified.current) {
        console.log('Verificação ignorada: já foi executada.');
        return; // Previne múltiplas execuções
      }

      hasVerified.current = true; // Marca que a função foi executada
      console.log('Iniciando verificação com token:', token);

      try {
        const response = await fetch(`https://medchoices.com.br:3001/verificar-email/${token}`);
        console.log('Resposta do servidor:', response);

        const data = await response.json();
        console.log('Dados recebidos:', data);

        if (data.success) {
          console.log('E-mail verificado com sucesso.');
          setStatus('success');
          setMessage('E-mail verificado com sucesso! Redirecionando para o login...');
          setTimeout(() => {
            console.log('Redirecionando para login após sucesso.');
            navigate('/login');
          }, 3000);
        } else {
          console.log('Erro ao verificar e-mail:', data.message);
          setStatus('error');
          setMessage(data.message || 'Ocorreu um erro durante a verificação do e-mail.');
          setTimeout(() => {
            console.log('Redirecionando para login após erro.');
            navigate('/login');
          }, 5000);
        }
      } catch (error) {
        console.error('Erro durante a conexão com o servidor:', error);
        if (status === 'success') {
          console.log('Ignorando erro porque já foi verificado com sucesso.');
          return;
        }
        setStatus('error');
        setMessage('Erro ao conectar ao servidor.');
        setTimeout(() => {
          console.log('Redirecionando para login após erro de conexão.');
          navigate('/login');
        }, 5000);
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        p: 3,
      }}
    >
      {console.log('Renderizando componente. Status atual:', status, 'Mensagem atual:', message)}
      {status === 'loading' && (
        <>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Verificando e-mail...</Typography>
        </>
      )}
      {status === 'success' && <Alert severity="success">{message}</Alert>}
      {status === 'error' && <Alert severity="error">{message}</Alert>}
    </Box>
  );
};

export default VerifyEmail;
