// frontend/src/pages/Admin/Materias/MateriasList.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Stack,
  Divider,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
  Fade,
  alpha,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon, // Importado corretamente
} from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useTheme } from '@mui/material/styles'; // Importação corrigida
import { Breadcrumbs, Link as MuiLink } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';

// Componente de Alerta
const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Componente para confirmar exclusão
const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

// Componente para o Diálogo de Confirmação de Deleção (para múltiplas seleções)
const DeleteSelectedConfirmationDialog = ({ open, onClose, onConfirm, title, description }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 2 }}>
      <Button onClick={onClose} color="inherit" variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        autoFocus
        sx={{ ml: 2 }}
      >
        Deletar
      </Button>
    </DialogActions>
  </Dialog>
);

const MateriasList = () => {
  // Hooks do Material-UI
  const theme = useTheme();

  // Estados
  const [materias, setMaterias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [totalMaterias, setTotalMaterias] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Itens por página
  const [selectedMaterias, setSelectedMaterias] = useState([]);
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [deleteSelectedDialogOpen, setDeleteSelectedDialogOpen] = useState(false); // Deleção múltipla
  const [refreshKey, setRefreshKey] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // Extrair parâmetros da URL
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const searchParam = params.get('search') || '';
  const pageParam = parseInt(params.get('page') || '1', 10);
  const limitParam = parseInt(params.get('limit') || '10', 10); // Limite de itens por página

  useEffect(() => {
    setSearch(searchParam);
    setCurrentPage(pageParam);
    setItemsPerPage(limitParam);
  }, [searchParam, pageParam, limitParam]);

  // Debounce para otimizar buscas
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const newParams = new URLSearchParams(location.search);
        if (value) {
          newParams.set('search', value);
        } else {
          newParams.delete('search');
        }
        newParams.set('page', '1');
        newParams.set('limit', itemsPerPage.toString()); // Atualizar limite
        navigate(`?${newParams.toString()}`);
      }, 500),
    [location.search, navigate, itemsPerPage]
  );

  // Função para buscar matérias
  const fetchMaterias = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      let url = `https://medchoices.com.br:3001/admin/materias?page=${pageParam}&limit=${itemsPerPage}`;
      if (searchParam) url += `&search=${encodeURIComponent(searchParam)}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      console.log('Dados recebidos:', data);

      if (data.success) {
        setMaterias(data.materias);
        // Verifique os nomes dos campos na resposta do backend
        setTotalMaterias(data.totalMaterias || data.total || 0); // Ajuste conforme necessário
        setTotalPages(data.totalPages || data.pages || 1);     // Ajuste conforme necessário
        setCurrentPage(data.currentPage || pageParam);
        setSelectedMaterias([]); // Resetar seleção ao buscar
      } else {
        setError(data.message || 'Erro ao buscar matérias.');
      }
    } catch (error) {
      console.error('Erro ao buscar matérias:', error);
      setError('Erro ao buscar matérias.');
    } finally {
      setLoading(false);
    }
  }, [searchParam, pageParam, itemsPerPage]);

  useEffect(() => {
    fetchMaterias();
  }, [fetchMaterias, refreshKey]);

  // Função para deletar uma matéria individual
  const handleDelete = async () => {
    if (!deleteTarget) return;

    setLoading(true);
    try {
      const response = await fetch(`https://medchoices.com.br:3001/admin/materias/${deleteTarget}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Matéria deletada com sucesso!', severity: 'success' });
        fetchMaterias();
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar matéria.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar matéria:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar matéria.', severity: 'error' });
    } finally {
      setLoading(false);
      setDeleteTarget(null);
      setDeleteDialogOpen(false);
    }
  };

  // Função para deletar múltiplas matérias selecionadas
  const handleDeleteSelected = async () => {
    if (selectedMaterias.length === 0) return;

    setLoading(true);
    try {
      // Supondo que o backend suporte a exclusão em lote via DELETE com IDs no corpo
      const response = await fetch(`https://medchoices.com.br:3001/admin/materias`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ ids: selectedMaterias }),
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({ open: true, message: 'Matérias deletadas com sucesso!', severity: 'success' });
        fetchMaterias();
      } else {
        setSnackbar({ open: true, message: data.message || 'Erro ao deletar matérias.', severity: 'error' });
      }
    } catch (error) {
      console.error('Erro ao deletar matérias:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar matérias.', severity: 'error' });
    } finally {
      setLoading(false);
      setSelectedMaterias([]);
      setDeleteSelectedDialogOpen(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handlePageChange = (event, value) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set('page', value.toString());
    newParams.set('limit', itemsPerPage.toString()); // Atualizar limite
    navigate(`?${newParams.toString()}`);
  };

  const handleItemsPerPageChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setItemsPerPage(newLimit);
    const newParams = new URLSearchParams(location.search);
    newParams.set('limit', newLimit.toString());
    newParams.set('page', '1'); // Resetar para a primeira página
    navigate(`?${newParams.toString()}`);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = materias.map((m) => m.id);
      setSelectedMaterias(allIds);
    } else {
      setSelectedMaterias([]);
    }
  };

  const handleSelectOne = (e, id) => {
    if (e.target.checked) {
      setSelectedMaterias((prev) => [...prev, id]);
    } else {
      setSelectedMaterias((prev) => prev.filter((mid) => mid !== id));
    }
  };

  const isAllSelected = materias.length > 0 && selectedMaterias.length === materias.length;

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
       {/* Breadcrumbs */}
       <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/materias"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Materias
        </MuiLink>
        <MuiLink
          underline="hover"
          color="inherit"
          component={Link}
          to="/admin/provas"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Provas
        </MuiLink>
      </Breadcrumbs>
      {/* Cabeçalho */}
      <Card 
        elevation={0}
        sx={{ 
          backgroundColor: (theme) => theme.palette.primary.main + '0A', // 10% opacity
          mb: 3,
          borderRadius: 2
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight="bold" color="primary">
                Lista de Matérias
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Total de {totalMaterias} matérias cadastradas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                component={Link}
                to="/admin/materias/create"
                sx={{ 
                  borderRadius: 2,
                  textTransform: 'none',
                  px: 3,
                  py: 1.5
                }}
              >
                Criar Nova Matéria
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Campo de Busca e Botões de Refresh */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Buscar Matérias"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 2 }}>
              {/* Seleção de Itens por Página */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel id="items-per-page-label">Por Página</InputLabel>
                <Select
                  labelId="items-per-page-label"
                  id="items-per-page-select"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Por Página"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              {/* Botão de Refresh */}
              <Tooltip title="Atualizar lista" arrow>
                <IconButton onClick={handleRefresh} sx={{ mr: 1 }} color="primary">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Botões de Ações para Múltiplas Seleções */}
      {selectedMaterias.length > 0 && (
        <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                {selectedMaterias.length} selecionadas
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteSelectedDialogOpen(true)}
              >
                Deletar Selecionadas
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Tabela */}
      <Card sx={{ borderRadius: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, py: 4 }}>
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Alert
            severity="error"
            sx={{ mb: 2, borderRadius: 2, m: 2 }}
            action={
              <Button color="inherit" size="small" onClick={handleRefresh}>
                Tentar Novamente
              </Button>
            }
          >
            {error}
          </Alert>
        ) : (
          <Fade in>
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: (theme) => theme.palette.primary.main + '1A' }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        indeterminate={selectedMaterias.length > 0 && selectedMaterias.length < materias.length}
                        inputProps={{ 'aria-label': 'Selecionar todas as matérias' }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Descrição</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Criado em</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Atualizado em</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="center">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materias.map((materia, index) => (
                    <TableRow
                      key={materia.id}
                      hover
                      sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedMaterias.includes(materia.id)}
                          onChange={(e) => handleSelectOne(e, materia.id)}
                          inputProps={{ 'aria-labelledby': `materia-${materia.id}` }}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={materia.id}
                          size="small"
                          sx={{
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      </TableCell>
                      <TableCell>{materia.nome}</TableCell>
                      <TableCell>{materia.descricao}</TableCell>
                      <TableCell>{new Date(materia.created_at).toLocaleString()}</TableCell>
                      <TableCell>{new Date(materia.updated_at).toLocaleString()}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            color="primary"
                            sx={{
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                              },
                            }}
                            component={Link}
                            to={`/admin/materias/edit/${materia.id}`}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir" arrow>
                          <IconButton
                            color="error"
                            sx={{
                              backgroundColor: alpha(theme.palette.error.main, 0.1),
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.error.main, 0.2),
                              },
                            }}
                            onClick={() => { setDeleteTarget(materia.id); setDeleteDialogOpen(true); }}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {materias.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                        <Typography variant="subtitle1" color="text.secondary">
                          Nenhuma Matéria encontrada.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
        )}
      </Card>

      {/* Contador e Paginação */}
      {!loading && !error && materias.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="body2">
            Página {currentPage} de {totalPages}
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}

      {/* Diálogo de Confirmação de Deleção Individual */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => { setDeleteDialogOpen(false); setDeleteTarget(null); }}
        onConfirm={handleDelete}
        title="Confirmar Deleção"
        description="Tem certeza que deseja excluir esta matéria? Esta ação não pode ser desfeita."
      />

      {/* Diálogo de Confirmação de Deleção Múltipla */}
      <DeleteSelectedConfirmationDialog
        open={deleteSelectedDialogOpen}
        onClose={() => setDeleteSelectedDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        title="Confirmar Deleção das Matérias Selecionadas"
        description="Tem certeza que deseja deletar todas as matérias selecionadas? Esta ação não pode ser desfeita."
      />

      {/* Snackbar para Mensagens */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <AlertSnackbar onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </AlertSnackbar>
      </Snackbar>
    </Container>
  );
};

export default MateriasList;
