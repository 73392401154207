import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Container,
  Alert,
  Fade,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { ArrowBack, Save, Cancel } from '@mui/icons-material';

const AvisosForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchAviso = async () => {
        setLoading(true);
        setError('');
        try {
          const response = await fetch(`https://medchoices.com.br:3001/admin/avisos/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const data = await response.json();
          if (data.success) {
            setTitulo(data.aviso.titulo);
            setMensagem(data.aviso.mensagem);
          } else {
            setError(data.message || 'Erro ao carregar o aviso');
          }
        } catch (error) {
          setError('Erro ao conectar com o servidor');
        } finally {
          setLoading(false);
        }
      };

      fetchAviso();
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const method = id ? 'PUT' : 'POST';
    const url = id
      ? `https://medchoices.com.br:3001/admin/avisos/${id}`
      : 'https://medchoices.com.br:3001/admin/avisos';

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ titulo, mensagem }),
      });

      const data = await response.json();
      if (data.success) {
        setSuccess(true);
        setTimeout(() => {
          navigate('/admin/avisos');
        }, 1500);
      } else {
        setError(data.message || 'Erro ao salvar o aviso');
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f5f5f5"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box py={4}>
        <Paper elevation={3} sx={{ p: 4, position: 'relative' }}>
          <Stack direction="row" alignItems="center" spacing={2} mb={3}>
            <Tooltip title="Voltar">
              <IconButton
                onClick={() => navigate('/admin/avisos')}
                sx={{ bgcolor: 'grey.100' }}
              >
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" component="h1" fontWeight="500">
              {id ? 'Editar Aviso' : 'Novo Aviso'}
            </Typography>
          </Stack>

          {error && (
            <Fade in={!!error}>
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            </Fade>
          )}

          {success && (
            <Fade in={success}>
              <Alert severity="success" sx={{ mb: 3 }}>
                Aviso salvo com sucesso!
              </Alert>
            </Fade>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              margin="normal"
              required
              variant="outlined"
              sx={{ mb: 3 }}
              InputProps={{
                sx: { borderRadius: 1 },
              }}
            />
            <TextField
              fullWidth
              label="Mensagem"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              margin="normal"
              multiline
              rows={6}
              required
              variant="outlined"
              sx={{ mb: 4 }}
              InputProps={{
                sx: { borderRadius: 1 },
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="error"
                onClick={() => navigate('/admin/avisos')}
                startIcon={<Cancel />}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Save />}
                disabled={loading}
              >
                {loading ? 'Salvando...' : id ? 'Salvar Alterações' : 'Criar Aviso'}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default AvisosForm;